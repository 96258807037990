export interface AuthState {
    access_token: string;
    account: Account;
}

export interface Account {
    id: string;
    username: string;
    permission: Array<string>;
    data: UserData;
    templates: Array<MailTemplate>;
    tokens: Array<Token>;
    change_password: boolean;
}

export interface UserData {
    short: string;
    long: string;
    role: Array<"avor" | "monteur" | "inspector" | "carrier" | "installer">;
    sign: string;
    hidden: boolean;
}

export interface MailTemplate {
    name: string;
    text: string;
    files: Array<string>;
}

export interface Token {
    agent: string;
    last_login: number;
}

export default {
    namespaced: true,
    state: (): AuthState => ({
        access_token: undefined,
        account: undefined,
    }),
    getters: {
        getAccessToken(state) {
            return state.access_token;
        },
        getAccount(state) {
            return state.account;
        },
    },
    mutations: {
        setAccessToken(state, token) {
            state.access_token = token;
        },
        setAccount(state, account) {
            state.account = account;
        },
    },
    actions: {
        fetchAccount(state) {
            this._vm.axios
                .get("/account")
                .then((res) => state.commit("setAccount", res.data))
                .catch(() =>
                    state.commit("ui/logMessage", { logLevel: 0, message: "Die Account Daten konnten nicht geladen werden." }, { root: true })
                );
        },
    },
    modules: {},
};
