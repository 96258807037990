<script>
import { mapMutations } from "vuex";

import Layout from "@layouts/main.layout.vue";
import NormLayout from "@layouts/norm.layout.vue";
import TextField from "@components/TextField.vue";
import Treeview from "@components/norm/NormTreeview.vue";
import Item from "@components/norm/NormItem.vue";
import NormMenu from "@components/menus/NormMenu.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "NormView",
    components: {
        Layout,
        NormLayout,
        TextField,
        Treeview,
        Item,
        NormMenu,
    },
    mixins: [permissionMixin],
    data() {
        return {
            search: undefined,
            norms: {
                customer: [],
                evu: [],
            },
            selected: undefined,
            queryTimestamp: undefined,
        };
    },
    mounted() {
        this.axios.get("/norms").then((res) => (this.norms = res.data));
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
        }),
        queryNorms() {
            this.selected = undefined;
            this.axios
                .get(!this.search || this.search.length == 0 ? "/norms" : "/norms?query=" + this.search)
                .then((res) => (this.norms = res.data))
                .catch(() => this.logError("Normen konnten nicht geladen werden"))
                .finally(() => (this.queryTimestamp = Date.now()));
        },
    },
};
</script>

<template>
    <div>
        <Layout>
            <template #navbar.append>
                <TextField v-model="search" icon="mdi-magnify" width="min(100%, 300px)" rounded placeholder="Suche" @submit="queryNorms" />
            </template>
            <NormLayout :key="queryTimestamp">
                <template #toolbar>
                    <NormMenu v-if="hasPermission('norm.edit.customer') || hasPermission('norm.edit.evu')" @update="queryNorms">
                        <template #activator="{ attrs, on }">
                            <v-btn icon outlined v-bind="attrs" v-on="on"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                        </template>
                    </NormMenu>
                </template>
                <template #default>
                    <Treeview v-model="norms" :selected.sync="selected" />
                </template>
                <template #item>
                    <Item v-model="selected" @update="queryNorms" />
                </template>
            </NormLayout>
        </Layout>
    </div>
</template>

<style lang="scss" scoped></style>
