<script>
export default {
    name: "NormLayoutComponent",
};
</script>

<template>
    <div class="norm-layout">
        <div class="norm-wrapper">
            <div class="norm-toolbar"><slot name="toolbar" /></div>
            <div class="norm-content">
                <div class="norm-tree">
                    <slot />
                </div>
                <div class="norm-view">
                    <slot name="item" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.norm-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .norm-wrapper {
        width: 100%;
        max-width: 1000px;
        min-height: 100%;
        display: grid;
        grid-template-areas:
            "toolbar"
            "content";
        grid-template-columns: 100%;
        grid-template-rows: $navbar-height calc(100% - $navbar-height - 1rem);
        gap: 1rem;
        padding: 1rem;

        .norm-toolbar {
            grid-area: toolbar;
            background: white;
            box-shadow: $box-shadow-1;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            gap: 1rem;
        }

        .norm-content {
            grid-area: content;
            background: white;
            box-shadow: $box-shadow-1;
            display: grid;
            grid-template-columns: 40% 60%;
            grid-template-rows: 100%;
            padding: 1rem;

            .norm-tree {
                padding-right: 1rem;
                overflow-y: auto;
            }

            .norm-view {
                padding-left: 1rem;
                border-left: 1px solid #ccc;
                text-align: center;
                overflow-y: auto;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .norm-layout {
        .norm-wrapper {
            grid-template-rows: $navbar-height calc(100% - $navbar-height - $navbar-height);

            .norm-content {
                grid-template-columns: 100%;
                grid-template-rows: unset;

                .norm-tree {
                    padding-right: 0;
                    margin-bottom: 0.5rem;
                }
                .norm-view {
                    padding-left: 0;
                    padding-top: 0.5rem;
                    border-left: unset;
                    border-top: 1px solid #ccc;
                }
            }
        }
    }
}
</style>
