<script>
import { mapState, mapMutations, mapActions } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "ImportFormularDialogComponent",
    components: {
        DialogLayout,
    },
    data() {
        return {
            dialog: false,
            contract: undefined,
            contract_id: undefined,
            contracts: [],
        };
    },
    computed: {
        ...mapState({
            history: (state) => state.contract.history,
        }),
        combinedContracts() {
            return this.contracts.length > 0
                ? this.contracts.map((item) => ({ text: item.contract_id + " " + item.contract_name, value: item.contract_id }))
                : this.history.map((item) => ({ text: item.contract_id + " " + item.contract_name, value: item.contract_id }));
        },
    },
    mounted() {
        this.queryHistory();
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        ...mapActions({
            queryHistory: "contract/queryHistory",
        }),
        open(contract_id) {
            this.contracts = this.history;
            this.contract_id = contract_id;
            this.dialog = true;
        },
        close(action) {
            action();
            this.contract = undefined;
            this.contract_id = undefined;
            this.$emit("close");
        },
        send(action) {
            if (!this.contract || !this.contract.value) return;
            this.axios
                .post("/contract/" + this.contract_id + "/formular/import/" + this.contract.value)
                .then(() => {
                    this.logMessage("Die Antworten wurde erfolgreich importiert");
                    this.$emit("update:formular");
                    this.close(action);
                })
                .catch((error) => {
                    if (error.response?.status == 500) this.logError("Bei importieren der Antworten ist ein Fehler aufgetreten");
                    if (error.response?.status == 404) this.logError("Es existiert keine Schnittstellenprotokoll unter diesem Auftrag");
                    else this.logError("Bei importieren der Antworten ist ein Fehler aufgetreten");
                });
        },
        query(query) {
            if (!query || query.length == 0) return;
            this.axios.get("/contract?query=" + query).then((res) => (this.contracts = res.data));
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" title="Fragen Importieren" width="500">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-combobox v-model="contract" label="Auftrag" :items="combinedContracts" @update:search-input="query" />
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="!contract" @click="send(action)">Importieren</v-btn>
        </template>
    </DialogLayout>
</template>
