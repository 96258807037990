<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "FileTypeDialogComponent",
    components: {
        DialogLayout,
    },
    mixins: [permissionMixin],
    props: {
        value: { type: Array, default: undefined },
        contract: { type: Object, required: true },
    },
    data() {
        return {
            files: [],
            special_mimes: ["application/pdf", "image/png", "image/jpeg"],
            image_mimes: ["image/png", "image/jpeg"],
        };
    },
    computed: {
        fileName() {
            if (!this.files[0]) return "";
            else return this.files[0].name;
        },
        fileType() {
            if (!this.files[0]) return undefined;
            return this.files[0].type;
        },
        dialog() {
            return this.files.length > 0;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value) this.files = this.files.concat(value);
            },
        },
        files: {
            immediate: true,
            handler(value) {
                if (value[0]) {
                    const file = value[0];
                    if (file.size == 0) {
                        this.logError("Die Datei konnte nicht gelesen werden");
                        this.files.shift();
                    }
                    if (!this.special_mimes.includes(file.type) && !this.isLocked(this.contract)) this.upload(this.files.shift(), "data");
                    if (
                        this.image_mimes.includes(file.type) &&
                        !this.hasPermission("file.upload.AVOR Bilder") &&
                        this.hasPermission("file.upload.Bilder")
                    )
                        this.upload(this.files.shift(), "Bilder");
                    if (
                        this.image_mimes.includes(file.type) &&
                        !this.hasPermission("file.upload.Bilder") &&
                        this.hasPermission("file.upload.AVOR Bilder")
                    )
                        this.upload(this.files.shift(), "AVOR Bilder");
                }
            },
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
        }),
        setType(type) {
            this.upload(this.files.shift(), type);
        },
        dismiss() {
            this.files.shift();
        },
        upload(file, type) {
            if (!this.contract) return;
            const formData = new FormData();
            formData.append(encodeURI(type), file);
            this.axios
                .post("/contract/" + this.contract.contract_id + "/file", formData, { headers: { "Content-Type": "multipart/form-data" } })
                .then(() => this.$emit("update:files"))
                .catch((err) => console.log(err.response))
                .catch(() => console.error(file.name, "upload error to", type));
        },
    },
};
</script>

<template>
    <DialogLayout :value="dialog" title="Datei Typ auswählen" :subtitle="fileName" persistent width="500">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <div class="action-grid">
                <div v-if="!isLocked(contract)" class="types-grid">
                    <template v-if="fileType == 'application/pdf'">
                        <div v-if="hasPermission('file.upload.Administrativ')" v-ripple class="item" @click="setType('Administrativ')">
                            <v-icon dark>mdi-shield-crown-outline</v-icon>
                            <div class="item-text">Administrativ</div>
                        </div>
                        <div v-if="hasPermission('file.upload.Dispo')" v-ripple class="item" @click="setType('Dispo')">
                            <v-icon dark>mdi-floor-plan</v-icon>
                            <div class="item-text">Dispo</div>
                        </div>
                        <div v-if="hasPermission('file.upload.Schema')" v-ripple class="item" @click="setType('Schema')">
                            <v-icon dark>mdi-chart-timeline-variant</v-icon>
                            <div class="item-text">Schema</div>
                        </div>
                        <div v-if="hasPermission('file.upload.Schema Revision')" v-ripple class="item" @click="setType('Schema Revision')">
                            <v-icon dark>mdi-chart-timeline-variant-shimmer</v-icon>
                            <div class="item-text">Schema Revision</div>
                        </div>
                        <div v-if="hasPermission('file.upload.Prüfbericht')" v-ripple class="item" @click="setType('Prüfbericht')">
                            <v-icon dark>mdi-file-certificate</v-icon>
                            <div class="item-text">Messprotokoll</div>
                        </div>
                        <div v-if="hasPermission('file.upload.Lieferschein')" v-ripple class="item" @click="setType('Lieferschein')">
                            <v-icon dark>mdi-format-list-bulleted-square</v-icon>
                            <div class="item-text">Lieferschein</div>
                        </div>
                    </template>
                    <template v-if="image_mimes.includes(fileType)">
                        <div v-if="hasPermission('file.upload.AVOR Bilder')" v-ripple class="item" @click="setType('AVOR Bilder')">
                            <v-icon dark>mdi-camera-wireless-outline</v-icon>
                            <div class="item-text">AVOR Bilder</div>
                        </div>
                        <div v-if="hasPermission('file.upload.Bilder')" v-ripple class="item" @click="setType('Bilder')">
                            <v-icon dark>mdi-camera-outline</v-icon>
                            <div class="item-text">Bilder</div>
                        </div>
                    </template>
                    <div v-if="hasPermission('file.upload')" v-ripple class="item" @click="setType('data')">
                        <v-icon dark>mdi-file-question</v-icon>
                        <div class="item-text">Diverse Unterlagen</div>
                    </div>
                </div>
                <div v-else class="locked">Dieser Auftrag wurde bereits gesperrt</div>
                <div v-ripple class="item-cancel" @click="dismiss">
                    <v-icon dark>mdi-close</v-icon>
                    <div class="item-text">Nicht hochladen</div>
                </div>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.action-grid {
    display: grid;
    gap: 1rem;
    grid-template-areas:
        "content"
        "cancel";
    grid-template-columns: 100%;
    grid-template-rows: auto 2.5rem;

    .types-grid {
        grid-area: content;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        grid-template-rows: repeat(1fr);

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: $primary;
            color: white;
            gap: 0.5rem;
            cursor: pointer;
            box-shadow: $box-shadow-1;
            height: 5rem;

            &:hover {
                background: lighten($primary, 5%);
            }
        }
    }

    .locked {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item-cancel {
        grid-area: cancel;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: $error;
        color: white;
        gap: 0.5rem;
        cursor: pointer;
        box-shadow: $box-shadow-1;

        &:hover {
            background: lighten($error, 5%);
        }
    }
}
</style>
