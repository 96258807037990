import { mapMutations } from "vuex";

export default {
    data() {
        return {
            contract: undefined,
            rapport: undefined,
            legend: undefined,
            formular: undefined,
            files: [],
            customer_norms: [],
            evu_norms: [],
            journal: [],
            templates: [],
        };
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logWarn: "ui/warning",
        }),
        fetchContract(contract_id) {
            return this.axios
                .get("/contract/" + contract_id)
                .then((res) => (this.contract = res.data))
                .catch(() => this.logError("Auftrag konnte nicht geladen werden"));
        },
        fetchRapport() {
            if (!this.contract) return Promise.resolve();
            return this.axios
                .get("/contract/" + this.contract?.contract_id + "/rapport")
                .then((res) => {
                    if (res?.status == 200) this.rapport = res.data;
                    else this.rapport = undefined;
                })
                .catch(() => console.warn("error loading rapport"));
        },
        fetchLegend() {
            if (!this.contract) return Promise.resolve();
            return this.axios
                .get("/contract/" + this.contract?.contract_id + "/legend")
                .then((res) => {
                    if (res?.status == 200) this.legend = res.data;
                    else this.legend = undefined;
                })
                .catch(() => console.warn("error loading legend"));
        },
        fetchFormular() {
            if (!this.contract) return Promise.reject();
            return this.axios
                .get("/contract/" + this.contract?.contract_id + "/formular")
                .then((res) => {
                    if (res?.status == 200) this.formular = res.data;
                    else this.formular = undefined;
                })
                .catch(() => console.warn("error loading formular"));
        },
        fetchFiles() {
            if (!this.contract) return Promise.reject();
            return this.axios
                .get("/contract/" + this.contract?.contract_id + "/file")
                .then((res) => (this.files = res.data))
                .catch(() => this.logError("Beim laden der Dateien ist ein fehler aufgetreten"));
        },
        fetchCustomerNorms() {
            if (!this.contract || !this.contract.customer_id) return Promise.resolve();
            return this.axios
                .get("/customer/" + this.contract.customer_id + "/norms")
                .then((res) => (this.customer_norms = res.data))
                .catch(() => this.logError("Kundennormen konnten nicht geladen werden"));
        },
        fetchEVUNorms() {
            if (!this.contract || !(this.contract.evu || this.formular?.data?.power_station)) return Promise.resolve();
            return this.axios
                .get("/evu/" + (this.formular?.data?.power_station || this.contract.evu) + "/norms")
                .then((res) => (this.evu_norms = res.data))
                .catch(() => this.logWarn("Für dieses EW sind keine Normen vorhanden"));
        },
        fetchJournal() {
            if (!this.contract) return Promise.resolve();
            return this.axios
                .get("/contract/" + this.contract.contract_id + "/journal")
                .then((res) => (this.journal = res.data))
                .catch(() => this.logError("Auftragsjournal konnte nicht geladen werden"));
        },
        fetchTemplates() {
            if (!this.contract) return Promise.resolve();
            return this.axios
                .get("/template/" + this.contract.contract_id)
                .then((res) => (this.templates = res.data))
                .catch((error) => {
                    if (error?.response?.status == 500) this.logError("Beim laden der Vorlagen ist ein fehler aufgetreten");
                });
        },
    },
};
