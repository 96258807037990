<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "DuedateDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        contract: { type: Object, required: true },
    },
    data() {
        return {
            datePicker: false,
            date: undefined,
        };
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        close(action) {
            action();
            this.date = undefined;
        },
        save(action) {
            this.axios
                .post("/contract/" + this.contract.contract_id, { delivery_date_mandatory: this.date })
                .then(() => {
                    this.logMessage("Lieferdatum wurde gesetzt");
                    this.$emit("update:duedate");
                    this.close(action);
                })
                .catch(() => this.logError("Es ist ein fehler aufgetreten"));
        },
        reset(action) {
            this.axios
                .post("/contract/" + this.contract.contract_id, { delivery_date_mandatory: "" })
                .then(() => {
                    this.logMessage("Lieferdatum wurde zurückgesetzt");
                    this.$emit("update:duedate");
                    this.close(action);
                })
                .catch(() => this.logError("Es ist ein fehler aufgetreten"));
        },
    },
};
</script>

<template>
    <DialogLayout title="Liefertermin setzen" width="450">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-dialog ref="dialog" v-model="datePicker" :return-value.sync="date" persistent width="290px">
                <template #activator="{ on, attrs }">
                    <v-text-field v-model="date" label="Liefertermin" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable first-day-of-week="1">
                    <v-btn text color="error" @click="datePicker = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="$refs.dialog.save(date)">Ok</v-btn>
                </v-date-picker>
            </v-dialog>
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="reset(action)">Zurücksetzen</v-btn>
            <v-btn color="primary" text :disabled="!date || date.length == 0" @click="save(action)">Speichern</v-btn>
        </template>
    </DialogLayout>
</template>
