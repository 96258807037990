<script>
import { mapMutations } from "vuex";

import ConfirmDialog from "@components/dialogs/ConfirmDialog.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "FileContextMenuComponent",
    components: {
        ConfirmDialog,
    },
    mixins: [permissionMixin],
    props: {
        contract: { type: Object, required: true },
    },
    data() {
        return {
            dialog: false,
            mobile: false,
            dialogX: 0,
            dialogY: 0,
            x: 0,
            y: 0,
            item: undefined,
        };
    },
    mounted() {
        document.addEventListener("mousemove", (e) => {
            this.x = e.x;
            this.y = e.y;
        });
        this.mobile = !/Windows/.test(navigator.userAgent);
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logWarning: "ui/warning",
            logMessage: "ui/log",
        }),
        open(item) {
            this.item = item;
            if (!item.canPrint() && !item.canSend() && !item.canImport() && !item.canEdit() && !item.canOpenExternal() && !item.canDelete())
                return this.close();
            this.dialogX = this.x;
            this.dialogY = this.y;
            this.dialog = true;
        },
        close() {
            this.dialog = false;
            this.item = undefined;
            this.$emit("close");
        },
    },
};
</script>

<template>
    <v-menu v-model="dialog" absolute :position-x="dialogX" :position-y="dialogY">
        <v-list v-if="item">
            <v-list-item v-if="item.canPrint()" @click="$emit('print', item)">
                <v-list-item-icon><v-icon>mdi-printer</v-icon></v-list-item-icon>
                <v-list-item-title>Datei drucken</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.canSend()" @click="$emit('send', item)">
                <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
                <v-list-item-title>Datei senden</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.canImport()" @click="$emit('import', item)">
                <v-list-item-icon><v-icon>mdi-import</v-icon></v-list-item-icon>
                <v-list-item-title>Daten Importieren</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.canEdit()" @click="$emit('edit', item)">
                <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                <v-list-item-title>Datei bearbeiten</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.canOpenExternal()" @click="$emit('external', item)">
                <v-list-item-icon><v-icon>mdi-open-in-new</v-icon></v-list-item-icon>
                <v-list-item-title>Datei öffnen</v-list-item-title>
            </v-list-item>
            <ConfirmDialog
                v-if="item.canDelete()"
                title="Datei löschen?"
                :text="item.name"
                @resolve="
                    item.deleteFile();
                    close();
                    $emit('update:files');
                    $emit('update:formular');
                    $emit('update:legend');
                    $emit('update:rapport');
                "
            >
                <template #activator="{ attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Datei löschen</v-list-item-title>
                    </v-list-item>
                </template>
            </ConfirmDialog>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped>
.context-main {
    background: white;
    min-height: 2rem;
    width: 10rem;
}
</style>
