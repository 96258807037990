<script>
export default {
    name: "NavButtonComponent",
    props: {
        active: { type: Boolean, default: false },
        to: { type: String, default: undefined },
        icon: { type: String, default: undefined },
        label: { type: String, default: undefined },
    },
    computed: {
        _active() {
            return (this.to && this.$route.path == this.to) || this.active;
        },
        listeners() {
            return {
                ...this.$listeners,
                click: (event) => {
                    if (this.to != undefined && this.to != this.$route.path) this.$router.push(this.to);
                    else this.$emit("click", event);
                },
            };
        },
    },
};
</script>

<template>
    <div v-ripple class="navbutton" :class="{ active: _active }" v-on="listeners">
        <div class="content">
            <v-icon color="white">{{ icon }}</v-icon>
            <div id="navbutton-label">{{ label }}</div>
        </div>
        <div id="navbutton-notch" />
    </div>
</template>

<style lang="scss" scoped>
.navbutton {
    position: relative;
    width: $navbar-height;
    height: $navbar-height;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0.5;

        #navbutton-label {
            font-size: 0px;
            font-weight: normal;
            color: white;
            transition: font-size $navbar-item-transition ease-in-out, font-weight $navbar-item-transition ease-in-out;
        }
    }

    &:hover > .content {
        opacity: 1;
    }

    &:hover > .content #navbutton-label {
        font-size: 14px !important;
        font-weight: bold;
    }

    #navbutton-notch {
        position: absolute;
        width: $navbar-notch-size;
        height: $navbar-notch-size;
        bottom: 0;
        left: calc(($navbar-height - $navbar-notch-size) / 2);
        background: $background;
        opacity: 0;
        transform: translateY(calc($navbar-notch-transform + 0.5rem)) rotate(45deg);
        transition: transform $navbar-item-transition ease-in-out, opacity 0.1s ease-in-out;
    }

    &:hover > #navbutton-notch {
        opacity: 1;
        transform: translateY(calc($navbar-notch-transform + 0.5rem)) rotate(45deg);
    }
}

.navbutton.active {
    opacity: 1;

    .content {
        opacity: 1;

        #navbutton-label {
            font-weight: bold;
        }
    }

    #navbutton-notch {
        opacity: 1;
        transform: translateY($navbar-notch-transform) rotate(45deg);
    }
}
</style>
