<script>
import Vue from "vue";
import { mapState } from "vuex";

import Teleport from "vue2-teleport";

export default Vue.extend({
    name: "App",
    components: {
        Teleport,
    },
    data() {
        return {
            logMessage: undefined,
            logColor: undefined,
        };
    },
    computed: {
        ...mapState({
            log: (state) => state.ui.log,
        }),
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = `DFutura - ${to.name}`;
                this.$store.dispatch("notification/fetchNotifications");
            },
        },
        log(value) {
            this.logMessage = value[value.length - 1];
            switch (this.logMessage.logLevel) {
                case 0:
                    this.logColor = "error";
                    break;
                case 1:
                    this.logColor = "warning";
                    break;
                case 3:
                    this.logColor = "info";
                    break;
                default:
                    this.logColor = "success";
                    break;
            }
        },
    },
    mounted() {
        this.axios.get("/auth").then(() => {
            this.$store.dispatch("auth/fetchAccount");
            this.$store.dispatch("user/fetchUsers");
            this.$store.dispatch("notification/fetchNotifications");
            this.$store.dispatch("norm/fetchNorms");
        });
    },
});
</script>

<template>
    <v-app id="app">
        <v-main>
            <router-view ref="view" :key="$route.fullPath" />
            <v-snackbar v-if="logMessage" v-model="logMessage" timeout="5000" :color="logColor">{{ logMessage.message }}</v-snackbar>
            <Teleport to="body">
                <div class="topbar">
                    <div class="action-wrapper">
                        <v-btn small icon @click="$router.go(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-btn small icon @click="$router.go(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </div>
                </div>
            </Teleport>
        </v-main>
    </v-app>
</template>

<style lang="scss">
#app {
    width: 100vw;
    height: calc(100vh - env(titlebar-area-height, $titlebar-area-height-alt));
    background: $background;
    margin-top: env(titlebar-area-height, $titlebar-area-height-alt);
    overflow: hidden;
}

.topbar {
    position: fixed;
    left: env(titlebar-area-x, 0);
    top: env(titlebar-area-y, 0);
    width: env(titlebar-area-width, 100%);
    height: env(titlebar-area-height, $titlebar-area-height-alt);
    display: flex;
    flex-direction: row;
    background: $primary;
    overflow: hidden;
    -webkit-app-region: drag;
    padding-left: 10px;
    gap: 10px;

    .action-wrapper {
        -webkit-app-region: no-drag;

        button {
            color: white !important;
            width: 2rem;
        }
    }
}
</style>
