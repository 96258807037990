<script>
export default {
    name: "ContractTileComponent",
    props: {
        value: { type: Object, required: true },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                click: (event) => {
                    this.$router.push("/contract/" + this.value.contract_id);
                    this.$emit("click", event);
                },
            };
        },
    },
};
</script>

<template>
    <div v-ripple class="contract-tile" v-on="listeners">
        <div class="item" style="flex: 0.8">
            <div class="item-title">Auftragsnummer</div>
            <div>{{ value.contract_id }}</div>
        </div>
        <div class="item" style="flex: 2">
            <div class="item-title">Kommission</div>
            <div>{{ value.contract_name }}</div>
        </div>
        <div class="item">
            <div class="item-title">Kunde</div>
            <div>{{ value.company }}</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.contract-tile {
    width: 100%;
    height: 5rem;
    background-color: white;
    box-shadow: $box-shadow-1;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    gap: 1rem;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .item {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-weight: bold;

        .item-title {
            font-weight: normal;
            font-size: 11px;
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: 650px) {
    .contract-tile {
        flex-direction: column;
        height: auto;
    }
}
</style>
