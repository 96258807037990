<script>
import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "ConfirmDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        title: { type: String, default: "Aktivität bestätigen?" },
        text: { type: String, default: undefined },
    },
    methods: {
        close(action) {
            action();
            this.$emit("reject");
        },
        submit(action) {
            action();
            this.$emit("resolve");
        },
    },
};
</script>

<template>
    <DialogLayout :title="title" width="400">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>{{ text }}</template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="submit(action)">Ok</v-btn>
        </template>
    </DialogLayout>
</template>
