
import { mapGetters } from "vuex";

import ImageDialog from "@components/dialogs/ImageDialog.vue";
import DataDialog from "@components/dialogs/DataDialog.vue";
import DocumentDialog from "@components/dialogs/DocumentDialog.vue";
import LegendDialog from "@components/dialogs/LegendDialog.vue";
import FormularDialog from "@components/dialogs/QuestionDialog.vue";
import ContractDataDialog from "@components/dialogs/ContractDataDialog.vue";
import ImportLegendDialog from "@components/dialogs/ImportLegendDialog.vue";
import ImportFormularDialog from "@components/dialogs/ImportFormularDialog.vue";
import PrintDialog from "@components/dialogs/PrintAmountDialog.vue";
import RapportDialog from "@components/dialogs/RapportDialog.vue";
import FileDrag from "@components/FileDrag.vue";
import FileContextMenu from "@components/menus/FileContextMenu.vue";
import EmailDialog from "@components/dialogs/EmailDialog.vue";
import MaterialDialog from "@components/dialogs/MaterialDialog.vue";

import permissionMixin from "../mixin/permission.mixin";

export default {
    name: "FileSystemComponent",
    components: {
        ImageDialog,
        DataDialog,
        DocumentDialog,
        LegendDialog,
        FormularDialog,
        ContractDataDialog,
        ImportLegendDialog,
        ImportFormularDialog,
        PrintDialog,
        RapportDialog,
        FileDrag,
        FileContextMenu,
        EmailDialog,
        MaterialDialog,
    },
    mixins: [permissionMixin],
    props: {
        value: { type: Array, default: () => [] },
        contract: { type: Object, required: true },
        files: { type: Array, default: () => [] },
        formular: { type: Object, default: undefined },
        rapport: { type: Object, default: undefined },
        legend: { type: Object, default: undefined },
        selectable: { type: Boolean, default: undefined },
        csv: { type: Boolean, default: undefined },
        filter: { type: Function, default: () => true },
        map: { type: Function, default: (item) => item },
    },
    data() {
        return {
            selected: [],
            active_items: [],
            image_mimes: ["image/jpeg", "image/png"],
            data_mimes: ["application/pdf", "text/plain", "video/mp4"],
            email_mimes: ["application/vnd.ms-outlook"],
            file_icons: require("./_icons.json"),
        };
    },
    computed: {
        ...mapGetters({
            inspectors: "user/inspectors",
            installers: "user/installers",
            carriers: "user/carriers",
        }),
        filesystem() {
            let filesystem = [
                ...this.files.map((root) => {
                    if (Array.isArray(root.children)) {
                        return { id: root.id || root.name + " group", ...root, children: root.children.map((file) => this.hydrateFile(file)) };
                    } else {
                        return this.hydrateFile(root);
                    }
                }),
            ];

            if (!this.isLocked(this.contract) && (this.isInGroup("avor") || this.isInGroup("monteur"))) {
                this.addToArray(filesystem, undefined, {
                    name: "Auftragsinformationen",
                    icon: "fa-circle-info",
                    canOpen: () => true,
                    open: () => this.$refs.contract_data_dialog.open(),
                    canOpenExternal: () => false,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => false,
                    canSend: () => false,
                    canPrint: () => false,
                });
            }

            if (this.contract.status < 4) {
                this.addToArray(filesystem, undefined, {
                    name: "Material",
                    icon: "fa-boxes-stacked",
                    canOpen: () => true,
                    open: () => this.$refs.material_dialog.open(),
                    canOpenExternal: () => false,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => false,
                    canSend: () => false,
                    canPrint: () => false,
                });
            }

            if (this.formular) {
                this.addToArray(filesystem, "Prüfbericht", {
                    name: "Schnittstellenprotokoll",
                    icon: "fa-clipboard-question",
                    canOpen: () => true,
                    open: () => this.$refs.document_dialog.open("formular", { ...this.formular.data, contract_name: this.contract.contract_name }),
                    canOpenExternal: () => false,
                    canEdit: () => {
                        if (this.isLocked(this.contract)) return false;
                        if (this.contract.status >= 2) return false;
                        return this.hasPermission("formular.edit");
                    },
                    edit: () => this.$refs.formular_dialog.open(),
                    canImport: () => {
                        if (this.isLocked(this.contract)) return false;
                        if (this.contract.status >= 2) return false;
                        return this.hasPermission("formular.edit");
                    },
                    import: () => this.$refs.import_formular_dialog.open(this.contract.contract_id),
                    canDelete: () => this.hasPermission("formular.delete") && !this.isLocked(this.contract),
                    deleteFile: () => this.axios.delete("/contract/" + this.contract.contract_id + "/formular"),
                    canSend: () => true,
                    send: async () =>
                        (
                            await this.axios.post("/document/formular/temp?filename=" + this.getFilename("Schnittstellenprotokoll"), {
                                ...this.formular.data,
                                contract_name: this.contract.contract_name,
                            })
                        )?.data?.id,
                    canPrint: () => this.contract.status > 1,
                    print: () => this.$refs.print_dialog.open("formular", { ...this.formular.data, contract_name: this.contract.contract_name }),
                });

                this.addToArray(filesystem, "Prüfbericht", {
                    name: "Stücknachweis",
                    icon: "fa-file-contract",
                    canOpen: () => true,
                    open: () => {
                        const inspector = this.inspectors.find((inspector) => inspector.id == this.contract.data?.inspector);
                        this.$refs.document_dialog.open("stuecknachweis", {
                            ...this.formular.data,
                            ...this.contract.data,
                            inspector_name: inspector?.data?.long,
                            inspector_sign: inspector?.data?.sign,
                            contract_id: this.contract.contract_id,
                        });
                    },
                    canOpenExternal: () => false,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => false,
                    canSend: () => true,
                    send: async () => {
                        const inspector = this.inspectors.find((inspector) => inspector.id == this.contract.data?.inspector);
                        return (
                            await this.axios.post("/document/stuecknachweis/temp?filename=" + this.getFilename("Stücknachweis"), {
                                ...this.formular.data,
                                ...this.contract.data,
                                inspector_name: inspector?.data?.long,
                                inspector_sign: inspector?.data?.sign,
                                contract_id: this.contract.contract_id,
                            })
                        )?.data?.id;
                    },
                    canPrint: () => this.contract.status > 1,
                    print: () => {
                        const inspector = this.inspectors.find((inspector) => inspector.id == this.contract.data?.inspector);
                        this.$refs.print_dialog.open("stuecknachweis", {
                            ...this.formular.data,
                            ...this.contract.data,
                            inspector_name: inspector?.data?.long,
                            inspector_sign: inspector?.data?.sign,
                            contract_id: this.contract.contract_id,
                        });
                    },
                });

                this.addToArray(filesystem, "Prüfbericht", {
                    name: "Bauartnachweis",
                    icon: "fa-file-contract",
                    canOpen: () => true,
                    open: () => {
                        const inspector = this.inspectors.find((inspector) => inspector.id == this.contract.data?.inspector);
                        this.$refs.document_dialog.open("bauartnachweis", {
                            ...this.formular.data,
                            ...this.contract.data,
                            inspector_name: inspector?.data?.long,
                            inspector_sign: inspector?.data?.sign,
                            contract_id: this.contract.contract_id,
                        });
                    },
                    canOpenExternal: () => false,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => false,
                    canSend: () => true,
                    send: async () => {
                        const inspector = this.inspectors.find((inspector) => inspector.id == this.contract.data?.inspector);
                        return (
                            await this.axios.post("/document/bauartnachweis/temp?filename=" + this.getFilename("Bauartnachweis"), {
                                ...this.formular.data,
                                ...this.contract.data,
                                inspector_name: inspector?.data?.long,
                                inspector_sign: inspector?.data?.sign,
                                contract_id: this.contract.contract_id,
                            })
                        )?.data?.id;
                    },
                    canPrint: () => this.contract.status > 1,
                    print: () => {
                        const inspector = this.inspectors.find((inspector) => inspector.id == this.contract.data?.inspector);
                        this.$refs.print_dialog.open("bauartnachweis", {
                            ...this.formular.data,
                            ...this.contract.data,
                            inspector_name: inspector?.data?.long,
                            inspector_sign: inspector?.data?.sign,
                            contract_id: this.contract.contract_id,
                        });
                    },
                });

                this.addToArray(filesystem, "Prüfbericht", {
                    name: "Konformitätserklärung",
                    icon: "fa-file-contract",
                    canOpen: () => true,
                    open: () => {
                        this.$refs.document_dialog.open("konform", {
                            ...this.formular.data,
                            ...this.contract.data,
                            contract_id: this.contract.contract_id,
                            contract_name: this.contract.contract_name,
                        });
                    },
                    canOpenExternal: () => false,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => false,
                    canSend: () => true,
                    send: async () =>
                        (
                            await this.axios.post("/document/konform/temp?filename=" + this.getFilename("Konformitätserklärung"), {
                                ...this.formular.data,
                                ...this.contract.data,
                                contract_id: this.contract.contract_id,
                                contract_name: this.contract.contract_name,
                            })
                        )?.data?.id,
                    canPrint: () => this.contract.status > 1,
                    print: () => {
                        this.$refs.print_dialog.open("konform", {
                            ...this.formular.data,
                            ...this.contract.data,
                            contract_id: this.contract.contract_id,
                            contract_name: this.contract.contract_name,
                        });
                    },
                });

                this.addToArray(filesystem, "Prüfbericht", {
                    name: "Prüfkleber",
                    icon: "fa-note-sticky",
                    canOpen: () => true,
                    open: () => {
                        this.$refs.document_dialog.open("pruefkleber", {
                            ...this.formular.data,
                            ...this.contract.data,
                            contract_id: this.contract.contract_id,
                            contract_name: this.contract.contract_name,
                        });
                    },
                    canOpenExternal: () => false,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => false,
                    canSend: () => true,
                    send: async () =>
                        (
                            await this.axios.post("/document/pruefkleber/temp?filename=" + this.getFilename("Prüfkleber"), {
                                ...this.formular.data,
                                ...this.contract.data,
                                contract_id: this.contract.contract_id,
                                contract_name: this.contract.contract_name,
                            })
                        )?.data?.id,
                    canPrint: () => this.contract.status > 1,
                    print: () => {
                        this.$refs.print_dialog.open("pruefkleber", {
                            ...this.formular.data,
                            ...this.contract.data,
                            contract_id: this.contract.contract_id,
                            contract_name: this.contract.contract_name,
                        });
                    },
                });
            }

            if (
                (this.contract?.data?.contract_type == "normal" ||
                    this.contract?.data?.contract_type == "empty_system" ||
                    this.contract?.data?.contract_type == "material") &&
                this.contract?.data?.transport == true
            ) {
                this.addToArray(filesystem, "Lieferschein", {
                    name: "Transportschein",
                    icon: "fa-truck",
                    canOpen: () => true,
                    open: () => {
                        const carrier = this.carriers.find((carrier) => carrier.id == this.contract.data?.carrier);
                        const installer = this.installers.find((intsaller) => intsaller.id == this.contract.data?.installer);
                        this.$refs.document_dialog.open("transportschein", {
                            ...this.formular?.data,
                            ...this.contract?.data,
                            contract_id: this.contract?.contract_id,
                            contract_name: this.contract?.contract_name,
                            carrier_name: carrier?.data?.long,
                            installer_name: installer?.data?.long,
                            customer: this.contract?.company,
                            customer_sign: this.contract?.customer_sign,
                        });
                    },
                    canOpenExternal: () => false,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => false,
                    canSend: () => true,
                    send: async () => {
                        const carrier = this.carriers.find((carrier) => carrier.id == this.contract.data?.carrier);
                        const installer = this.installers.find((intsaller) => intsaller.id == this.contract.data?.installer);
                        return (
                            await this.axios.post("/document/transportschein/temp?filename=" + this.getFilename("Transportschein"), {
                                ...this.formular?.data,
                                ...this.contract?.data,
                                contract_id: this.contract?.contract_id,
                                contract_name: this.contract?.contract_name,
                                carrier_name: carrier?.data?.long,
                                installer_name: installer?.data?.long,
                                customer: this.contract?.company,
                                customer_sign: this.contract?.customer_sign,
                            })
                        )?.data?.id;
                    },
                    canPrint: () => this.contract.status > 1,
                    print: () => {
                        const carrier = this.carriers.find((carrier) => carrier.id == this.contract.data?.carrier);
                        const installer = this.installers.find((intsaller) => intsaller.id == this.contract.data?.installer);
                        this.$refs.print_dialog.open(
                            "transportschein",
                            {
                                ...this.formular?.data,
                                ...this.contract?.data,
                                contract_id: this.contract?.contract_id,
                                contract_name: this.contract?.contract_name,
                                carrier_name: carrier?.data?.long,
                                installer_name: installer?.data?.long,
                                customer: this.contract?.company,
                                customer_sign: this.contract?.customer_sign,
                            },
                            null,
                            true
                        );
                    },
                });
            }

            if (this.contract?.data?.material) {
                const tours = {};

                this.contract?.data?.material
                    .filter((tour) => tour.type == "Abholung" || tour.type == "Lieferung")
                    .filter((tour) => (tour.delivered || 0) < tour.amount)
                    .filter((tour) => (tour.date ? tour.date + 1000 * 60 * 60 * 24 > Date.now() : true))
                    .forEach((tour) => {
                        const footprint = tour.address.replaceAll(" ", "").replaceAll("\n", "");
                        if (tours[footprint]) tours[footprint].push(tour);
                        else tours[footprint] = [tour];
                    });

                Object.values(tours).forEach((tour) => {
                    this.addToArray(filesystem, "Lieferschein", {
                        name: "Materialauftrag (" + tour[0].address.replaceAll("\n", ", ") + ")",
                        icon: "fa-truck-ramp-box",
                        canOpen: () => true,
                        open: () => {
                            this.$refs.document_dialog.open("pickup", {
                                ...this.contract?.data,
                                contract_id: this.contract?.contract_id,
                                contract_name: this.contract?.contract_name,
                                customer: this.contract?.company,
                                address: tour[0].address,
                                items: tour,
                            });
                        },
                        canOpenExternal: () => false,
                        canEdit: () => true,
                        edit: () => this.$refs.material_dialog.open(),
                        canImport: () => false,
                        canDelete: () => false,
                        canSend: () => true,
                        send: async () => {
                            return (
                                await this.axios.post("/document/pickup/temp?filename=" + this.getFilename("Materialauftrag"), {
                                    ...this.contract?.data,
                                    contract_id: this.contract?.contract_id,
                                    contract_name: this.contract?.contract_name,
                                    customer: this.contract?.company,
                                    address: tour[0].address,
                                    items: tour,
                                })
                            )?.data?.id;
                        },
                        canPrint: () => this.contract.status > 1,
                        print: () => {
                            this.$refs.print_dialog.open(
                                "pickup",
                                {
                                    ...this.contract?.data,
                                    contract_id: this.contract?.contract_id,
                                    contract_name: this.contract?.contract_name,
                                    customer: this.contract?.company,
                                    address: tour[0].address,
                                    items: tour,
                                },
                                null,
                                true
                            );
                        },
                    });
                });
            }

            if (this.contract?.data?.pickup == true) {
                this.addToArray(filesystem, "Lieferschein", {
                    name: "Abholschein",
                    icon: "fa-box-open",
                    canOpen: () => true,
                    open: () => {
                        this.$refs.document_dialog.open("abholschein", {
                            ...this.formular?.data,
                            ...this.contract?.data,
                            contract_id: this.contract?.contract_id,
                            contract_name: this.contract?.contract_name,
                            customer: this.contract?.company,
                            customer_sign: this.contract?.customer_sign,
                        });
                    },
                    canOpenExternal: () => false,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => false,
                    canSend: () => true,
                    send: async () => {
                        return (
                            await this.axios.post("/document/abholschein/temp?filename=" + this.getFilename("Abholschein"), {
                                ...this.formular?.data,
                                ...this.contract?.data,
                                contract_id: this.contract?.contract_id,
                                contract_name: this.contract?.contract_name,
                                customer: this.contract?.company,
                                customer_sign: this.contract?.customer_sign,
                            })
                        )?.data?.id;
                    },
                    canPrint: () => this.contract.status > 1,
                    print: () => {
                        this.$refs.print_dialog.open(
                            "abholschein",
                            {
                                ...this.formular?.data,
                                ...this.contract?.data,
                                contract_id: this.contract?.contract_id,
                                contract_name: this.contract?.contract_name,
                                customer: this.contract?.company,
                                customer_sign: this.contract?.customer_sign,
                            },
                            null,
                            true
                        );
                    },
                });
            }

            if (this.rapport) {
                this.addToArray(filesystem, "Rapport", {
                    name: "Montagerapport",
                    icon: "fa-stopwatch",
                    canOpen: () => true,
                    open: () =>
                        this.$refs.document_dialog.open("rapport", {
                            ...this.rapport,
                            contract_name: this.contract.contract_name,
                            company: this.contract.company,
                            contact: this.contract.display_name,
                            installer: this.installers.find((installer) => installer.id == this.rapport.installer)?.data?.long,
                        }),
                    canOpenExternal: () => false,
                    canEdit: () => !this.isLocked(this.contract),
                    edit: () => this.$refs.rapport_dialog.open(),
                    canImport: () => false,
                    canDelete: () => !this.isLocked(this.contract),
                    deleteFile: () => this.axios.delete("/contract/" + this.contract.contract_id + "/rapport"),
                    canSend: () => true,
                    send: async () =>
                        (
                            await this.axios.post("/document/rapport/temp?filename=" + this.getFilename("Rapport"), {
                                ...this.rapport,
                                contract_name: this.contract.contract_name,
                                company: this.contract.company,
                                contact: this.contract.display_name,
                                installer: this.installers.find((installer) => installer.id == this.rapport.installer)?.data?.long,
                            })
                        )?.data?.id,
                    canPrint: () => this.contract.status > 1,
                    print: () =>
                        this.$refs.print_dialog.open("formular", {
                            ...this.rapport,
                            contract_name: this.contract.contract_name,
                            company: this.contract.company,
                            contact: this.contract.display_name,
                            installer: this.installers.find((installer) => installer.id == this.rapport.installer)?.data?.long,
                        }),
                });
            }

            if (this.legend != undefined) {
                this.addToArray(filesystem, "Legende", {
                    name: "Legende",
                    icon: "fa-list-alt",
                    canOpen: () => true,
                    open: () => this.$refs.document_dialog.open("legend", { ...this.legend, contract_name: this.contract.contract_name }),
                    canOpenExternal: () => false,
                    canEdit: () => !this.isLocked(this.contract) && this.hasPermission("legend.edit"),
                    edit: () => this.$refs.legend_dialog.open(),
                    canImport: () => !this.isLocked(this.contract),
                    import: () => this.$refs.import_legend_dialog.open(this.contract.contract_id),
                    canDelete: () => !this.isLocked(this.contract) && this.hasPermission("formular.delete"),
                    deleteFile: () => this.axios.delete("/contract/" + this.contract.contract_id + "/rapport"),
                    canSend: () => true,
                    send: async () =>
                        (
                            await this.axios.post("/document/legend/temp?filename=" + this.getFilename("Legende"), {
                                ...this.legend,
                                contract_name: this.contract.contract_name,
                            })
                        )?.data?.id,
                    canPrint: () => this.contract.status > 1,
                    print: () => this.$refs.print_dialog.open("legend", { ...this.legend, contract_name: this.contract.contract_name }),
                });

                if (this.csv) {
                    this.addToArray(filesystem, "Legende", {
                        name: "Legende.csv",
                        icon: "fa-file-excel",
                        canOpen: () => false,
                        canOpenExternal: () => false,
                        canEdit: () => false,
                        canImport: () => false,
                        canDelete: () => false,
                        canSend: () => true,
                        send: async () => {
                            console.log("test");
                            let csv = "";
                            this.legend.groups?.forEach((group) => {
                                csv += `${group.title || ""}\n`;
                                group.items?.forEach((item) => {
                                    csv += `${item.key?.toUpperCase() || ""};${item.items?.map((_item) => _item.toUpperCase())?.join(", ")};${
                                        item.value || ""
                                    }\n`;
                                });
                                csv += "\n";
                            });
                            return (await this.axios.post("/document/legend/temp/csv?filename=" + this.getFilename("Legend"), { csv }))?.data?.id;
                        },
                        canPrint: () => false,
                    });
                }
            }

            filesystem = filesystem.map(this.map);
            filesystem = filesystem.filter(this.filter);

            filesystem.sort((a, b) => {
                if (a.children != undefined && b.children == undefined) return -1;
                if (a.children == undefined && b.children != undefined) return 1;
                if (a.children != undefined && b.children != undefined) {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                }
                if (a.children == undefined && b.children == undefined) {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                }
                return 0;
            });

            return filesystem;
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                this.selected = value;
            },
        },
        selected: {
            deep: true,
            handler(value) {
                if (value.length != this.value.length) this.$emit("input", value);
            },
        },
    },
    methods: {
        addToArray(array, key, item) {
            if (key == undefined && !this.hasPermission("file.read")) return;
            else if (key != undefined && !this.hasPermission(["file.read", key].join("."))) return;

            if (!item.id) item.id = item.name + " file";
            if (key != undefined) {
                const entry = array.find((e) => e.name == key);

                if (entry) {
                    if (!entry.children.find((e) => e.name == item.name)) entry.children.push(item);
                } else
                    array.push({
                        id: key + " group",
                        name: key,
                        children: [item],
                    });
            } else {
                array.push(item);
            }
        },
        hydrateFile(file) {
            if (this.image_mimes.includes(file.mimetype)) {
                const context = this.files
                    .reduce((value, group) => value.concat(group.children ? group.children : [group]), [])
                    .filter((item) => this.image_mimes.includes(item.mimetype))
                    .map((item) => item.id);
                return {
                    id: file.id || file.name + " file",
                    ...file,
                    icon: this.file_icons[file.mimetype],
                    canOpen: () => this.hasPermission("file.read." + file.group),
                    open: () => this.$refs.image_dialog.open(file.id, context),
                    canOpenExternal: () => true,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => !this.isLocked(this.contract) && this.hasPermission("file.delete"),
                    deleteFile: () => this.axios.delete("/file/" + file.id),
                    canSend: () => this.hasPermission("file.send"),
                    send: () => file.id,
                    canPrint: () => false,
                };
            } else if (this.data_mimes.includes(file.mimetype)) {
                return {
                    id: file.id || file.name + " file",
                    ...file,
                    icon: this.file_icons[file.mimetype],
                    canOpen: () => this.hasPermission("file.read." + file.group),
                    open: () => this.$refs.data_dialog.open(file.id, file.mimetype),
                    canOpenExternal: () => true,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => !this.isLocked(this.contract) && this.hasPermission("file.delete"),
                    deleteFile: () => this.axios.delete("/file/" + file.id),
                    canSend: () => this.hasPermission("file.send"),
                    send: () => file.id,
                    canPrint: () => false,
                };
            } else if (this.email_mimes.includes(file.mimetype)) {
                return {
                    id: file.id || file.name + " file",
                    ...file,
                    icon: this.file_icons[file.mimetype],
                    canOpen: () => this.hasPermission("file.read." + file.group),
                    open: () => this.$refs.email_dialog.open(file.id),
                    canOpenExternal: () => true,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => !this.isLocked(this.contract) && this.hasPermission("file.delete"),
                    deleteFile: () => this.axios.delete("/file/" + file.id),
                    canSend: () => false,
                    canPrint: () => false,
                };
            } else {
                return {
                    id: file.id || file.name + " file",
                    ...file,
                    icon: this.file_icons[file.mimetype],
                    canOpen: () => false,
                    canOpenExternal: () => true,
                    canEdit: () => false,
                    canImport: () => false,
                    canDelete: () => !this.isLocked(this.contract) && this.hasPermission("file.delete"),
                    deleteFile: () => this.axios.delete("/file/" + file.id),
                    canSend: () => this.hasPermission("file.send"),
                    send: () => file.id,
                    canPrint: () => false,
                };
            }
        },
        getFilename(name) {
            return encodeURIComponent(name + "_" + this.contract.contract_id);
        },
        openFile(files) {
            const file = files[0]?.children ? files[0].children[0] : files[0];
            if (!file) return;
            if (!file.canOpen()) return;
            if (this.selectable) return;
            file.open();
        },
        closeDialog() {
            this.active_items = [];
        },
        openExternal(item) {
            window.open("dfutura://open/" + item.id, "_self");
        },
        async sendMail(id) {
            var _id;
            if (typeof id === "object" && typeof id.then === "function") _id = await id;
            else _id = id;

            const subject = encodeURIComponent(this.contract.contract_name.replaceAll(",", "").replaceAll("(", "").replaceAll(")", ""));

            window.open(`dfutura://mail/${_id}?to=${this.contract.email}&subject=${subject}`, "_self");
        },
        openContext(item) {
            if (item.children) return;
            this.$refs.context_dialog.open(item);
        },
        selectFile(selector) {
            const parts = selector.split(".");
            if (parts.length == 1)
                this.selected.push(
                    this.filesystem.findLast((file) => new RegExp(".*" + parts[0] + ".*").test(file.name) && file.children == undefined)
                );
            if (parts.length == 2) {
                if (parts[1] == "*")
                    this.filesystem
                        .find((folder) => folder.name == parts[0] && Array.isArray(folder.children))
                        ?.children?.forEach((file) => this.selected.push(file));
                else
                    this.selected.push(
                        this.filesystem
                            .find((folder) => folder.name == parts[0] && Array.isArray(folder.children))
                            ?.children?.findLast((file) => new RegExp(".*" + parts[1] + ".*").test(file.name))
                    );
            }
        },
        resetSelection() {
            this.selected = [];
        },
    },
};
