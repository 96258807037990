<script>
import Layout from "@layouts/main.layout.vue";
import FilterLayout from "@layouts/filter.layout.vue";

export default {
    name: "FilterView",
    components: {
        Layout,
        FilterLayout,
    },
    data() {
        return {
            headers: [
                { text: "Auftrags Nr.", value: "contract_id" },
                { text: "Kommission", value: "contract_name" },
                { text: "Kunde", value: "company" },
                { text: "Status", value: "status" },
            ],
        };
    },
    computed: {
        contracts: {
            get() {
                return this.$store.state.filter.contracts;
            },
            set(value) {
                this.$store.commit("filter/setContracts", value);
            },
        },
        filters: {
            get() {
                return this.$store.state.filter.filter;
            },
            set(value) {
                this.$store.commit("filter/setFilter", value);
            },
        },
        query() {
            const result = {};
            this.filters.forEach((filter) => {
                if (filter.value) {
                    let query;
                    if (filter.text && filter.textQuery) {
                        query = filter.textQuery(filter.value);
                    } else {
                        query = filter.value;
                    }

                    if (query.length == 1) {
                        Object.keys(query[0]).forEach((key) => {
                            if (result[key] == undefined) result[key] = [];
                            result[key].push(query[0][key]);
                        });
                    } else if (query.length > 1) {
                        const stack = {};
                        query.forEach((query) => {
                            Object.keys(query).forEach((key) => {
                                if (stack[key] == undefined) stack[key] = { $or: [] };
                                if (stack[key].$or == undefined) stack[key].$or = [];
                                stack[key].$or.push(query[key]);
                            });
                        });

                        Object.keys(stack).forEach((key) => {
                            if (result[key] == undefined) result[key] = [];
                            result[key].push(stack[key]);
                        });
                    }
                }
            });
            return result;
        },
    },
    watch: {
        query: {
            deep: true,
            handler(query) {
                this.axios
                    .post("/contract/filter", query)
                    .then((res) => (this.contracts = res.data))
                    .catch(() => (this.contracts = []));
            },
        },
    },
    methods: {
        selectItem(item) {
            if (item?.contract_id) this.$router.push("/contract/" + item.contract_id);
        },
    },
};
</script>

<template>
    <Layout>
        <FilterLayout>
            <template #toolbar>
                <template v-for="(filter, index) in filters">
                    <v-text-field v-if="filter.text" :key="index" v-model="filter.value" :label="filter.name" outlined />
                    <v-select
                        v-else
                        :key="index"
                        v-model="filter.value"
                        :label="filter.name"
                        :items="filter.items"
                        item-text="name"
                        item-value="query"
                        outlined
                        :multiple="filter.multiple"
                        class="filter__select"
                    />
                </template>
            </template>
            <template #default>
                <v-data-table
                    :items="contracts"
                    :headers="headers"
                    multi-sort
                    :sort-by="['status', 'contract_id']"
                    :sort-desc="[true, true]"
                    style="height: 100%"
                    class="filter__table elevation-3"
                    @click:row="selectItem"
                >
                    <template #item.status="{ item }">
                        <v-chip v-if="item.status == 0 || item.status == undefined" small color="#ccc">Auftrag in Vorbereitung</v-chip>
                        <v-chip v-if="item.status == 1" small color="info">Auftrag in Arbeit</v-chip>
                        <v-chip v-if="item.status == 2" small color="accent">Auftrag geprüft</v-chip>
                        <v-chip v-if="item.status == 3" small color="success">Auftrag transportbereit</v-chip>
                        <v-chip v-if="item.status > 3" small color="error">Auftrag abgeschlossen</v-chip>
                    </template>
                </v-data-table>
            </template>
        </FilterLayout>
    </Layout>
</template>

<style lang="scss">
.v-text-field--outlined > .v-input__control > .v-input__slot,
.v-input.filter__select > div > div.v-input__slot {
    background: white;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important;
}

.filter__table > div.v-data-table__wrapper > table > tbody > tr {
    cursor: pointer;
}
</style>
