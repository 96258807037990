<script>
export default {
    name: "NavItemComponent",
    props: {
        active: { type: Boolean, default: false },
        to: { type: String, default: undefined },
    },
    computed: {
        _active() {
            return (this.to && this.$route.path == this.to) || this.active;
        },
        listeners() {
            return {
                ...this.$listeners,
                click: (event) => {
                    if (this.to != undefined && this.to != this.$route.path) this.$router.push(this.to);
                    else this.$emit("click", event);
                },
            };
        },
    },
};
</script>

<template>
    <div class="navitem" :class="{ active: _active }" v-on="listeners">
        <slot />
        <div id="navitem-notch" />
    </div>
</template>

<style lang="scss" scoped>
.navitem {
    position: relative;
    min-width: $navbar-height;
    height: $navbar-height;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #navitem-notch {
        position: absolute;
        width: $navbar-notch-size;
        height: $navbar-notch-size;
        bottom: 0;
        left: calc((100% - $navbar-notch-size) / 2);
        background: $background;
        opacity: 0;
        transform: translateY(calc($navbar-notch-transform + 0.3rem)) rotate(45deg);
        transition: transform $navbar-item-transition ease-in-out, opacity 0.1s ease-in-out;
    }
}

.navitem.active {
    #navitem-notch {
        opacity: 1;
    }
}
</style>
