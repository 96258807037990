export default {
    methods: {
        initDragzone(dropArea: HTMLElement, callback: (files) => void) {
            ["dragenter", "dragover", "dragleave", "drop"].forEach((event) => dropArea.addEventListener(event, preventDefaults, false));

            function preventDefaults(event) {
                event.preventDefault();
                event.stopPropagation();
            }

            ["dragenter", "dragover"].forEach((event) => dropArea.addEventListener(event, highlight, false));
            ["dragleave", "drop"].forEach((event) => dropArea.addEventListener(event, unhighlight, false));

            function highlight(event) {
                dropArea.classList.add("highlight");
            }

            function unhighlight(event) {
                dropArea.classList.remove("highlight");
            }

            dropArea.addEventListener("drop", handleDrop, false);

            function handleDrop(event) {
                const files = event.dataTransfer.files;
                callback([...files]);
            }
        },
    },
};
