<script>
import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "EntryTableComponent",
    components: {
        DialogLayout,
    },
    props: {
        value: { type: Array, default: () => [] },
        emty: { type: String, default: "Keine Einträge vorhanden" },
        definition: { type: String, default: "Eintrag" },
    },
    data: () => ({
        dialog: false,
        dialogDelete: false,
        data: [],
        editedIndex: -1,
        editedItem: {
            description: null,
            amount: 1,
        },
        defaultItem: {
            description: null,
            amount: 1,
        },
        headers: [
            { text: "Beschreibung", value: "description" },
            { text: "Anzahl", value: "amount", align: "end" },
            { text: "Aktionen", value: "actions", align: "end" },
        ],
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        data: {
            deep: true,
            handler(value) {
                this.$emit("input", value);
            },
        },
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                this.data = value;
            },
        },
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.data.splice(this.editedIndex, 1);
            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.data[this.editedIndex], this.editedItem);
            } else {
                this.data.push(this.editedItem);
            }
            this.close();
        },
    },
};
</script>

<template>
    <v-data-table :headers="headers" :items="data" hide-default-footer class="elevation-1">
        <template #footer>
            <DialogLayout v-model="dialog" width="400" :title="definition + ' bearbeiten'">
                <template #activator="{ on, attrs }">
                    <v-row class="px-5 pt-5">
                        <v-spacer></v-spacer>
                        <v-btn text class="mb-2" v-bind="attrs" v-on="on">{{ definition }} hinzufügen</v-btn>
                    </v-row>
                </template>
                <template #default>
                    <v-row>
                        <v-col cols="8">
                            <v-text-field v-model="editedItem.description" label="Beschreibung"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="editedItem.amount" label="Anzahl" type="number"></v-text-field>
                        </v-col>
                    </v-row>
                </template>
                <template #action>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="close">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="save">Speichern</v-btn>
                </template>
            </DialogLayout>
            <DialogLayout v-model="dialogDelete" width="300" :title="definition + ' löschen'">
                <template #default>{{ definition }} wirklich löschen?</template>
                <template #action>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDelete">Abbrechen</v-btn>
                    <v-btn color="error" text @click="deleteItemConfirm">Löschen</v-btn>
                </template>
            </DialogLayout>
        </template>
        <template #item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template #no-data>{{ emty }}</template>
    </v-data-table>
</template>
