<script>
import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "AccountMenuComponent",
    mixins: [permissionMixin],
    data() {
        return {
            state: false,
        };
    },
};
</script>

<template>
    <v-menu v-model="state" tile offset-y min-width="100%" max-width="100%">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" :isActive="state" />
        </template>
        <v-list>
            <v-list-item color="primary" to="/">
                <v-list-item-icon>
                    <v-icon>mdi-briefcase</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Aufträge</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isInGroup('avor')" color="primary" to="/calendar">
                <v-list-item-icon>
                    <v-icon>mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Kalender</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item color="primary" to="/norms">
                <v-list-item-icon>
                    <v-icon>mdi-gavel</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Normen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isInGroup('avor') || isInGroup('inspector')" color="primary" to="/filter">
                <v-list-item-icon>
                    <v-icon>mdi-magnify</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Filter</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped></style>
