<script>
import { mapState, mapMutations } from "vuex";

import PasswordDialog from "@components/dialogs/PasswordDialog.vue";
import AboutDialog from "@components/dialogs/AboutDialog.vue";

export default {
    name: "AccountMenuComponent",
    components: {
        PasswordDialog,
        AboutDialog,
    },
    data() {
        return {
            state: false,
        };
    },
    computed: {
        ...mapState({
            account: (state) => state.auth.account,
        }),
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
        }),
        logout() {
            this.axios
                .delete("/auth")
                .then(() => this.$router.push("/login"))
                .catch(() => this.logError("Es ist ein fehler aufgetreten"));
        },
    },
};
</script>

<template>
    <v-menu v-if="account" v-model="state" tile offset-y min-width="200">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" :isActive="state" />
        </template>
        <v-list>
            <v-list-item v-if="account" disabled>
                <v-list-item-icon />
                <v-list-item-content>
                    <v-list-item-title>{{ account.username }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <AboutDialog>
                <template #activator="{ attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-information-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Über</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </AboutDialog>
            <PasswordDialog>
                <template #activator="{ attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-key-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Passwort ändern</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </PasswordDialog>
            <v-list-item @click="logout">
                <v-list-item-icon>
                    <v-icon>mdi-power</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Abmelden</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped></style>
