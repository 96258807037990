<script>
import { mapState, mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "QuestionDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        contract: { type: Object, required: true },
        formular: { type: Object, default: undefined },
    },
    data() {
        return {
            dialog: false,
            data: undefined,
        };
    },
    computed: {
        ...mapState({
            account: (state) => state.auth.account,
        }),
    },
    watch: {
        dialog: {
            immediate: true,
            handler(value) {
                if (!value) this.$emit("close");
            },
        },
    },
    mounted() {
        window.addEventListener("message", this.handleEvent);
    },
    beforeDestroy() {
        window.removeEventListener("message", this.handleEvent);
    },
    methods: {
        ...mapMutations({
            logMessage: "ui/log",
            logError: "ui/error",
        }),
        open() {
            this.dialog = true;
        },
        save() {
            window.frames["question"].postMessage({ type: "save" }, "*");
        },
        saved() {
            this.logMessage("Formular wurde gespeichert");
            this.$emit("update:formular");
            this.dialog = false;
        },
        handleEvent(message) {
            if (message.data?.id == this.formular?.reference_id) {
                if (message.data.type == "save") this.saved();
                if (message.data.type == "error") this.logError("Das Formular konnte nicht gespeichert werden");
            }
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" fullscreen>
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #content="{ action }">
            <div class="question-main">
                <div class="question-toolbar">
                    <div class="toolbar-title">Schnittstellen-Fragebogen</div>
                    <v-spacer class="desktop-spacer"></v-spacer>
                    <v-btn dark text @click="action"><v-icon left>mdi-close</v-icon>Abbrechen</v-btn>
                    <v-spacer class="mobile-spacer"></v-spacer>
                    <v-btn dark text @click="save"><v-icon left>mdi-send</v-icon>Speichern</v-btn>
                </div>
                <div v-if="formular && account" class="question-wrapper">
                    <div class="question-content">
                        <iframe
                            name="question"
                            :src="$external + '/forms/question/' + formular.reference_id + '?auth=' + account.id"
                            frameborder="0"
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.question-main {
    display: grid;
    grid-template-areas:
        "toolbar"
        "content";
    grid-template-columns: 100vw;
    grid-template-rows: $navbar-height calc(100vh - $navbar-height);
    overflow: hidden;

    .question-toolbar {
        grid-area: toolbar;
        display: flex;
        align-items: center;
        padding: 0 2rem;
        gap: 1rem;
        background: $primary;
        color: white;

        .toolbar-title {
            font-size: 1.2rem;
        }

        .desktop-spacer {
            display: unset;
        }

        .mobile-spacer {
            display: none;
        }

        .toolbar-button {
            min-width: $navbar-height;
            height: $navbar-height;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background: lighten($primary, 5%);
            }
        }
    }

    .question-wrapper {
        grid-area: content;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $background;
        padding: 0 2rem;

        .question-content {
            width: 100%;
            height: 100%;
            max-width: 1200px;
            margin: 2rem;
            box-shadow: $box-shadow-1;
            background: white;
        }
    }
}

@media screen and (max-width: 650px) {
    .question-main {
        .question-toolbar {
            padding: 0 1rem;

            .toolbar-title {
                display: none;
            }

            .desktop-spacer {
                display: none;
            }

            .mobile-spacer {
                display: unset;
            }
        }

        .question-wrapper {
            padding: 0;
            .question-content {
                margin: 0;
            }
        }
    }
}
</style>
