<script>
import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "DataDialogComponent",
    components: {
        DialogLayout,
    },
    data() {
        return {
            dialog: false,
            data: undefined,
            id: undefined,
            mime: "application/pdf",
            width: 0,
        };
    },
    mounted() {
        this.width = window.innerWidth;
        window.onresize = () => (this.width = window.innerWidth);
    },
    methods: {
        open(id, mime) {
            if (this.id != id || this.mime != mime) this.data = undefined;
            this.id = id;
            this.mime = mime;
            this.dialog = true;
            if (!this.data)
                this.axios
                    .get("/media/" + id, { responseType: "blob" })
                    .then((res) => {
                        if (res) this.data = window.URL.createObjectURL(res.data);
                        else this.$router.go();
                    })
                    .catch((err) => console.log(err));
        },
        close(action) {
            action();
            this.$emit("input", this.dialog);
            this.$emit("close");
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" :fullscreen="width < 900" width="auto">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #content="{ action }">
            <div class="data-viewer">
                <div class="viewer-content">
                    <object v-if="data" :data="data" :type="mime"></object>
                    <div v-else class="loader"><v-progress-circular indeterminate /></div>
                </div>
                <div v-ripple class="viewer-close" @click="close(action)">Vorschau schliessen</div>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.data-viewer {
    overflow: hidden !important;
    width: 90vw;
    height: 90vh;
    display: grid;
    grid-template-areas:
        "content"
        "close";
    grid-template-columns: 100%;
    grid-template-rows: calc(100% - 3.5rem) 3.5rem;
    background: white;

    .viewer-content {
        grid-area: content;
        background: #525659;

        object {
            height: 100%;
            width: 100%;
        }

        .loader {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .viewer-close {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #323639;
        color: white;
        cursor: pointer;
    }
}

@media only screen and (max-width: 900px) {
    .data-viewer {
        width: 100vw;
        height: 100vh;
    }
}
</style>
