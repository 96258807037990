<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "EVUNormDialogComponent",
    components: {
        DialogLayout,
    },
    data() {
        return {
            form: false,
            evus: undefined,
            evu: undefined,
            text: undefined,
            requiredRule: [(v) => !!v || "Eingabe ist erforderlich"],
        };
    },
    methods: {
        ...mapMutations({
            logMessage: "ui/log",
            logError: "ui/error",
        }),
        close(action) {
            action();
            this.evus = undefined;
            this.evu = undefined;
            this.text = undefined;
            this.$emit("close");
        },
        async submit(action) {
            try {
                const norms = await this.axios.get("/evu/" + this.evu + "/norms");
                await this.axios.post("/evu/" + this.evu + "/norms", [...norms.data, { text: this.text }]);
                this.logMessage("Norm wurde hinzugefügt");
                this.$emit("update");
                this.close(action);
            } catch (error) {
                console.log(error);
                this.logError("Es ist ein Fehler aufgetreten");
            }
        },
        queryEVU(input) {
            if (!input) return;
            this.axios("/evu?query=" + input).then((res) => (this.evus = res.data));
        },
    },
};
</script>

<template>
    <DialogLayout title="EVU-Norm hinzufügen" width="500">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-form v-model="form" class="norm-form">
                <v-combobox
                    v-model="evu"
                    :rules="requiredRule"
                    label="EVU"
                    :items="evus"
                    item-text="evu_id"
                    item-value="evu_id"
                    :return-object="false"
                    @update:search-input="queryEVU($event)"
                />
                <v-textarea v-model="text" :rules="requiredRule" label="Norm-Text" />
            </v-form>
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="!form" @click="submit(action)">Speichern</v-btn>
        </template>
    </DialogLayout>
</template>

<style lang="scss">
.v-autocomplete__content.v-menu__content::-webkit-scrollbar-track {
    background: white !important;
}
</style>
