<script>
import { mapMutations } from "vuex";

import DocumentSendDialog from "@components/dialogs/DocumentSendDialog.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
	name: "EmailMenuComponent",
	components: {
		DocumentSendDialog,
	},
	mixins: [permissionMixin],
	props: {
		contract: { type: Object, required: true },
		files: { type: Array, default: () => [] },
		formular: { type: Object, default: undefined },
		rapport: { type: Object, default: undefined },
		legend: { type: Object, default: undefined },
	},
	data() {
		return {
			state: false,
		};
	},
	computed: {
		formularBody() {
			return `Guten Tag ${this.contract.display_name}
                    \rVielen Dank für den Auftrag.
                    \rWir führen ein Schnittstellenprotokoll welches wir für einen reibungslosen und speditiven Ablauf für die Produktion benötigen. Das Formular habe ich bereits teilweise ausgefüllt. Ich bitte dich um ein paar wenige Minuten zur Kontrolle und Vervollständigung des Protokolls. Sobald das Protokoll vollständig ist, kannst du das Formular speichern und es wird uns automatisch übermittelt.

                    \r${this.$external}/formular/${this.formular.reference_id}

                    \rVielen Dank für deine Mithilfe.\n\n\r`;
		},
	},
	methods: {
		...mapMutations({
			logError: "ui/error",
			logMessage: "ui/log",
		}),
		sendFormular() {
			if (!this.contract.email || !this.formular || !this.formular.reference_id)
				return this.logError("Es ist ein fehler aufgetreten");
			window.open(
				"mailto:" +
					this.contract.email +
					"?subject=" +
					encodeURIComponent(this.contract.contract_name) +
					"&body=" +
					encodeURIComponent(this.formularBody),
				"_self",
			);
		},
	},
};
</script>

<template>
    <v-menu v-model="state" tile offset-y>
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" :isActive="state" />
        </template>
        <v-list>
            <v-list-item v-if="hasPermission('email.send.Formular')" :disabled="!contract || !contract.email || !formular" @click="sendFormular">
                <v-list-item-icon>
                    <v-icon>mdi-comment-question-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Schnittstellenfragebogen senden</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <DocumentSendDialog :contract="contract" :files="files" :formular="formular" :rapport="rapport" :legend="legend">
                <template #activator="{ attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon><v-icon>mdi-email-newsletter</v-icon></v-list-item-icon>
                        <v-list-item-content><v-list-item-title>Dokumentenversand</v-list-item-title></v-list-item-content>
                    </v-list-item>
                </template>
            </DocumentSendDialog>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped></style>
