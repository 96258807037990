<script>
import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "FileDragComponent",
    mixins: [permissionMixin],
    props: {
        value: { type: Object, required: true },
    },
    data() {
        return {
            content: undefined,
        };
    },
    computed: {
        draggable() {
            return (
                this.value.id &&
                this.value.mimetype &&
                this.value.name &&
                this.hasPermission("file.read" + (this.value.group ? "." + this.value.group : ""))
            );
        },
    },
    mounted() {
        const instance = this;
        if (this.draggable) {
            this.axios.get("/media/" + this.value.id, { responseType: "blob" }).then((res) => {
                if (!res || !res.data) return;
                const reader = new FileReader();
                reader.readAsDataURL(res.data);
                reader.onloadend = () => (instance.content = this.value.mimetype + ":" + this.value.name + ":" + reader.result);
            });
        }
    },
    methods: {
        drag(event) {
            event.dataTransfer.setData("DownloadURL", this.content);
        },
    },
};
</script>

<template>
    <div ref="file_drag" :draggable="draggable" @dragstart="drag"><slot /></div>
</template>

<style lang="scss" scoped></style>
