<script>
export default {
    name: "ExpansionPanelComponent",
    data() {
        return {
            expand: false,
        };
    },
};
</script>

<template>
    <div class="expansion-panel" :class="{ expand }" @click="expand = !expand">
        <div class="header">
            <div><slot name="header" :expand="expand" /></div>
            <div class="icon">
                <v-icon>{{ expand ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </div>
        </div>
        <slot v-if="expand" />
    </div>
</template>

<style lang="scss" scoped>
.expansion-panel {
    .header {
        display: grid;
        grid-template-columns: auto 3rem;
        z-index: 1;

        .icon {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
        }
    }

    .content {
        opacity: 0;
    }
}
</style>
