<script>
import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "AboutDialogComponent",
    components: {
        DialogLayout,
    },
};
</script>

<template>
    <DialogLayout title="Über diese Software" width="500">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <div>
                Diese Software wurde von Nextlvlup KlG in zusammenarbeit mit D.Fallegger Elektro Tableaux AG entwickelt. Alle Rechte an Design,
                Funktion und Quellcode liegen bei Nextlvlup KlG. Die Rechte an den verwendeten Bilddateien liegen bei den entsprechenden Urheber.
            </div>
            <div class="footer">
                <img src="@assets/nlu_rgb.png" width="150" />
                <div style="text-align: center; font-weight: bold">v.2.0.0</div>
                <a href="https://www.nextlvlup.com">www.nextlvlup.com</a>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.footer {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
        color: #c90000;
    }
}
</style>
