var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(_vm.value)?_c('v-card',{key:_vm.value.name,attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"px-3"},[_c('v-spacer'),_c('h3',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.value.name))]),_c('v-spacer'),(_vm.value.type == 'evu' && _vm.hasPermission('norm.edit.evu'))?_c('EVURenameDialog',{attrs:{"evu":_vm.value.name},on:{"update":function($event){return _vm.$emit('update')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,false,1536936098)}):_vm._e(),(_vm.value.type == 'evu' && _vm.hasPermission('norm.remove.evu'))?_c('ConfirmDialog',{attrs:{"title":"EVU-Norm löschen?","text":"Soll dieser Eintrag gelöscht werden?"},on:{"resolve":_vm.removeEVU},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}],null,false,1190856762)}):_vm._e()],1),_c('v-divider',{staticClass:"my-4"}),_c('ul',_vm._l((_vm.value.norms),function(norm,index){return _c('li',{key:index},[_c('div',{staticClass:"norm-item"},[_vm._v(" "+_vm._s(norm.text)+" "),_c('v-spacer'),_c('div',{staticClass:"item-action"},[(_vm.hasPermission('norm.edit.' + _vm.value.type))?_c('ConfirmDialog',{attrs:{"title":"Norm-Eintrag löschen?","text":norm.text},on:{"resolve":function($event){return _vm.removeItem(norm)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}],null,true)}):_vm._e()],1)],1)])}),0)],1)],1):_c('div',[_vm._v("Kein Eintrag ausgewählt")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }