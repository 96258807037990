<script>
import DialogLayout from "@layouts/dialog.layout.vue";
import PrintDialog from "@components/dialogs/PrintDialog.vue";

export default {
    name: "PrintAmountDialogComponent",
    components: {
        DialogLayout,
        PrintDialog,
    },
    props: {
        disabled: { type: Boolean, default: undefined },
    },
    data() {
        return {
            dialog: false,
            template: null,
            data: null,
            doctype: null,
            amount: 1,
        };
    },
    methods: {
        open(template, data, doctype, disabled = false) {
            this.template = template;
            this.data = data;
            this.doctype = doctype;
            if (this.disabled || disabled) {
                this.amount = 1;
                this.awaitElement("print_dialog").then(() => this.print());
            } else this.dialog = true;
        },
        close() {
            this.dialog = false;
            this.template = null;
            this.data = null;
            this.doctype = null;
            this.amount = 1;
            this.$emit("close");
        },
        printNext() {
            this.amount--;
            this.print();
        },
        print() {
            document.activeElement.blur();

            setTimeout(() => {
                if (this.amount > 0) this.$refs.print_dialog.open(this.template, this.data, this.doctype);
                else this.close();
            }, 10);
        },
        awaitElement(id) {
            return new Promise((resolve) => {
                let timer = setInterval(() => {
                    const el = document.getElementById(id);
                    if (el != null) {
                        clearInterval(timer);
                        resolve(el);
                    }
                }, 100);
            });
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" title="Dokument Drucken" width="300" v-bind="$attrs">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
            <PrintDialog id="print_dialog" ref="print_dialog" @close="printNext" />
        </template>
        <template #default>
            <v-text-field v-model="amount" type="number" label="Anzahl" />
        </template>
        <template #action>
            <v-btn color="error" text @click="close">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="print">Drucken</v-btn>
        </template>
    </DialogLayout>
</template>
