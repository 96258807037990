<script>
import { mapGetters, mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";
import ConfirmDialog from "@components/dialogs/ConfirmDialog.vue";
import DatePicker from "@components/DatePicker.vue";

export default {
    name: "RapportDialogComponent",
    components: {
        DialogLayout,
        ConfirmDialog,
        DatePicker,
    },
    props: {
        contract: { type: Object, required: true },
        rapport: { type: Object, default: undefined },
    },
    data() {
        return {
            dialog: false,
            data: {
                steps: [],
            },
            text_presets: [
                "Besichtigung",
                "Vorbereitung",
                "Anfahrt",
                "Ausführung gemäss Auftrag",
                "Pause",
                "Rückfahrt",
                "Rapportieren",
                "Nachbearbeitung",
            ],
            time_rule: [(v) => /^[012]\d:[012345]\d$/.test(v) || "hh:mm"],
        };
    },
    computed: {
        ...mapGetters({
            installers: "user/installers",
        }),
    },
    watch: {
        rapport: {
            immediate: true,
            deep: true,
            handler(value) {
                this.data = value || {};
                if (this.data.steps == undefined || this.data.steps.length == 0) this.data.steps = [{ multiplier: 1, date: Date.now() }];
                if (this.data.material == undefined || this.data.material.length == 0)
                    this.data.material = [{ material: "Kleinmaterial", amount: 1, description: "Hülsen, Kabelbinder, etc" }];
            },
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        open() {
            this.dialog = true;
        },
        close(action) {
            action();
            this.$emit("close");
        },
        save(action) {
            document.activeElement.blur();
            setTimeout(
                () =>
                    this.axios
                        .post("/contract/" + this.contract.contract_id + "/rapport", this.data)
                        .then(() => this.logMessage("Rapport wurde gespeichert"))
                        .then(() => this.$emit("update:rapport"))
                        .then(() => this.close(action))
                        .catch(() => this.logError("Fehler beim speichern des Rapports")),
                10
            );
        },
        addStep(index) {
            if (this.data.steps == undefined) this.data.steps = [];
            if (index && index < this.data.steps.length - 1) return;
            const previous = this.data.steps[this.data.steps.length - 1];
            this.data.steps.push({ description: "", multiplier: previous?.multiplier || 1, from: previous?.to, date: previous?.date });
        },
        removeStep(index) {
            this.data.steps.splice(index, 1);
        },
        addMaterial(index) {
            if (this.data.material == undefined) this.data.material = [];
            if (index && index < this.data.material.length - 1) return;
            this.data.material.push({ material: "", amount: 1, description: "" });
        },
        removeMaterial(index) {
            this.data.material.splice(index, 1);
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" fullscreen>
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #content="{ action }">
            <div class="rapport-main">
                <div class="rapport-toolbar">
                    <div class="toolbar-title">Rapport</div>
                    <v-spacer></v-spacer>
                    <ConfirmDialog title="Änderungen verwerfen?" text="Alle aktuellen Änderungen verwerfen?" @resolve="close(action)">
                        <template #activator="{ attrs, on }">
                            <div v-ripple class="toolbar-button" v-bind="attrs" v-on="on"><v-icon dark>mdi-close</v-icon></div>
                        </template>
                    </ConfirmDialog>
                    <div v-ripple class="toolbar-button" @click="save(action)"><v-icon dark>mdi-content-save</v-icon></div>
                </div>
                <div class="rapport-wrapper">
                    <div class="rapport-content">
                        <div class="rapport-header">
                            <v-select v-model="data.installer" :items="installers" solo item-text="data.long" item-value="id" label="Montageleiter" />
                            <v-textarea v-model="data.notes" label="Anmerkungen" solo />
                        </div>
                        <div class="rapport-body">
                            <div class="rapport-group">
                                <div class="group-title">Arbeitsschritte</div>
                                <div class="group-content">
                                    <div v-for="(step, index) in data.steps" :key="index" class="step">
                                        <v-combobox v-model="step.description" :items="text_presets" placeholder="Arbeit" />
                                        <v-text-field v-model="step.multiplier" label="Personen" type="number" />
                                        <v-text-field v-model="step.from" label="Von" :rules="time_rule" />
                                        <v-text-field v-model="step.to" label="Bis" :rules="time_rule" />
                                        <DatePicker v-model="step.date" label="Datum" @tab="addStep(index)" />
                                        <v-btn icon color="error" tabindex="-1" @click="removeStep(index)"><v-icon>mdi-close</v-icon></v-btn>
                                    </div>
                                    <v-btn @click="addStep">Eintrag hinzufügen</v-btn>
                                </div>
                            </div>
                            <div class="rapport-group">
                                <div class="group-title">Material</div>
                                <div class="group-content">
                                    <div v-for="(material, index) in data.material" :key="index" class="material">
                                        <v-text-field v-model="material.material" label="Material" />
                                        <v-text-field v-model="material.amount" label="Anzahl" type="number" />
                                        <v-text-field v-model="material.description" label="Beschreibung" @keydown.tab="addMaterial(index)" />
                                        <v-btn icon color="error" tabindex="-1" @click="removeMaterial(index)"><v-icon>mdi-close</v-icon></v-btn>
                                    </div>
                                    <v-btn @click="addMaterial">Eintrag hinzufügen</v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.rapport-main {
    display: grid;
    grid-template-areas:
        "toolbar"
        "content";
    grid-template-columns: 100vw;
    grid-template-rows: $navbar-height calc(100vh - $navbar-height);
    overflow: hidden;
    background-color: red;

    .rapport-toolbar {
        grid-area: toolbar;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        background: $primary;
        color: white;

        .toolbar-title {
            font-size: 1.2rem;
        }

        .toolbar-button {
            min-width: $navbar-height;
            height: $navbar-height;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background: lighten($primary, 5%);
            }
        }
    }

    .rapport-wrapper {
        grid-area: content;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
        background: $background;

        .rapport-content {
            width: 100%;
            height: 100%;
            max-width: 1200px;
            display: grid;
            grid-template-areas:
                "header"
                "content";
            grid-template-columns: auto;
            grid-template-rows: 16rem calc(100% - 16rem);

            .rapport-header {
                grid-area: header;
                display: flex;
                flex-direction: column;
                padding: 0 1rem;
            }

            .rapport-body {
                grid-area: content;
                display: flex;
                flex-direction: column;
                padding: 1rem;
                gap: 2rem;
                overflow-y: auto;
                padding-bottom: 2rem;

                .rapport-group {
                    display: grid;
                    grid-template-columns: 100%;
                    grid-template-rows: 4rem auto;
                    background: white;
                    box-shadow: $box-shadow-1;

                    .group-title {
                        padding: 0 1rem;
                        font-size: 22px;
                        box-shadow: $box-shadow-1;
                        display: flex;
                        align-items: center;
                    }

                    .group-content {
                        display: flex;
                        flex-direction: column;
                        padding: 1rem;

                        .step {
                            display: grid;
                            grid-template-columns: auto 5rem 8rem 8rem 8rem 3rem;
                            align-items: center;
                            gap: 1rem;
                        }

                        .material {
                            display: grid;
                            grid-template-columns: 10rem 5rem auto 3rem;
                            align-items: center;
                            gap: 1rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
