<script>
export default {
    name: "HomeLayout",
    props: {
        value: { type: String, default: undefined },
        title: { type: String, default: "Aufträge" },
    },
    data() {
        return {
            norms: undefined,
            carousel: undefined,
        };
    },
    computed: {
        mapedNorms() {
            if (!this.norms) return undefined;
            let result = [];
            for (const item of this.norms.customer) {
                result = [...result, ...item.norms.map((norm) => ({ name: item.name, text: norm.text, timestamp: norm.timestamp }))];
            }
            for (const item of this.norms.evu) {
                result = [...result, ...item.norms.map((norm) => ({ name: item.name, text: norm.text, timestamp: norm.timestamp }))];
            }
            return result;
        },
    },
    mounted() {
        this.axios.get("/norms?time=14").then((res) => (this.norms = res.data));
    },
};
</script>

<template>
    <div class="home-layout">
        <template v-if="norms">
            <div class="layout-title">Neuigkeiten:</div>
            <div class="carousel">
                <v-carousel
                    v-model="carousel"
                    height="150"
                    class="elevation-2"
                    interval="8000"
                    cycle
                    progress
                    light
                    hide-delimiters
                    hide-delimiter-background
                    show-arrows-on-hover
                >
                    <template v-if="mapedNorms && mapedNorms.length > 0">
                        <v-carousel-item v-for="(norm, index) in mapedNorms" :key="index">
                            <div class="carousel-item">
                                <div class="text-overline">{{ norm.name }}</div>
                                <div>{{ norm.text }}</div>
                                <div class="counter">{{ carousel + 1 }} / {{ mapedNorms.length }}</div>
                            </div>
                        </v-carousel-item>
                    </template>
                    <div v-else class="news-emptystate text-overline">keine aktuellen Neuigkeiten</div>
                </v-carousel>
            </div>
        </template>
        <div class="layout-title">{{ title }}</div>
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.home-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    overflow: hidden;

    .layout-title {
        width: 100%;
        max-width: 1000px;
        font-size: 20px;
        font-weight: bold;
        padding: 1rem;
    }

    .carousel {
        width: 100%;
        max-width: 1000px;
        padding: 0 1rem;

        .carousel-item {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            background: white;
            text-align: center;
            font-weight: bold;

            .counter {
                font-weight: normal;
                font-size: 12px;
                margin-top: auto;
            }
        }

        .news-emptystate {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content {
        width: 100%;
        height: 100%;
        max-width: 1000px;
        display: grid;
        gap: 1rem;
        overflow-y: auto;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        align-content: flex-start;
    }
}
</style>
