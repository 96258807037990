var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogLayout',{attrs:{"title":"Benachrichtigung erstellen","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}},{key:"default",fn:function(){return [_c('v-form',{model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-select',{attrs:{"rules":_vm.requiredRule,"label":"Empfänger","items":_vm.users,"item-text":_vm.getName,"item-value":"id","multiple":"","chips":""},model:{value:(_vm.data.receiver),callback:function ($$v) {_vm.$set(_vm.data, "receiver", $$v)},expression:"data.receiver"}}),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Zu erledigen bis","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":"1"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.datePicker = false}}},[_vm._v("Abbrechen")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v("Ok")])],1)],1),_c('v-textarea',{attrs:{"rules":_vm.requiredRule,"label":"Nachricht","placeholder":"Aufgabe noch erledigen..."},model:{value:(_vm.data.message),callback:function ($$v) {_vm.$set(_vm.data, "message", $$v)},expression:"data.message"}})],1)]},proxy:true},{key:"action",fn:function(ref){
var action = ref.action;
return [_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.close(action)}}},[_vm._v("Abbrechen")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.form},on:{"click":function($event){return _vm.save(action)}}},[_vm._v("Senden")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }