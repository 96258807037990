<script>
import { mapState, mapMutations, mapActions } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "NotificationDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        contract: { type: Object, required: true },
    },
    data() {
        return {
            datePicker: false,
            date: undefined,
            data: {
                receiver: [],
                duedate: undefined,
                message: undefined,
            },
            requiredRule: [(v) => !!v || "Eingabe ist erforderlich"],
            form: false,
        };
    },
    computed: {
        ...mapState({
            users: (state) => state.user.users.sort((a, b) => (a.data?.long || a.username) > (b.data?.long || b.username) ? 1 : -1),
        }),
    },
    watch: {
        date(value) {
            if (value) this.data.duedate = Date.parse(value);
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        ...mapActions({
            fetchNotifications: "notification/fetchNotifications",
        }),
        close(action) {
            action();
            this.date = undefined;
            this.data = {
                receiver: [],
                duedate: undefined,
                message: undefined,
            };
            this.$emit("close");
        },
        save(action) {
            this.axios
                .post("/notification", {
                    ...this.data,
                    title: "Erinnerung: " + this.contract.contract_name,
                    contract: this.contract.contract_id,
                })
                .then(() => {
                    this.logMessage("Benachrichtigung wurde erstellt");
                    this.fetchNotifications();
                    this.close(action);
                })
                .catch(() => this.logError("Es ist ein fehler aufgetreten"));
        },
        getName(item) {
            return item.data?.long || item.username;
        },
    },
};
</script>

<template>
    <DialogLayout title="Benachrichtigung erstellen" width="500">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-form v-model="form">
                <v-select
                    v-model="data.receiver"
                    :rules="requiredRule"
                    label="Empfänger"
                    :items="users"
                    :item-text="getName"
                    item-value="id"
                    multiple
                    chips
                ></v-select>
                <v-dialog ref="dialog" v-model="datePicker" :return-value.sync="date" persistent width="290px">
                    <template #activator="{ on, attrs }">
                        <v-text-field v-model="date" label="Zu erledigen bis" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" scrollable first-day-of-week="1">
                        <v-btn text color="error" @click="datePicker = false">Abbrechen</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.dialog.save(date)">Ok</v-btn>
                    </v-date-picker>
                </v-dialog>
                <v-textarea v-model="data.message" :rules="requiredRule" label="Nachricht" placeholder="Aufgabe noch erledigen..." />
            </v-form>
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="!form" @click="save(action)">Senden</v-btn>
        </template>
    </DialogLayout>
</template>
