export interface NormState {
    customer_norms: Array<Norm>;
    evu_norms: Array<Norm>;
}

export interface Norm {
    id: string;
    name: string;
    company: string;
    street: string;
    city: string;
    plz: string;
    country: string;
}

export default {
    namespaced: true,
    state: (): NormState => ({
        customer_norms: [],
        evu_norms: [],
    }),
    getters: {
        norms(state) {
            const results = [...state.customer_norms, ...state.evu_norms];
            results.sort((a, b) => (a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0));
            return results;
        },
    },
    mutations: {
        setCustomerNorms(state, value) {
            state.customer_norms = value;
        },
        addCustomerNorm(state, value) {
            state.customer_norms = state.customer_norms.concat(value);
        },
        setEVUNorms(state, value) {
            state.evu_norms = value;
        },
        addEVUNorm(state, value) {
            state.evu_norms = state.evu_norms.concat(value);
        },
    },
    actions: {
        fetchNorms({ commit }) {
            this._vm.axios
                .get("/norms?time=30")
                .then((res) => {
                    commit("setCustomerNorms", []);
                    res.data.customer.forEach((customer) => {
                        this._vm.axios
                            .get("/customer/" + customer.id)
                            .then((result) => {
                                customer.norms.forEach((norm) => {
                                    commit("addCustomerNorm", {
                                        id: customer.id,
                                        name: result.data.company,
                                        ...norm,
                                    });
                                });
                            })
                            .catch(() => commit("ui/error", "Kunde " + customer.id + " konnte nicht geladen werden", { root: true }));
                    });

                    commit("setEVUNorms", []);
                    res.data.evu.forEach((evu) => {
                        evu.norms.forEach((norm) => {
                            commit("addEVUNorm", {
                                id: evu.id,
                                name: evu.id,
                                ...norm,
                            });
                        });
                    });
                })
                .catch((err) => {
                    if (!err.response || !err.response.status) return commit("ui/error", "Unbekannter Fehler", { root: true });
                    if (err.response.status == 400) return commit("ui/error", "Normen konnten nicht geladen werden", { root: true });
                    if (err.response.status == 500) return commit("ui/error", "Server Fehler", { root: true });
                });
        },
    },
};
