var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogLayout',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}},{key:"content",fn:function(ref){
var action = ref.action;
return [_c('div',{staticClass:"legend-main"},[_c('div',{staticClass:"legend-toolbar"},[_c('div',{staticClass:"toolbar-title"},[_vm._v("Legende")]),_c('v-spacer'),_c('ConfirmDialog',{attrs:{"title":"Änderungen verwerfen?","text":"Alle aktuellen Änderungen verwerfen?"},on:{"resolve":function($event){return _vm.close(action)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"toolbar-button"},'div',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}],null,true)}),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"toolbar-button",on:{"click":function($event){return _vm.save(action)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-content-save")])],1)],1),_c('div',{staticClass:"legend-wrapper"},[_c('div',{staticClass:"legend-content"},[_c('div',{staticClass:"legend-header"},[_c('v-text-field',{attrs:{"placeholder":"Legenden Titel (Adresse)","solo":""},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('v-select',{attrs:{"value":_vm.data.orientation ? 'Querformat' : 'Hochformat',"items":['Hochformat', 'Querformat'],"label":"Ausrichtung","solo":""},on:{"input":function($event){_vm.data.orientation = $event == 'Querformat'}}})],1),_c('div',{staticClass:"legend-body"},[_c('div',{staticClass:"group-content"},_vm._l((_vm.data.groups),function(group,groupIndex){return _c('div',{key:groupIndex,staticClass:"legend-group"},[_c('div',{staticClass:"group-header"},[_c('div',{staticClass:"item-header"},[_vm._v("Gruppenbezeichnung")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.moveTo(groupIndex, groupIndex - 1)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.moveTo(groupIndex, groupIndex + 1)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('ConfirmDialog',{attrs:{"title":"Gruppe entfehrnen?","text":"Soll diese Gruppe entfehrnt werden?"},on:{"resolve":function($event){return _vm.removeGroup(groupIndex)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)})],1),_c('div',{staticClass:"group-info"},[_c('v-text-field',{attrs:{"label":"Gruppenbezeichnung","placeholder":"Ab Messung ..."},model:{value:(group.title),callback:function ($$v) {_vm.$set(group, "title", $$v)},expression:"group.title"}}),_c('v-text-field',{attrs:{"label":"Feldbezeichnung","placeholder":"Feld ..."},model:{value:(group.field),callback:function ($$v) {_vm.$set(group, "field", $$v)},expression:"group.field"}})],1),_c('div',{staticClass:"legend-item legend-title mb-3"},[_c('div',{staticClass:"item-header"},[_vm._v("BMK")]),_c('div',{staticClass:"item-header"},[_vm._v("Andere Geräte")]),_c('div',{staticClass:"item-header"},[_vm._v("Beschreibung")])]),_vm._l((group.items),function(entry,entryIndex){return _c('div',{key:entryIndex,staticClass:"legend-item"},[_c('v-text-field',{staticClass:"uppercase",attrs:{"dense":"","placeholder":"BMK"},model:{value:(entry.key),callback:function ($$v) {_vm.$set(entry, "key", $$v)},expression:"entry.key"}}),_c('v-combobox',{staticClass:"uppercase",attrs:{"dense":"","placeholder":"Andere Geräte","multiple":"","chips":"","append-icon":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("mdi-close")])],1)]}}],null,true),model:{value:(entry.items),callback:function ($$v) {_vm.$set(entry, "items", $$v)},expression:"entry.items"}}),_c('v-combobox',{attrs:{"items":_vm.text_presets,"dense":"","placeholder":"Beschreibung","append-icon":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.newLine(group, entryIndex, $event)}},model:{value:(entry.value),callback:function ($$v) {_vm.$set(entry, "value", $$v)},expression:"entry.value"}}),_c('div',{staticClass:"item-action"},[_c('v-btn',{attrs:{"icon":"","tabindex":"-1"},on:{"click":function($event){return _vm.insertLine(group, entryIndex)}}},[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1),_c('v-btn',{attrs:{"icon":"","tabindex":"-1"},on:{"click":function($event){return _vm.removeLine(group, entryIndex)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)})],2)}),0),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"legend-button primary white--text",on:{"click":_vm.addGroup}},[_vm._v("Gruppe hinzufügen")])])])])])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }