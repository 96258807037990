var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogLayout',{attrs:{"title":_vm.contract.status == 3 ||
        (_vm.contract.status == 1 && _vm.contract.data && (_vm.contract.data.service || _vm.contract.data.contract_type == 'service')) ||
        (_vm.contract.status == 1 && _vm.contract.data && _vm.contract.data.contract_type == 'material') ||
        (_vm.contract.status == 1 && _vm.contract.data && _vm.contract.data.contract_type == 'empty_system')
            ? 'Auftrag abschliessen'
            : 'Auftrag wiederherstellen',"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}},{key:"default",fn:function(){return [(_vm.contract.status == 1)?_c('div',[_vm._v("Soll dieser Auftrag abgeschlossen werden?")]):(_vm.contract.status == 3)?_c('div',[_vm._v("Soll dieser Auftrag abgeschlossen werden?")]):_c('div',[_vm._v("Soll der Auftragsstatus zurückgesetzt werden?")])]},proxy:true},{key:"action",fn:function(ref){
            var action = ref.action;
return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.close(action)}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.save(action)}}},[_vm._v("Bestätigen")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }