<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "PrintDialogComponent",
    components: {
        DialogLayout,
    },
    data() {
        return {
            dialog: false,
            width: 0,
        };
    },
    mounted() {
        this.width = window.innerWidth;
        window.onresize = () => (this.width = window.innerWidth);
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        open(template, data, doctype) {
            this.axios
                .post("/document/" + template + "/print" + (doctype ? `?doctype=${doctype}` : ""), data)
                .then(() => {
                    this.logMessage("Die Datei wurde gedruckt");
                    this.close();
                })
                .catch(() => {
                    this.logError("Beim drucken der Datei ist ein Fehler aufgetreten");
                    this.close();
                });
            this.dialog = true;
        },
        close() {
            this.dialog = false;
            this.$emit("close");
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" persistent width="400">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #content>
            <div class="print-viewer">
                <div class="loader">
                    <div class="text-button">Datei wird gedruckt</div>
                    <v-progress-circular indeterminate />
                </div>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.print-viewer {
    height: 250px;
    overflow: hidden;

    .loader {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
