var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"tile":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs,"isActive":_vm.state})]}}],null,true),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-list',[(_vm.hasPermission('norm.edit.customer'))?_c('CustomerNormDialog',{on:{"close":function($event){_vm.state = false},"update":function($event){return _vm.$emit('update')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Kundennorm hinzufügen")])],1)],1)]}}],null,false,2020593987)}):_vm._e(),(_vm.hasPermission('norm.edit.evu'))?_c('EVUNormDialog',{on:{"close":function($event){_vm.state = false},"update":function($event){return _vm.$emit('update')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-domain")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("EW-Norm hinzufügen")])],1)],1)]}}],null,false,3216884962)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }