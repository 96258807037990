<script>
import { mapMutations } from "vuex";

import CustomerNormDialog from "@components/dialogs/CustomerNormDialog.vue";
import EVUNormDialog from "@components/dialogs/EVUNormDialog.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "NormMenuComponent",
    components: {
        CustomerNormDialog,
        EVUNormDialog,
    },
    mixins: [permissionMixin],
    data() {
        return {
            state: false,
        };
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
    },
};
</script>

<template>
    <v-menu v-model="state" tile offset-y>
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" :isActive="state" />
        </template>
        <v-list>
            <CustomerNormDialog v-if="hasPermission('norm.edit.customer')" @close="state = false" @update="$emit('update')">
                <template #activator="{ attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Kundennorm hinzufügen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </CustomerNormDialog>
            <EVUNormDialog v-if="hasPermission('norm.edit.evu')" @close="state = false" @update="$emit('update')">
                <template #activator="{ attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-domain</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>EW-Norm hinzufügen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </EVUNormDialog>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped></style>
