<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";
import MaterialTable from "@components/contract/MaterialTable.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "MaterialDialogComponent",
    components: {
        DialogLayout,
        MaterialTable,
    },
    mixins: [permissionMixin],
    props: {
        contract: { type: Object, required: true },
    },
    data() {
        return {
            dialog: false,
            form: false,
            material: [],
            width: 0,
        };
    },
    watch: {
        contract: {
            immediate: true,
            deep: true,
            handler(value) {
                this.material = value.data?.material;
            },
        },
    },
    mounted() {
        this.width = window.innerWidth;
        window.onresize = () => (this.width = window.innerWidth);
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        open() {
            this.dialog = true;
        },
        save() {
            this.axios
                .post("/contract/" + this.contract.contract_id + "/data", { material: this.material })
                .then(() => this.$emit("update:contract"))
                .catch(() => this.logError("Die Materialinformationen konnten nicht gespeichert werden"));
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" title="Material" :fullscreen="width < 900" width="auto" @close="$emit('close')">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <MaterialTable v-model="material" :contract="contract" class="mb-3" @update="save" />
        </template>
        <template #action="{ action }">
            <v-spacer></v-spacer>
            <v-btn text @click="action">Schliessen</v-btn>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.datatitle {
    padding: 0.5rem 5px;
    font-size: 16px;
}
</style>
