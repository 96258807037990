<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "ArchiveDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        contract: { type: Object, required: true },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        close(action) {
            action();
            this.$emit("close");
        },
        async save(action) {
            try {
                if (this.contract.status == 1) await this.axios.patch("/contract/" + this.contract.contract_id + "/status");
                else if (this.contract.status == 3) await this.axios.post("/contract/" + this.contract.contract_id + "/status");
                else await this.axios.delete("/contract/" + this.contract.contract_id + "/status");

                this.logMessage("Der Auftragsstatus wurde geändert");
                this.$emit("update:contract");
                this.close(action);
            } catch (error) {
                this.logError("Es ist ein fehler aufgetreten");
            }
        },
    },
};
</script>

<template>
    <DialogLayout
        :title="
            contract.status == 3 ||
            (contract.status == 1 && contract.data && (contract.data.service || contract.data.contract_type == 'service')) ||
            (contract.status == 1 && contract.data && contract.data.contract_type == 'material') ||
            (contract.status == 1 && contract.data && contract.data.contract_type == 'empty_system')
                ? 'Auftrag abschliessen'
                : 'Auftrag wiederherstellen'
        "
        width="400"
    >
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <div v-if="contract.status == 1">Soll dieser Auftrag abgeschlossen werden?</div>
            <div v-else-if="contract.status == 3">Soll dieser Auftrag abgeschlossen werden?</div>
            <div v-else>Soll der Auftragsstatus zurückgesetzt werden?</div>
        </template>
        <template #action="{ action }">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-btn color="primary" text @click="save(action)">Bestätigen</v-btn>
        </template>
    </DialogLayout>
</template>
