<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "InspectionDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        contract: { type: Object, required: true },
        checklist: { type: Object, default: () => {} },
    },
    data() {
        return {
            report: {},
        };
    },
    watch: {
        checklist: {
            immediate: true,
            deep: true,
            handler(value) {
                this.report = value;
            },
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        close(action) {
            action();
            this.$emit("close");
        },
        async save(action) {
            try {
                await this.axios.post("/contract/" + this.contract.contract_id + "/status");
                this.logMessage("Der Auftragsstatus wurde geändert");
                this.$emit("update:contract");
                this.close(action);
            } catch (error) {
                this.logError("Es ist ein fehler aufgetreten");
            }
        },
    },
};
</script>

<template>
    <DialogLayout title="Autrag freigeben" width="400">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-checkbox v-model="report.dispo" dense readonly label="Dispo vorhanden" />
            <v-checkbox v-model="report.schema" dense readonly label="Schema vorhanden" />
            <v-checkbox v-model="report.formular" dense readonly label="Schnittstellenprotokoll komplett" />
        </template>
        <template #action="{ action }">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-btn color="primary" text @click="save(action)">Freigeben</v-btn>
        </template>
    </DialogLayout>
</template>
