<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Layout from "@layouts/main.layout.vue";
import ContractLayout from "@layouts/contract.layout.vue";
import NavButton from "@components/layouts/main/NavButton.vue";
import NavGroup from "@components/layouts/main/NavGroup.vue";
import FileMenu from "@components/menus/FileMenu.vue";
import ContractInfo from "@components/contract/ContractInfo.vue";
import ContractNorm from "@components/contract/ContractNorm.vue";
import ContractFileExplorer from "@components/FileSystem.vue";
import FileTypeDialog from "@components/dialogs/FileTypeDialog.vue";
import ContractTimeline from "@components/contract/ContractTimeline.vue";
import NotificationDialog from "@components/dialogs/NotificationDialog.vue";
import DuedateDialog from "@components/dialogs/DuedateDialog.vue";
import ContractNotesDialog from "@components/dialogs/ContractNotesDialog.vue";
import TextField from "@components/TextField.vue";
import ContractSearchMenu from "@components/menus/ContractSearchMenu.vue";
import EmailMenu from "@components/menus/EmailMenu.vue";
import InspectionDialog from "@components/dialogs/InspectionDialog.vue";
import ArchiveDialog from "@components/dialogs/ArchiveDialog.vue";
import PrintDialog from "@components/dialogs/PrintDialog.vue";
import ContractReleaseDialog from "@components/dialogs/ContractReleaseDialog.vue";
import ContractStatusDialog from "@components/dialogs/ContractStatusDialog.vue";

import contractMixin from "@mixin/contract.mixin";
import dropzoneMixin from "@mixin/dropzone.mixin";
import permissionMixin from "@mixin/permission.mixin";
import formularMixin from "@mixin/formular.mixin";

export default {
    name: "ContractView",
    components: {
        Layout,
        NavButton,
        NavGroup,
        ContractLayout,
        FileMenu,
        ContractInfo,
        ContractNorm,
        ContractFileExplorer,
        FileTypeDialog,
        ContractTimeline,
        NotificationDialog,
        DuedateDialog,
        ContractNotesDialog,
        TextField,
        ContractSearchMenu,
        EmailMenu,
        InspectionDialog,
        ArchiveDialog,
        PrintDialog,
        ContractReleaseDialog,
        ContractStatusDialog,
    },
    mixins: [contractMixin, dropzoneMixin, permissionMixin, formularMixin],
    data() {
        return {
            file_upload: [],
            filesToPrint: [],
            search: undefined,
        };
    },
    computed: {
        ...mapGetters({
            inspectors: "user/inspectors",
        }),
        contract_id() {
            return this.$route.params.id;
        },
        checklist() {
            return {
                image: this.hasFile("Bilder"),
                delivery_note: this.hasFile("Lieferschein") || this.isService,
                legend:
                    (this.legend != undefined &&
                        this.legend.groups &&
                        this.legend.groups.length > 0 &&
                        this.legend.groups[0].items &&
                        this.legend.groups[0].items.length > 0) ||
                    this.isService,
                test_report: this.hasFile("Prüfbericht") || this.isService || this.isMaterial || this.isEmptySystem,
                formular: this.isFormularComplete(this.formular) || this.isService || this.isMaterial || this.isEmptySystem,
                dispo: this.hasFile("Dispo") || this.isService || this.isMaterial,
                schema: this.hasFile("Schema") || this.isService || this.isMaterial || this.isEmptySystem,
                schema_revision: this.hasFile("Schema Revision") || this.isMaterial || this.isEmptySystem,
                rapport: this.rapport != undefined,
                contract_data: this.contract.data?.monteur && this.contract.data?.system_amount,
                socket: this.contract.data?.socket != null,
                bag: this.contract.data?.bag != null,
            };
        },
        isService() {
            if (this.contract?.data?.service) return true;
            return this.contract?.data?.contract_type == "service";
        },
        isMaterial() {
            return this.contract?.data?.contract_type == "material";
        },
        isEmptySystem() {
            return this.contract?.data?.contract_type == "empty_system";
        },
    },
    watch: {
        filesToPrint: {
            immediate: true,
            deep: true,
            handler(files) {
                if (files[0]) {
                    const file = files[0];
                    this.$refs.print_dialog.open(file.template, file.data);
                }
            },
        },
    },
    mounted() {
        this.fetchContract(this.contract_id).then(async () => {
            this.fetchRapport();
            this.fetchLegend();
            this.fetchFiles();
            this.fetchJournal();

            await this.fetchFormular();
            this.fetchCustomerNorms();
            this.fetchEVUNorms();
            this.fetchTemplates();
        });

        this.initDragzone(this.$refs.dropzone.$el, (files) => {
            this.file_upload = files;
        });
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
        }),
        ...mapActions({
            queryContracts: "contract/queryContracts",
        }),
        hasFile(category) {
            const folder = this.files.find((folder) => folder.name == category);
            return folder != undefined && folder.children && folder.children.length > 0;
        },
        printFiles() {
            if (!this.contract || this.contract.status < 2 || !this.formular || !this.contract.data)
                return this.logError("Daten konnten nicht gedruckt werden");

            this.filesToPrint.push({
                template: "pruefkleber",
                data: {
                    contract_id: this.contract.contract_id,
                    contract_name: this.contract.contract_name,
                    inspect_date: this.contract.data.inspect_date,
                    ...this.formular.data,
                    ...this.contract.data,
                },
            });

            if (this.legend) this.filesToPrint.push({ template: "legend", data: { ...this.legend, contract_name: this.contract.contract_name } });
        },
        printNext() {
            setTimeout(() => this.filesToPrint.shift(), 800);
        },
    },
};
</script>

<template>
    <Layout ref="dropzone" :loading="contract == undefined">
        <template #navbar>
            <NavButton icon="mdi-folder" label="Ablage" :active="true" />
        </template>
        <template #navbar.append>
            <ContractSearchMenu>
                <template #activator="{ attrs, on, isActive }">
                    <NavGroup :active="isActive">
                        <TextField
                            v-model="search"
                            icon="mdi-magnify"
                            width="min(100%, 300px)"
                            rounded
                            placeholder="Suche"
                            v-bind="attrs"
                            @submit="
                                queryContracts(search);
                                on();
                            "
                        />
                    </NavGroup>
                </template>
            </ContractSearchMenu>
        </template>
        <template #default>
            <ContractLayout>
                <template #toolbar>
                    <FileMenu
                        :contract="contract"
                        :rapport="rapport"
                        :legend="legend"
                        :formular="formular"
                        :templates="templates"
                        @create:rapport="
                            fetchRapport();
                            fetchJournal();
                        "
                        @create:legend="
                            fetchLegend();
                            fetchJournal();
                        "
                        @create:formular="
                            fetchFormular();
                            fetchJournal();
                        "
                        @update:files="
                            fetchFiles();
                            fetchJournal();
                            fetchTemplates();
                        "
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined :disabled="contract.status == 4" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                        </template>
                    </FileMenu>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="isInGroup('monteur') && contract.status >= 2 && !isService && !isMaterial && !isEmptySystem"
                        icon
                        outlined
                        @click="printFiles()"
                    >
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <InspectionDialog
                        v-if="
                            isInGroup('inspector') &&
                            hasPermission('contract.status.promote') &&
                            contract.status < 3 &&
                            contract.status > 0 &&
                            !isService &&
                            !isMaterial
                        "
                        :contract="contract"
                        :formular="formular"
                        :checklist="checklist"
                        @update:contract="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                        @update:formular="
                            fetchFormular();
                            fetchJournal();
                        "
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined v-bind="attrs" v-on="on"><v-icon>mdi-check-decagram-outline</v-icon></v-btn>
                        </template>
                    </InspectionDialog>
                    <ArchiveDialog
                        v-if="
                            (isInGroup('avor') &&
                                ((hasPermission('contract.status.promote') && contract.status == 3) ||
                                    (hasPermission('contract.status.demote') && contract.status == 4))) ||
                            (isService &&
                                (isInGroup('avor') || isInGroup('installer')) &&
                                contract.status == 1 &&
                                hasPermission('contract.status.promote')) ||
                            (isMaterial && isInGroup('avor') && contract.status == 1 && hasPermission('contract.status.promote'))
                        "
                        :contract="contract"
                        @update:contract="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined v-bind="attrs" v-on="on">
                                <v-icon>{{ contract.status < 4 ? "mdi-archive-outline" : "mdi-archive-sync-outline" }}</v-icon>
                            </v-btn>
                        </template>
                    </ArchiveDialog>
                    <ContractReleaseDialog
                        v-if="isInGroup('avor') && hasPermission('contract.status.promote') && (contract.status == 0 || contract.status == undefined)"
                        :contract="contract"
                        :checklist="checklist"
                        @update:contract="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined v-bind="attrs" v-on="on"><v-icon>mdi-check-decagram-outline</v-icon></v-btn>
                        </template>
                    </ContractReleaseDialog>
                    <EmailMenu
                        v-if="hasPermission('email.send')"
                        :contract="contract"
                        :files="files"
                        :formular="formular"
                        :rapport="rapport"
                        :legend="legend"
                        @update:contract="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined v-bind="attrs" v-on="on"><v-icon>mdi-email-outline</v-icon></v-btn>
                        </template>
                    </EmailMenu>
                    <DuedateDialog
                        v-if="hasPermission('contract.duedate.set') && contract.status != 4"
                        :contract="contract"
                        @update:duedate="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined v-bind="attrs" v-on="on"><v-icon>mdi-calendar-month-outline</v-icon></v-btn>
                        </template>
                    </DuedateDialog>
                    <ContractNotesDialog
                        v-if="!isLocked(contract)"
                        :contract="contract"
                        @update:notes="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined v-bind="attrs" v-on="on"><v-icon>mdi-text-long</v-icon></v-btn>
                        </template>
                    </ContractNotesDialog>
                    <NotificationDialog :contract="contract">
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined v-bind="attrs" v-on="on"><v-icon>mdi-bell-plus-outline</v-icon></v-btn>
                        </template>
                    </NotificationDialog>
                    <ContractStatusDialog
                        v-if="hasSpecificPermission('contract.status.set')"
                        :contract="contract"
                        @update:contract="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn icon outlined color="error" v-bind="attrs" v-on="on"><v-icon>mdi-security</v-icon></v-btn>
                        </template>
                    </ContractStatusDialog>
                    <PrintDialog ref="print_dialog" @close="printNext" />
                </template>
                <template #default>
                    <ContractFileExplorer
                        :contract="contract"
                        :rapport="rapport"
                        :legend="legend"
                        :formular="formular"
                        :files="files"
                        @update:formular="
                            fetchFormular();
                            fetchJournal();
                        "
                        @update:rapport="
                            fetchRapport();
                            fetchJournal();
                        "
                        @update:legend="
                            fetchLegend();
                            fetchJournal();
                        "
                        @update:data="
                            fetchFormular();
                            fetchJournal();
                        "
                        @update:files="
                            fetchFiles();
                            fetchJournal();
                            fetchTemplates();
                        "
                        @update:contract="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                    />
                </template>
                <template #info>
                    <ContractInfo :contract="contract" :formular="formular" @update:contract="fetchContract(contract_id)" />
                </template>
                <template #norms>
                    <ContractNorm :contract="contract" :formular="formular" :customer_norms="customer_norms" :evu_norms="evu_norms" />
                </template>
                <template #checklist>
                    <ContractTimeline
                        :contract="contract"
                        :journal="journal"
                        :checklist="checklist"
                        @update:contract="
                            fetchContract(contract_id);
                            fetchJournal();
                        "
                    />
                </template>
            </ContractLayout>
            <FileTypeDialog
                :value="file_upload"
                :contract="contract"
                @update:files="
                    fetchFiles();
                    fetchJournal();
                "
            />
            <div class="dropzone">Datei hochladen</div>
        </template>
    </Layout>
</template>

<style lang="scss" scoped>
.dropzone {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    transition: opacity 0.2s ease-in-out 0.2s;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 100;
}

.highlight {
    .dropzone {
        opacity: 1;
    }
}
</style>
