<script>
export default {
    name: "FilterLayout",
};
</script>

<template>
    <div class="filter-layout">
        <div class="toolbar"><slot name="toolbar" /></div>
        <div class="content-wrapper">
            <div class="content"><slot /></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.filter-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    .toolbar {
        width: 100%;
        max-width: 1000px;
        max-height: 30vh;
        padding: 1rem;
        padding-bottom: 0px;
        overflow-y: auto;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-auto-flow: dense;
        gap: 1rem;
        row-gap: 0;
        margin: 0 auto;
    }

    .content-wrapper {
        width: 100%;
        max-width: 1000px;
        height: 100%;
        overflow-y: scroll;
        padding: 2px 1px 2px 1rem;
        margin: 0 auto;

        .content {
            width: 100%;
            height: auto;
        }
    }
}

@media screen and (max-width: 900px) {
    .filter-layout {
        .toolbar {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media screen and (max-width: 700px) {
    .filter-layout {
        .toolbar {
            grid-template-columns: 1fr 1fr;
            padding: 1rem 0 1rem 1rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .filter-layout {
        overflow-y: auto;
        .toolbar {
            grid-template-columns: 1fr;
        }
        .content {
            width: calc(100% - 1rem);
            overflow-y: unset;
        }
    }
}
</style>
