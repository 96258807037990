var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('CalendarLayout',{scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-toolbar',[_c('v-btn',{staticClass:"mr-4 hide-mobile",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v("Heute")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1),_c('v-toolbar-title',{staticClass:"ml-4 hide-mobile"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel(_vm.type)))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.types),function(item){return _c('v-list-item',{key:item.key,on:{"click":function($event){_vm.type = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.key))])],1)}),1)],1)],1)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"calendar-main elevation-1"},[_c('v-calendar',{ref:"calendar",staticClass:"calendar",attrs:{"events":_vm.events,"weekdays":[1, 2, 3, 4, 5, 6, 0],"type":_vm.type,"locale":"de-CH","event-overlap-mode":"column","show-week":"","first-time":"05:00"},on:{"change":function($event){_vm.title = _vm.$refs.calendar.title}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"event-item",class:_vm.type,on:{"click":function($event){return _vm.$router.push('/contract/' + event.contract)}}},'div',attrs,false),on),[(event.time)?_c('b',[_vm._v(_vm._s(event.time))]):_vm._e(),_c('div',[_vm._v(_vm._s(event.name))]),(event.icon)?_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(_vm._s(event.icon))]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(event.name))])])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }