export interface FilterState {
    contracts: Array<Contract>;
    filter: Array<Filter>;
}

export interface Contract {
    contract_id: string;
    contract_name: string;
    manufacturer_sign: string;
    customer_sign: string;
    pronoun: string;
    first_name: string;
    last_name: string;
    display_name: string;
    phone: string;
    email: string;
    customer_id: string;
    company: string;
    street: string;
    city: string;
    plz: string;
    country: string;
}

export interface Filter {
    name: string;
    multiple: boolean;
    text: boolean;
    textQuery?: (value: string) => Array<any>;
    items: Array<FilterQuery>;
}

export interface FilterQuery {
    name: string;
    query: any;
}

export default {
    namespaced: true,
    state: (): FilterState => ({
        contracts: [],
        filter: [
            {
                name: "Auftragstyp",
                multiple: true,
                text: false,
                items: [
                    { name: "Normal", query: { contract: { $or: [{ "data.contract_type": "normal" }, { "data.contract_type": null }] } } },
                    { name: "Service", query: { contract: { $or: [{ "data.service": true }, { "data.contract_type": "service" }] } } },
                    { name: "Materialbestellung", query: { contract: { "data.contract_type": "material" } } },
                    { name: "Leeranlage", query: { contract: { "data.contract_type": "empty_system" } } },
                ],
            },
            {
                name: "Auftragsstatus",
                multiple: true,
                text: false,
                items: [
                    { name: "AVOR", query: { contract: { $or: [{ status: 0 }, { status: null }] } } },
                    { name: "In Arbeit", query: { contract: { status: 1 } } },
                    { name: "Geprüft", query: { contract: { status: 2 } } },
                    { name: "Transportbereit", query: { contract: { status: 3 } } },
                    { name: "Geschlossen", query: { contract: { status: 4 } } },
                ],
            },
            {
                name: "Schnittstellenprotokoll",
                multiple: true,
                text: false,
                items: [
                    {
                        name: "Nicht komplett",
                        query: {
                            formular: {
                                $or: [
                                    { data: null },
                                    { "data.access": null },
                                    { "data.system_design": null },
                                    { "data.rated_voltage": null },
                                    { "data.rated_current": null },
                                    { "data.main_fuse_location": null },
                                    {
                                        $and: [
                                            { "data.ipk": null },
                                            { "data.icp": null },
                                            { "data.icc": null },
                                            {
                                                $or: [{ "data.icw": null }, { "data.icw_time": null }, { "data.ipk": null }],
                                            },
                                        ],
                                    },
                                    { "data.main_fuse_type": null },
                                    { "data.main_fuse_size": null },
                                    { "data.fuse_amount": null },
                                    { "data.mounting_location": null },
                                    { $and: [{ "data.low_voltage_grid": null }, { "data.system_design": { $ne: "Kleinverteiler" } }, { "data.system_design": { $ne: "geschlossene Bauform (Kleinverteiler)" } }] },
                                    { $and: [{ "data.copper_system": null }, { "data.access": { $ne: "Laien" } }] },
                                    { $and: [{ "data.protection": null }, { "data.access": { $ne: "Laien" } }] },
                                    { $and: [{ "data.ip_protection": null }, { "data.system_design": { $ne: "Geschlossene Bauform" } }] },
                                    { "data.fuse_change": null },
                                    { "data.width": null },
                                    { "data.height": null },
                                    { "data.depth": null },
                                    { "data.transportable": null },
                                    { "data.assembly": null },
                                    { "data.power_station": null },
                                ],
                            },
                        },
                    },
                    { name: "Keine Kundenrückmeldung", query: { formular: { $or: [{ response: false }, { response: null }] } } },
                    {
                        name: "Komplett ausgefüllt",
                        query: {
                            formular: {
                                $and: [
                                    { data: { $ne: null } },
                                    { "data.access": { $ne: null } },
                                    { "data.system_design": { $ne: null } },
                                    { "data.rated_voltage": { $ne: null } },
                                    { "data.rated_current": { $ne: null } },
                                    { "data.main_fuse_location": { $ne: null } },
                                    {
                                        $or: [
                                            { "data.ipk": { $ne: null } },
                                            { "data.icp": { $ne: null } },
                                            { "data.icc": { $ne: null } },
                                            {
                                                $and: [
                                                    { "data.icw": { $ne: null } },
                                                    { "data.icw_time": { $ne: null } },
                                                    { "data.ipk": { $ne: null } },
                                                ],
                                            },
                                        ],
                                    },
                                    { "data.main_fuse_type": { $ne: null } },
                                    { "data.main_fuse_size": { $ne: null } },
                                    { "data.fuse_amount": { $ne: null } },
                                    { "data.mounting_location": { $ne: null } },
                                    { $or: [{ "data.low_voltage_grid": { $ne: null } }, { "data.system_design": "Kleinverteiler" }, { "data.system_design": "geschlossene Bauform (Kleinverteiler)" }] },
                                    { $or: [{ "data.copper_system": { $ne: null } }, { "data.access": "Laien" }] },
                                    { $or: [{ "data.protection": { $ne: null } }, { "data.access": "Laien" }] },
                                    { $or: [{ "data.ip_protection": { $ne: null } }, { "data.system_design": "Geschlossene Bauform" }] },
                                    { "data.fuse_change": { $ne: null } },
                                    { "data.width": { $ne: null } },
                                    { "data.height": { $ne: null } },
                                    { "data.depth": { $ne: null } },
                                    { "data.transportable": { $ne: null } },
                                    { "data.assembly": { $ne: null } },
                                    { "data.power_station": { $ne: null } },
                                ],
                            },
                        },
                    },
                ],
            },
            {
                name: "Auftragsinformationen",
                multiple: true,
                text: false,
                items: [
                    { name: "Kunde kontaktiert", query: { contract: { "data.customer_contacted": true } } },
                    {
                        name: "Kunde nicht kontaktiert",
                        query: { contract: { $or: [{ "data.customer_contacted": false }, { "data.customer_contacted": null }] } },
                    },
                    { name: "Abholung durch Kunde", query: { contract: { "data.pickup": true } } },
                    { name: "Lieferung zum Kunde", query: { contract: { "data.transport": true } } },
                ],
            },
            {
                name: "Verantwortlich",
                multiple: false,
                text: false,
                items: [
                    { name: "Kein Verantwortlicher", query: [] },
                    {
                        name: "Christian Niederberger",
                        query: [{ base: "Christian Niederberger" }],
                    },
                    { name: "Harald Lusser", query: [{ base: "Harald Lusser" }] },
                    { name: "Alain Brunner", query: [{ base: "Alain Brunner" }] },
                    { name: "Daniel Fallegger", query: [{ base: "Dani Fallegger" }] },
                ],
            },
            {
                name: "Monteur",
                multiple: false,
                text: true,
                textQuery: (value) => [{ contract: { "data.monteur": { $regex: value } } }],
                items: [],
            },
            {
                name: "Material",
                multiple: true,
                text: false,
                items: [
                    {
                        name: "Material komplett",
                        query: {
                            contract: { $and: [{ "data.material": { $nin: [null, []] } }, { "data.material.completed": { $nin: [false, null] } }] },
                        },
                    },
                    {
                        name: "Material ausstehend",
                        query: {
                            contract: { $and: [{ "data.material": { $nin: [null, []] } }, { "data.material.completed": { $in: [false, null] } }] },
                        },
                    },
                ],
            },
        ],
    }),
    mutations: {
        setContracts(state, value) {
            state.contracts = value;
        },
        setFilter(state, value) {
            state.filter = value;
        },
    },
};
