export interface UserState {
    users: Array<User>;
}

export interface User {
    id: string;
    username: string;
    data: UserData;
}

export interface UserData {
    short: string;
    long: string;
    role: Array<"avor" | "monteur" | "inspector" | "carrier" | "installer">;
    sign: string;
}

export interface Inspector {
    id: string;
    username: string;
}

export default {
    namespaced: true,
    state: (): UserState => ({
        users: [],
    }),
    getters: {
        avor(state: UserState) {
            return state.users.filter((user) => user.data.role.includes("avor"));
        },
        inspectors(state: UserState) {
            return state.users.filter((user) => user.data.role.includes("inspector"));
        },
        carriers(state: UserState) {
            return state.users.filter((user) => user.data.role.includes("carrier"));
        },
        installers(state: UserState) {
            return state.users.filter((user) => user.data.role.includes("installer"));
        },
    },
    mutations: {
        setUsers(state, value) {
            state.users = value;
        },
    },
    actions: {
        fetchUsers({ commit }) {
            this._vm.axios
                .get("/user")
                .then((res) => commit("setUsers", res.data))
                .catch((err) => {
                    if (!err.response || !err.response.status) return commit("ui/error", "Unbekannter Fehler", { root: true });
                    if (err.response.status == 400) return commit("ui/error", "Ungültiges Format", { root: true });
                    if (err.response.status == 500) return commit("ui/error", "Server Fehler", { root: true });
                });
        },
    },
};
