<script>
import DialogLayout from "@layouts/dialog.layout.vue";
import DatePicker from "@components/DatePicker.vue";
import TimePicker from "@components/TimePicker.vue";

import permissionMixin from "@mixin/permission.mixin.ts";

export default {
    name: "MaterialTableComponent",
    components: {
        DialogLayout,
        DatePicker,
        TimePicker,
    },
    mixins: [permissionMixin],
    props: {
        value: { type: Array, default: () => [] },
        contract: { type: Object, default: undefined },
    },
    data: () => ({
        dialog: false,
        dialogDelete: false,
        dialogInbox: false,
        data: [],
        editedIndex: -1,
        editedItem: {
            type: true,
            description: null,
            amount: 1,
            date: null,
            time: null,
            address_alt: false,
            address: null,
            id: null,
        },
        inbox: 0,
        inboxIndex: -1,
        headers: [
            { text: "Beschreibung", value: "description" },
            { text: "Anzahl", value: "amount", align: "center" },
            { text: "Geliefert", value: "delivered", align: "center" },
            { text: "Zeit", value: "timestamp", align: "center" },
            { text: "Ort", value: "address", align: "start" },
            { text: "Art", value: "type", align: "center" },
            { text: "Aktionen", value: "actions", align: "end" },
        ],
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                this.data = value;
            },
        },
        contract: {
            immediate: true,
            deep: true,
            handler(value) {
                if (!this.editedItem.address) {
                    this.editedItem.address = `${value.company || ""}\n${value.street || ""}\n${value.plz ? value.plz + " " : ""}${value.city || ""}`;
                }
            },
        },
        editedItem: {
            deep: true,
            handler(value) {
                if (value.type == "Anlieferung") {
                    this.editedItem.date = null;
                    this.editedItem.time = null;
                }
            },
        },
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.data.splice(this.editedIndex, 1);
            this.$emit("input", this.data);
            this.$emit("update");
            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem.description = null;
                this.editedItem.amount = 1;
                this.editedItem.address_alt = false;
                this.editedItem.address = `${this.contract.company || ""}\n${this.contract.street || ""}\n${
                    this.contract.plz ? this.contact.plz + " " : ""
                }${this.contract.city || ""}`;
                this.editedItem.id = null;
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem.description = null;
                this.editedItem.amount = 1;
                this.editedItem.address_alt = false;
                this.editedItem.address = `${this.contract.company || ""}\n${this.contract.street || ""}\n${
                    this.contract.plz ? this.contact.plz + " " : ""
                }${this.contract.city || ""}`;
                this.editedItem.id = null;
                this.editedIndex = -1;
            });
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.data[this.editedIndex], this.editedItem);
            } else {
                this.data.push(Object.assign({}, this.editedItem));
            }
            this.$emit("input", this.data);
            this.$emit("update");
            this.close();
        },
        closeInbox() {
            this.dialogInbox = false;
            this.$nextTick(() => {
                this.inbox = 0;
                this.inboxIndex = -1;
            });
        },
        saveInbox() {
            if (this.inboxIndex < 0) return;
            this.dialogInbox = false;
            if (this.data[this.inboxIndex].delivered == undefined) this.data[this.inboxIndex].delivered = 0;
            this.data[this.inboxIndex].delivered += parseInt(this.inbox);
            this.$emit("input", this.data);
            this.$emit("update");
            this.$nextTick(() => {
                this.inbox = 0;
                this.inboxIndex = -1;
            });
        },
        openInbox(index) {
            this.inboxIndex = index;
            this.inbox = this.data[index].amount - (this.data[index].delivered || 0);
            this.dialogInbox = true;
        },
    },
};
</script>

<template>
    <v-data-table :headers="headers" :items="data" hide-default-footer class="elevation-1">
        <template #footer>
            <DialogLayout v-model="dialog" width="500" title="Material bearbeiten">
                <template #activator="{ on, attrs }">
                    <v-row class="px-5 pt-5">
                        <v-spacer></v-spacer>
                        <v-btn v-if="hasPermission('material.add')" text class="mb-2" v-bind="attrs" v-on="on">Material hinzufügen</v-btn>
                    </v-row>
                </template>
                <template #default>
                    <v-select v-model="editedItem.type" :items="['Anlieferung', 'Abholung', 'Lieferung']" label="Art" />
                    <v-row dense>
                        <v-col cols="8">
                            <v-text-field v-model="editedItem.description" :disabled="editedItem.id != undefined" label="Beschreibung"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="editedItem.amount"
                                :disabled="editedItem.id != undefined"
                                label="Anzahl"
                                type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col><DatePicker v-model="editedItem.date" :disabled="editedItem.type == 'Anlieferung'" label="Abholdatum" /></v-col>
                        <v-col><TimePicker v-model="editedItem.time" :disabled="editedItem.type == 'Anlieferung'" label="Abholzeit" /></v-col>
                    </v-row>
                    <v-checkbox v-model="editedItem.address_alt" label="Abweichende Adresse" />
                    <v-textarea v-model="editedItem.address" :disabled="!editedItem.address_alt" label="Adresse" rows="3"></v-textarea>
                </template>
                <template #action>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="close">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="save">Speichern</v-btn>
                </template>
            </DialogLayout>
            <DialogLayout v-model="dialogDelete" width="300" title="Material löschen">
                <template #default>Material wirklich löschen?</template>
                <template #action>
                    <v-btn text @click="closeDelete">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="deleteItemConfirm">Löschen</v-btn>
                </template>
            </DialogLayout>
            <DialogLayout v-model="dialogInbox" width="400" title="Wareneingang">
                <template #default>
                    <v-text-field v-model="inbox" type="number" label="Menge"></v-text-field>
                </template>
                <template #action>
                    <v-btn text @click="closeInbox">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="saveInbox">Speichern</v-btn>
                </template>
            </DialogLayout>
        </template>
        <template #item.description="{ item }">
            {{ item.details || item.description }}
        </template>
        <template #item.delivered="{ item }">
            <div :style="{ color: (item.delivered || 0) < item.amount ? '#ff5252' : '#4caf50', 'font-weight': 'bold' }">
                {{ item.delivered || 0 }}
            </div>
        </template>
        <template #item.timestamp="{ item }">
            <template v-if="item.date">{{ new Date(item.date) | moment("DD.MM.YYYY") }}</template> {{ item.time }}
        </template>
        <template #item.address="{ item }">{{ item.address.split("\n")[0] }}</template>
        <template #item.type="{ item }">
            <v-chip v-if="item.type == 'Anlieferung'" small color="success">Anlieferung</v-chip>
            <v-chip v-else-if="item.type == 'Abholung'" small color="error">Abholung</v-chip>
            <v-chip v-else-if="item.type == 'Lieferung'" small color="info">Lieferung</v-chip>
            <v-chip v-else>Unbekannt</v-chip>
        </template>
        <template #item.actions="{ item, index }">
            <template v-if="item.id">
                <v-btn v-if="hasPermission('material.edit')" small icon @click="editItem(item)">
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="hasPermission('material.invoice')" small icon @click="openInbox(index)">
                    <v-icon small>mdi-inbox-arrow-down</v-icon>
                </v-btn>
            </template>
            <template v-else>
                <v-btn v-if="hasPermission('material.remove')" small icon @click="deleteItem(item)">
                    <v-icon small>mdi-delete</v-icon>
                </v-btn>
                <v-btn v-if="hasPermission('material.edit')" small icon @click="editItem(item)">
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="hasPermission('material.invoice')" small icon @click="openInbox(index)">
                    <v-icon small>mdi-inbox-arrow-down</v-icon>
                </v-btn>
            </template>
        </template>
        <template #no-data>Kein Material erfasst</template>
    </v-data-table>
</template>
