import { mapState } from "vuex";
import { State } from "@store/";

export default {
    computed: {
        ...mapState({
            account: (state: State) => state.auth.account,
        }),
    },
    methods: {
        hasPermission(permission) {
            if (!this.account || !this.account.permissions) return false;
            return (
                this.account.permissions.find((perm) => new RegExp("^" + perm.replaceAll(".", "\\.").replaceAll("*", ".+") + "$").test(permission)) !=
                undefined
            );
        },
        hasSpecificPermission(permission) {
            if (!this.account || !this.account.permissions) return false;
            return this.account.permissions.find((perm) => perm == permission) != undefined;
        },
        matchPermission(permission) {
            if (!this.account || !this.account.permissions) return false;
            return (
                this.account.permissions.find((perm) => new RegExp("^" + permission.replaceAll(".", "\\.").replaceAll("*", ".+") + "$").test(perm)) !=
                undefined
            );
        },
        isInGroup(group) {
            if (!this.account || !this.account.data) return false;
            return this.account.data.role?.includes(group) || false;
        },
        isLocked(contract) {
            if ((this.isInGroup("avor") && contract.status == 4) || (!this.isInGroup("avor") && contract.status >= 3)) return true;
            return false;
        },
    },
};
