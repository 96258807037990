<script>
import JournalDialog from "@components/dialogs/JournalDialog.vue";
import ExpansionPanel from "@components/ExpansionPanel.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "ContractTimelineComponent",
    components: {
        JournalDialog,
        ExpansionPanel,
    },
    mixins: [permissionMixin],
    props: {
        contract: { type: Object, required: true },
        journal: { type: Array, default: () => [] },
        checklist: { type: Object, default: () => {} },
    },
    computed: {
        icons() {
            return {
                date_modify: "mdi-calendar-edit",
                notes_modify: "mdi-playlist-edit",
                contract_modify: "mdi-briefcase-edit",
                customer_reply: "mdi-reply",
                file_delete: "mdi-delete",
                file_upload: "mdi-upload",
                file_import: "mdi-import",
                formular_edit: "mdi-pencil",
                formular_create: "mdi-plus",
                formular_delete: "mdi-delete",
                formular_import: "mdi-import",
                legend_edit: "mdi-pencil",
                legend_create: "mdi-plus",
                legend_delete: "mdi-delete",
                legend_import: "mdi-import",
                status_update: "mdi-chevron-triple-up",
                status_reset: "mdi-chevron-triple-down",
                rapport_edit: "mdi-pencil",
                rapport_create: "mdi-plus",
                rapport_delete: "mdi-delete",
            };
        },
        colors() {
            return {
                date_modify: "primary",
                notes_modify: "primary",
                contract_modify: "primary",
                customer_reply: "warning",
                file_delete: "error",
                file_upload: "success",
                file_import: "success",
                formular_edit: "primary",
                formular_create: "success",
                formular_delete: "error",
                formular_import: "primary",
                legend_edit: "primary",
                legend_create: "success",
                legend_delete: "error",
                legend_import: "primary",
                status_update: "accent",
                status_reset: "error",
                rapport_edit: "primary",
                rapport_create: "success",
                rapport_delete: "error",
            };
        },
        computedJournal() {
            const journal = this.journal.reduce((value, item, index, array) => {
                if (index == 0) value.push(item);
                else {
                    const previous = array[index - 1];
                    if (previous.category == item.category) {
                        const group = value[value.length - 1];
                        if (Array.isArray(group.children)) group.children.push(item);
                        else group.children = [item];
                    } else value.push(item);
                }
                return value;
            }, []);

            // Avor
            if (this.isInGroup("avor")) {
                this.insertMemory(journal, { title: "Dispo hochladen", checklist: "dispo" });
                this.insertMemory(journal, { title: "Schema hochladen", checklist: "schema" });
                this.insertMemory(journal, { title: "Schnittstellen Informationen", checklist: "formular" });

                this.insertMemory(journal, { title: "Schema Revision hochgeladen", checklist: "schema_revision" });
                this.insertMemory(journal, { title: "Lieferschein hochgeladen", checklist: "delivery_note" });
            }
            // Monteur
            if (this.isInGroup("monteur")) {
                this.insertMemory(journal, { title: "Anlagefotos hochgeladen", checklist: "image" });
                this.insertMemory(journal, { title: "Legende erstellt", checklist: "legend" });
                this.insertMemory(journal, { title: "Messprotokoll hochgeladen", checklist: "test_report" });
                this.insertMemory(journal, { title: "Auftragsinformationen", checklist: "contract_data" });
            }
            // Prüfer
            if (this.isInGroup("inspector")) {
                this.insertMemory(journal, { title: "Schnittstellen Informationen", checklist: "formular" });
                this.insertMemory(journal, { title: "Anlagefotos hochgeladen", checklist: "image" });
                this.insertMemory(journal, { title: "Legende erstellt", checklist: "legend" });
                this.insertMemory(journal, { title: "Messprotokoll hochgeladen", checklist: "test_report" });
            }
            // Installateur
            if (this.isInGroup("installer")) {
                this.insertMemory(journal, { title: "Anlagefotos hochgeladen", checklist: "image" });
            }

            if (this.contract.data?.service || this.contract.data?.contract_type == "service") {
                this.insertMemory(journal, { title: "Montagerapport erstellt", checklist: "rapport" });
            }

            return journal;
        },
    },
    methods: {
        badgeContent(entry) {
            return (entry.children?.length || 0) + 1;
        },
        insertMemory(array, item) {
            if (array.find((_item) => _item.checklist == item.checklist) == undefined) array.push({ ...item, disabled: true });
        },
    },
};
</script>

<template>
    <div class="main">
        <div class="timeline">
            <v-timeline dense clipped>
                <!-- Contract Created entry -->
                <v-timeline-item fill-dot small color="accent" icon="mdi-briefcase-plus">
                    <JournalDialog
                        :journal="{
                            origin: contract.manufacturer_sign,
                            title: 'Auftrag erstellt',
                            description: 'Der Auftrag wurde eröffnet',
                            timestamp: new Date(contract.created),
                        }"
                    >
                        <template #activator="{ attrs, on }">
                            <div style="user-select: none" v-bind="attrs" v-on="on">Auftrag erstellt</div>
                        </template>
                    </JournalDialog>
                </v-timeline-item>

                <template v-for="(entry, index) in computedJournal">
                    <!-- Disabled Items -->
                    <template v-if="entry.disabled">
                        <v-timeline-item v-if="!checklist[entry.checklist]" :key="index" fill-dot small color="#ccc" icon="mdi-clock-outline">
                            <div class="entry">{{ entry.title }}</div>
                        </v-timeline-item>
                    </template>

                    <!-- Group Items -->
                    <template v-else-if="Array.isArray(entry.children)">
                        <ExpansionPanel :key="index">
                            <template #header="{ expand }">
                                <v-timeline-item fill-dot small :color="colors[entry.category]">
                                    <template #icon>
                                        <v-badge :value="!expand" :content="badgeContent(entry)" :color="colors[entry.category]" bordered small>
                                            <v-icon small dark>{{ icons[entry.category] }}</v-icon>
                                        </v-badge>
                                    </template>
                                    <JournalDialog :journal="entry">
                                        <template #activator="{ attrs, on }">
                                            <div v-if="expand" class="entry" v-bind="attrs" v-on="on">{{ entry.title }}</div>
                                            <div v-else class="entry">{{ entry.title }}</div>
                                        </template>
                                    </JournalDialog>
                                </v-timeline-item>
                            </template>
                            <v-timeline-item v-for="(children, chindex) in entry.children" :key="'ch' + chindex" hide-dot>
                                <JournalDialog :journal="children">
                                    <template #activator="{ attrs, on }">
                                        <div class="entry" v-bind="attrs" v-on="on">{{ children.title }}</div>
                                    </template>
                                </JournalDialog>
                            </v-timeline-item>
                        </ExpansionPanel>
                    </template>

                    <!-- Single Items -->
                    <template v-else>
                        <v-timeline-item :key="index" fill-dot small :color="colors[entry.category]" :icon="icons[entry.category]">
                            <JournalDialog :journal="entry">
                                <template #activator="{ attrs, on }">
                                    <div class="entry" v-bind="attrs" v-on="on">{{ entry.title }}</div>
                                </template>
                            </JournalDialog>
                        </v-timeline-item>
                    </template>
                </template>
            </v-timeline>
        </div>
        <div class="action">
            <div v-if="contract.status == 0 || contract.status == undefined" class="action-button grey" style="pointer-events: none">
                Auftrag in Vorbereitung
            </div>
            <div v-else-if="contract.status == 1" class="action-button info" style="pointer-events: none">Auftrag in Arbeit</div>
            <div v-else-if="contract.status == 2" class="action-button accent" style="pointer-events: none">Auftrag geprüft</div>
            <div v-else-if="contract.status == 3" class="action-button success" style="pointer-events: none">Auftrag transportbereit</div>
            <div v-else-if="contract.status == 4" class="action-button error" style="pointer-events: none">Auftrag abgeschlossen</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.main {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 4rem;

    .timeline {
        overflow: hidden;
        overflow-y: auto;

        * {
            user-select: none;
        }

        .entry {
            cursor: pointer;
        }
    }

    .action {
        display: flex;

        .action-button {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 18px;
            user-select: none;
            cursor: pointer;

            &:hover {
                background: rgba(40, 40, 40, 0.2);
            }
        }

        .action-button.disabled {
            opacity: 0.5;
            pointer-events: none;
            background: rgba(220, 220, 220, 0.8);
        }
    }
}
</style>
