import Vue from "vue";
import Vuex from "vuex";

import ui_store, { UiState } from "./modules/ui.store";
import auth_store, { AuthState } from "./modules/auth.store";
import user_store, { UserState } from "./modules/user.store";
import contract_store, { ContractState } from "./modules/contract.store";
import notification_store, { NotificationState } from "./modules/notification.store";
import norm_store, { NormState } from "./modules/norm.store";
import filter_store, { FilterState } from "./modules/filter.store";

Vue.use(Vuex);

export interface State {
    ui: UiState;
    auth: AuthState;
    user: UserState;
    contract: ContractState;
    notification: NotificationState;
    norm: NormState;
    filter: FilterState;
}

export default new Vuex.Store<State>({
    modules: {
        ui: ui_store,
        auth: auth_store,
        user: user_store,
        contract: contract_store,
        notification: notification_store,
        norm: norm_store,
        filter: filter_store,
    },
});
