<script>
export default {
    name: "FileUploadComponent",
    props: {
        path: { type: String, required: true },
        type: { type: String, default: "data" },
        accept: { type: String, default: "*" },
        multiple: { type: Boolean, default: undefined },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                click: () => this.$refs.fileupload.click(),
            };
        },
    },
    methods: {
        change() {
            const formData = new FormData(this.$refs.fileform);
            this.axios
                .post(this.path, formData, { headers: { "Content-Type": "multipart/form-data" } })
                .then(() => this.$emit("upload"))
                .catch((err) => console.error(err.response));
        },
    },
};
</script>

<template>
    <form ref="fileform" :action="path" method="POST" enctype="multipart/form-data" charset="utf-8" accept-charset="utf-8">
        <input
            ref="fileupload"
            type="file"
            :name="encodeURI(type)"
            :accept="accept"
            :multiple="multiple != undefined && multiple != false"
            @input="change"
        />
        <slot name="activator" :on="listeners" :attrs="$attrs" />
    </form>
</template>

<style lang="scss" scoped>
form {
    input {
        display: none;
    }
}
</style>
