<script>
export default {
    name: "ContractEmptyStateComponent",
};
</script>

<template>
    <div>Kein Passendes Ergebnis gefunden</div>
</template>

<style lang="scss" scoped></style>
