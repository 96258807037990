export interface ContractState {
    contracts: Array<Contract>;
    history: Array<Contract>;
    query: string;
    loadMore: boolean;
    loading: boolean;
}

export interface Contract {
    contract_id: string;
    contract_name: string;
    manufacturer_sign: string;
    customer_sign: string;
    pronoun: string;
    first_name: string;
    last_name: string;
    display_name: string;
    phone: string;
    email: string;
    customer_id: string;
    company: string;
    street: string;
    city: string;
    plz: string;
    country: string;
}

export interface FilterOptions {
    contract_type: string;
    contract_status: number;
    formular: string;
    contract_data: string;
}

export default {
    namespaced: true,
    state: (): ContractState => ({
        contracts: [],
        history: [],
        query: undefined,
        loadMore: false,
        loading: false,
    }),
    mutations: {
        setContracts(state, value) {
            state.contracts = value;
        },
        addContracts(state, value) {
            state.contracts = state.contracts.concat(value);
        },
        setHistory(state, value) {
            state.history = value;
        },
        addHistory(state, value) {
            state.history = state.history.concat(value);
        },
        updateQuery(state, value) {
            state.query = value;
        },
        setFetchState(state, value) {
            state.loadMore = value;
        },
        setLoading(state, value) {
            state.loading = value;
        },
    },
    actions: {
        queryContracts({ state, commit }, query) {
            if (state.query != query) commit("setContracts", []);
            commit("updateQuery", query);
            commit("setLoading", true);

            this._vm.axios
                .get(`/contract/?${query ? `query=${query}&` : ""}offset=${state.contracts.length}`)
                .then((res) => {
                    if (res.data.length < 10) commit("setFetchState", false);
                    else commit("setFetchState", true);
                    commit("addContracts", res.data);
                })
                .catch((err) => {
                    commit("setContracts", []);
                    if (!err.response || !err.response.status) commit("ui/error", "Unbekannter Fehler", { root: true });
                    if (err.response.status == 400) commit("ui/error", "Ungültiges Format", { root: true });
                    if (err.response.status == 500) commit("ui/error", "Server Fehler", { root: true });
                })
                .finally(() => commit("setLoading", false));
        },
        queryHistory({ commit }) {
            this._vm.axios
                .get("/contract?offset=0&limit=20")
                .then((res) => commit("setHistory", res.data))
                .catch((err) => {
                    commit("setHistory", []);
                    if (!err.response || !err.response.status) return commit("ui/error", "Unbekannter Fehler", { root: true });
                    if (err.response.status == 400) return commit("ui/error", "Ungültiges Format", { root: true });
                    if (err.response.status == 500) return commit("ui/error", "Server Fehler", { root: true });
                });
        },
        touchContract({ commit, dispatch }, contract_id) {
            this._vm.axios
                .post("/contract/" + contract_id + "/touch")
                .then(() => dispatch("queryHistory"))
                .catch((err) => {
                    if (!err.response || !err.response.status) return commit("ui/error", "Unbekannter Fehler", { root: true });
                    if (err.response.status == 400) return commit("ui/error", "Ungültiger Auftrag", { root: true });
                    if (err.response.status == 404) return commit("ui/error", "Auftrag wurde nicht gefunden", { root: true });
                    if (err.response.status == 500) return commit("ui/error", "Server Fehler", { root: true });
                });
        },
    },
    modules: {},
};
