<script>
import { mapState, mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "PasswordDialogComponent",
    components: {
        DialogLayout,
    },
    data() {
        return {
            form: false,
            oldPassword: undefined,
            newPassword: undefined,
            newPasswordRepeat: undefined,
            passwordRule: [(v) => !!v || "Eingabe erforderlich"],
            passwordRepeatRule: [(v) => !!v || "Eingabe erfolderlich", (v) => v == this.newPassword || "Passwort stimmt nicht überein"],
        };
    },
    computed: {
        ...mapState({
            account: (state) => state.auth.account,
        }),
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        close(action) {
            action();
            (this.oldPassword = undefined), (this.newPassword = undefined), (this.newPasswordRepeat = undefined), this.$emit("close");
        },
        send(action) {
            this.axios
                .put("/account", {
                    old_password: this.oldPassword,
                    new_password: this.newPassword,
                })
                .then((res) => {
                    if (res.status == 200) {
                        this.logMessage("Das Passwort wurde geändert");
                        this.close(action);
                    }
                })
                .catch((error) => {
                    if (error.status == 403) this.logError("Das alte Passwort ist ungültig");
                    else this.logError("Das Passwort konnte nicht geändert werden");
                });
        },
    },
};
</script>

<template>
    <DialogLayout title="Passwort ändern" width="500">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-form v-model="form">
                <v-text-field v-model="oldPassword" label="Altes Passwort" type="password" :rules="passwordRule" />
                <v-text-field v-model="newPassword" label="Neues Passwort" type="password" :rules="passwordRule" />
                <v-text-field v-model="newPasswordRepeat" label="Neues Passwort wiederholen" type="password" :rules="passwordRepeatRule" />
            </v-form>
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="!form" @click="send(action)">Ändern</v-btn>
        </template>
    </DialogLayout>
</template>
