<script>
import { mapGetters, mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "ContractResponsibleDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        contract: { type: Object, required: true },
    },
    data() {
        return {
            responsible: null,
        };
    },
    computed: {
        ...mapGetters({
            avor: "user/avor",
        }),
    },
    watch: {
        contract: {
            immediate: true,
            handler(contract) {
                if (contract) this.notes = contract.notes || "";
            },
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        close(action) {
            action();
            this.$emit("close");
        },
        save(action) {
            this.axios
                .post("/contract/" + this.contract.contract_id + "/data", { manufacturer_sign: this.responsible })
                .then(() => {
                    this.logMessage("Verantwortlicher wurden gespeichert");
                    this.$emit("update:responsible");
                    this.close(action);
                })
                .catch(() => this.logError("Es ist ein fehler aufgetreten"));
        },
    },
};
</script>

<template>
    <DialogLayout title="Verantwortlicher bearbeiten" width="400" v-bind="$attrs">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-select v-model="responsible" :items="avor" item-text="data.long" item-value="data.long" />
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="save(action)">Speichern</v-btn>
        </template>
    </DialogLayout>
</template>
