var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.account)?_c('v-menu',{attrs:{"tile":"","offset-y":"","min-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs,"isActive":_vm.state})]}}],null,true),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-list',[(_vm.account)?_c('v-list-item',{attrs:{"disabled":""}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.account.username))])],1)],1):_vm._e(),_c('AboutDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Über")])],1)],1)]}}],null,false,1039119402)}),_c('PasswordDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-key-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Passwort ändern")])],1)],1)]}}],null,false,1041051072)}),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-power")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Abmelden")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }