<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";
import ConfirmDialog from "@components/dialogs/ConfirmDialog.vue";

export default {
    name: "LegendDialogComponent",
    components: {
        DialogLayout,
        ConfirmDialog,
    },
    props: {
        contract: { type: Object, required: true },
        legend: { type: Object, default: undefined },
    },
    data() {
        return {
            dialog: false,
            data: {},
            text_presets: ["FI zu LS - Gruppen 20F2 - 20F6"],
        };
    },
    watch: {
        legend: {
            immediate: true,
            deep: true,
            handler(value) {
                this.data = value || {};
            },
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        open() {
            this.dialog = true;
        },
        close(action) {
            action();
            this.$emit("close");
        },
        save(action) {
            document.activeElement.blur();
            setTimeout(
                () =>
                    this.axios
                        .post("/contract/" + this.contract.contract_id + "/legend", this.data)
                        .then(() => this.logMessage("Legende wurde gespeichert"))
                        .then(() => this.$emit("update:legend"))
                        .then(() => this.close(action))
                        .catch(() => this.logError("Fehler beim speichern der Legende")),
                10
            );
        },
        addGroup() {
            this.data.groups.push({ title: "", items: [{}] });
        },
        removeGroup(index) {
            this.data.groups.splice(index, 1);
        },
        newLine(group, index, event) {
            if (index == group.items.length - 1 && !event.shiftKey) group.items.push({});
        },
        insertLine(group, index) {
            group.items.splice(index + 1, 0, {});
        },
        removeLine(group, index) {
            group.items.splice(index, 1);
        },
        moveTo(currentIndex, index) {
            if (index < 0) index = 0;
            if (index >= this.data.groups.length) index = this.data.groups.length - 1;
            const item = { ...this.data.groups[currentIndex] };
            this.data.groups.splice(currentIndex, 1);
            this.data.groups.splice(index, 0, item);
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" fullscreen>
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #content="{ action }">
            <div class="legend-main">
                <div class="legend-toolbar">
                    <div class="toolbar-title">Legende</div>
                    <v-spacer></v-spacer>
                    <ConfirmDialog title="Änderungen verwerfen?" text="Alle aktuellen Änderungen verwerfen?" @resolve="close(action)">
                        <template #activator="{ attrs, on }">
                            <div v-ripple class="toolbar-button" v-bind="attrs" v-on="on"><v-icon dark>mdi-close</v-icon></div>
                        </template>
                    </ConfirmDialog>
                    <div v-ripple class="toolbar-button" @click="save(action)"><v-icon dark>mdi-content-save</v-icon></div>
                </div>
                <div class="legend-wrapper">
                    <div class="legend-content">
                        <div class="legend-header">
                            <v-text-field v-model="data.title" placeholder="Legenden Titel (Adresse)" solo></v-text-field>
                            <v-select
                                :value="data.orientation ? 'Querformat' : 'Hochformat'"
                                :items="['Hochformat', 'Querformat']"
                                label="Ausrichtung"
                                solo
                                @input="data.orientation = $event == 'Querformat'"
                            />
                        </div>
                        <div class="legend-body">
                            <div class="group-content">
                                <div v-for="(group, groupIndex) in data.groups" :key="groupIndex" class="legend-group">
                                    <div class="group-header">
                                        <div class="item-header">Gruppenbezeichnung</div>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="moveTo(groupIndex, groupIndex - 1)"><v-icon>mdi-chevron-up</v-icon></v-btn>
                                        <v-btn icon @click="moveTo(groupIndex, groupIndex + 1)"><v-icon>mdi-chevron-down</v-icon></v-btn>
                                        <ConfirmDialog
                                            title="Gruppe entfehrnen?"
                                            text="Soll diese Gruppe entfehrnt werden?"
                                            @resolve="removeGroup(groupIndex)"
                                        >
                                            <template #activator="{ attrs, on }">
                                                <v-btn icon color="error" v-bind="attrs" v-on="on"><v-icon>mdi-close</v-icon></v-btn>
                                            </template>
                                        </ConfirmDialog>
                                    </div>
                                    <div class="group-info">
                                        <v-text-field v-model="group.title" label="Gruppenbezeichnung" placeholder="Ab Messung ..." />
                                        <v-text-field v-model="group.field" label="Feldbezeichnung" placeholder="Feld ..." />
                                    </div>
                                    <div class="legend-item legend-title mb-3">
                                        <div class="item-header">BMK</div>
                                        <div class="item-header">Andere Geräte</div>
                                        <div class="item-header">Beschreibung</div>
                                    </div>

                                    <div v-for="(entry, entryIndex) in group.items" :key="entryIndex" class="legend-item">
                                        <v-text-field v-model="entry.key" dense placeholder="BMK" class="uppercase" />
                                        <v-combobox
                                            v-model="entry.items"
                                            dense
                                            placeholder="Andere Geräte"
                                            multiple
                                            chips
                                            append-icon=""
                                            class="uppercase"
                                        >
                                            <template #selection="{ attrs, item, parent, selected }">
                                                <v-chip v-bind="attrs" :input-value="selected" small class="ma-1">
                                                    <span class="pr-2">{{ item }}</span>
                                                    <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
                                                </v-chip>
                                            </template>
                                        </v-combobox>
                                        <v-combobox
                                            v-model="entry.value"
                                            :items="text_presets"
                                            dense
                                            placeholder="Beschreibung"
                                            append-icon=""
                                            @keydown.tab="newLine(group, entryIndex, $event)"
                                        />
                                        <div class="item-action">
                                            <v-btn icon tabindex="-1" @click="insertLine(group, entryIndex)">
                                                <v-icon>mdi-playlist-plus</v-icon>
                                            </v-btn>
                                            <v-btn icon tabindex="-1" @click="removeLine(group, entryIndex)">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-ripple class="legend-button primary white--text" @click="addGroup">Gruppe hinzufügen</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.legend-main {
    display: grid;
    grid-template-areas:
        "toolbar"
        "content";
    grid-template-columns: 100vw;
    grid-template-rows: $navbar-height calc(100vh - $navbar-height);
    overflow: hidden;

    .legend-toolbar {
        grid-area: toolbar;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        background: $primary;
        color: white;

        .toolbar-title {
            font-size: 1.2rem;
        }

        .toolbar-button {
            min-width: $navbar-height;
            height: $navbar-height;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background: lighten($primary, 5%);
            }
        }
    }

    .legend-wrapper {
        grid-area: content;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
        background: $background;

        .legend-content {
            width: 100%;
            height: 100%;
            max-width: 1200px;
            display: grid;
            grid-template-areas:
                "header"
                "content";
            grid-template-columns: auto;
            grid-template-rows: 10rem calc(100% - 10rem);

            .legend-header {
                grid-area: header;
                display: flex;
                flex-direction: column;
                padding: 0 1rem;
            }

            .legend-body {
                grid-area: content;
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: calc(100% - 5rem) 3rem;
                gap: 1rem;

                .group-content {
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    padding: 0 1rem 1rem 1rem;

                    .legend-group {
                        background: white;
                        box-shadow: $box-shadow-1;
                        padding: 1rem;

                        .group-header {
                            display: flex;
                            gap: 1rem;
                        }

                        .group-info {
                            display: grid;
                            gap: 1rem;
                            grid-template-columns: auto 10rem;
                        }
                    }

                    .legend-item {
                        display: grid;
                        gap: 1rem;
                        grid-template-columns: 5rem 15rem auto 5.5rem;
                        align-items: end;

                        .item-action {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                        }
                    }

                    .item-header {
                        font-weight: bold;
                        text-transform: uppercase;
                        font-size: 0.8rem;
                    }
                }

                .legend-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 3rem;
                    min-height: 3rem;
                    cursor: pointer;
                    user-select: none;
                    margin: 0 1rem 1rem 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .legend-main {
        .legend-wrapper {
            .legend-content {
                .legend-body {
                    .group-content {
                        .legend-title {
                            display: none;
                        }

                        .legend-group {
                            .group-info {
                                grid-template-columns: auto;
                                grid-template-rows: 1fr 1fr;
                            }
                        }

                        .legend-item {
                            grid-template-columns: 100%;
                            grid-template-columns: repeat(1fr);
                            margin-bottom: 1rem;
                            padding: 1rem;
                            box-shadow: $box-shadow-1;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.uppercase input,
.uppercase span {
    text-transform: uppercase !important;
}

.uppercase input::placeholder {
    text-transform: none !important;
}
</style>
