<script>
import { mapGetters, mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";
import DatePicker from "@components/DatePicker.vue";
import TimePicker from "@components/TimePicker.vue";
import EntryTable from "@components/contract/EntryTable.vue";

import permissionMixin from "@mixin/permission.mixin";
import axios from "axios";

export default {
    name: "ContractDataDialogComponent",
    components: {
        DialogLayout,
        DatePicker,
        EntryTable,
        TimePicker,
    },
    mixins: [permissionMixin],
    props: {
        contract: { type: Object, required: true },
        formular: { type: Object, default: () => {} },
    },
    data() {
        return {
            dialog: false,
            form: false,
            width: 0,
            data: {
                service: false,
                contract_type: "normal",
                customer_contacted: false,
                transport: false,
                carrier: null,
                call_early: false,
                call_early_notes: null,
                transport_date: null,
                transport_time: null,
                transport_location_alt: null,
                electrician_on_site: null,
                electrician_on_site_phone: null,
                site_street: null,
                site_plz: null,
                site_city: null,

                installer: null,
                different_install_time: false,
                install_date: null,
                install_time: null,
                installer_amount: 1,

                transport_notes: null,

                monteur: null,
                system_amount: 1,
                schema_id: null,
                socket: false,
                devices_missing: false,
                missing_devices: [],
                devices_returned: false,
                returned_devices: [],
                assembly_notes: null,
            },
            contract_types: [
                { key: "Normal", value: "normal" },
                { key: "Service", value: "service" },
                { key: "Materialbestellung", value: "material" },
                { key: "Leeranlage", value: "empty_system" },
            ],
            requiredRule: [(v) => !!v || "Eingabe erforderlich"],
            phoneRule: [(v) => v?.length == 0 || /^(?:\+41)*[ ]*\d{2,3}[ ]*\d{3}[ ]*\d{2}[ ]*\d{2}$/.test(v) || "Ungültiges Format"],
        };
    },
    computed: {
        ...mapGetters({
            inspectors: "user/inspectors",
            carriers: "user/carriers",
            installers: "user/installers",
        }),
        time_presets() {
            return ["Morgen", "Mittag", "Nachmittag", "Ganzer Tag"];
        },
    },
    watch: {
        inspect_date_picker_value(value) {
            if (value) this.data.inspect_date = Date.parse(value);
        },
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                if (!value.call_early) this.data.call_early_notes = null;
                if (!value.transport_location_alt && !value.service && value.contract_type != "service") {
                    this.data.site_street = this.contract?.street;
                    this.data.site_plz = this.contract?.plz;
                    this.data.site_city = this.contract.city;
                }
                if (value.service || value.contract_type == "service") {
                    this.data.site_street = null;
                    this.data.site_plz = null;
                    this.data.site_city = null;
                }
                if (value?.transport == false) {
                    this.data.carrier = null;
                    this.data.electrician_on_site = null;
                    this.data.electrician_on_site_phone = null;
                    this.data.transport_date = null;
                    this.data.transport_time = null;
                    this.data.call_early = false;
                    this.data.transport_location_alt = false;
                    this.data.call_early_notes = null;
                    this.data.site_street = this.contract?.street;
                    this.data.site_plz = this.contract?.plz;
                    this.data.site_city = this.contract.city;
                }
                if (value?.pickup == false) {
                    this.data.pickup_date = null;
                    this.data.pickup_time = null;
                }
            },
        },
        contract: {
            immediate: true,
            deep: true,
            handler(value) {
                this.data = value.data || {};
                if (!this.data.contract_type) this.data.contract_type = "normal";
            },
        },
    },
    mounted() {
        this.width = window.innerWidth;
        window.onresize = () => (this.width = window.innerWidth);
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        open() {
            this.dialog = true;
        },
        save() {
            document.activeElement.blur();

            setTimeout(() => {
                this.axios
                    .post("/contract/" + this.contract.contract_id + "/data", this.data)
                    .then(() => this.logMessage("Die Auftragsinformationen wurden gespeichert"))
                    .then(() => (this.dialog = false))
                    .then(() => this.$emit("update:contract"))
                    .catch(() => this.logError("Die Auftragsinformationen konnten nicht gespeichert werden"));
            }, 10);
        },
        queryPLZ(value) {
            if (value?.length == 4)
                axios
                    .get("https://swisspost.opendatasoft.com/api/records/1.0/search/?dataset=plz_verzeichnis_v2&rows=1&facet=ortbez18&q=" + value)
                    .then((res) => (this.data.site_city = res?.data?.records[0]?.fields?.ortbez18));
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" title="Auftragsinformationen" :fullscreen="width < 900" width="800" @close="$emit('close')">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-form v-model="form">
                <!-- Werkstatt -->
                <template v-if="isInGroup('monteur')">
                    <v-row>
                        <v-col cols="8">
                            <v-text-field v-model="data.monteur" label="Erbauer" />
                        </v-col>
                        <v-col cols="4"><v-text-field v-model="data.system_amount" type="number" label="Anzahl Anlageteile" /></v-col>
                    </v-row>
                    <v-text-field v-model="data.schema_id" class="mb-6" label="Schemanummer" />
                    <template v-if="contract.status >= 2">
                        <v-select
                            :value="data.socket == true ? 'Ja' : data.socket == false ? 'Nein' : null"
                            :items="['Ja', 'Nein']"
                            label="Sockel mitliefern"
                            @input="data.socket = $event == 'Ja' ? true : $event == 'Nein' ? false : null"
                        />
                        <v-select
                            :value="data.bag == true ? 'Ja' : data.bag == false ? 'Nein' : null"
                            :items="['Ja', 'Nein']"
                            label="Tragtasche mitliefern"
                            @input="data.bag = $event == 'Ja' ? true : $event == 'Nein' ? false : null"
                        />
                        <v-checkbox v-model="data.devices_missing" label="Ausstehende Geräte" />
                        <EntryTable
                            v-if="data.devices_missing"
                            v-model="data.missing_devices"
                            definition="Gerät"
                            emty="Alle Geräte vorhanden"
                            class="mb-12"
                        />
                        <v-checkbox v-model="data.devices_returned" label="Überschüssige Geräte" />
                        <EntryTable
                            v-if="data.devices_returned"
                            v-model="data.returned_devices"
                            definition="Gerät"
                            emty="Keine Geräte überschüsig"
                            class="mb-12"
                        />
                    </template>
                    <v-textarea v-model="data.assembly_notes" label="Anmerkungen Monteur" />
                </template>

                <v-divider v-if="isInGroup('monteur') && isInGroup('avor')" class="my-6" />

                <!-- Büro -->
                <template v-if="isInGroup('avor')">
                    <v-select v-model="data.contract_type" :items="contract_types" item-text="key" item-value="value" label="Auftragsart" />
                    <v-text-field v-model="data.schema_id" class="mb-6" label="Schemanummer" />
                    <v-row>
                        <v-col v-if="!data.service && data.contract_type != 'service' && !data.pickup">
                            <v-checkbox v-model="data.transport" label="Lieferung zum Kunden" />
                        </v-col>
                        <v-col v-if="!data.service && data.contract_type != 'service' && !data.transport">
                            <v-checkbox v-model="data.pickup" label="Abholung durch Kunde" />
                        </v-col>
                        <v-col><v-checkbox v-model="data.customer_contacted" label="Kunde kontaktiert" /></v-col>
                    </v-row>

                    <template v-if="data.transport && !data.service && data.contract_type != 'service' && data.contract_type != 'material'">
                        <v-select v-model="data.carrier" :items="carriers" item-text="data.long" item-value="id" label="Transport durch" />
                        <v-row>
                            <v-col><v-text-field v-model="data.electrician_on_site" label="Kontakt Vorort" /></v-col>
                            <v-col>
                                <v-text-field v-model="data.electrician_on_site_phone" label="Kontakt Telefon" type="tel" :rules="phoneRule" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col><DatePicker v-model="data.transport_date" label="Lieferdatum" /></v-col>
                            <v-col><v-combobox v-model="data.transport_time" :items="time_presets" label="Lieferzeit" append-icon="" /></v-col>
                        </v-row>
                        <v-row dense>
                            <v-col><v-checkbox v-model="data.call_early" label="Lieferung anvisieren" /></v-col>
                            <v-col><v-checkbox v-model="data.transport_location_alt" label="Abweichende Lieferadresse" /></v-col>
                        </v-row>
                        <v-row dense align="start">
                            <v-col dense>
                                <v-text-field v-model="data.call_early_notes" :disabled="!data.call_early" label="Anmerkungen Kontaktaufnahme" />
                            </v-col>
                            <v-col dense>
                                <v-text-field v-model="data.site_street" :disabled="!data.transport_location_alt" label="Strasse" />
                                <v-row dense>
                                    <v-col dense cols="4">
                                        <v-text-field
                                            v-model="data.site_plz"
                                            :disabled="!data.transport_location_alt"
                                            label="PLZ"
                                            type="number"
                                            @input="queryPLZ"
                                        />
                                    </v-col>
                                    <v-col dense cols="8">
                                        <v-text-field v-model="data.site_city" :disabled="!data.transport_location_alt" label="Ort" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-if="data.pickup">
                        <v-row>
                            <v-col><DatePicker v-model="data.pickup_date" label="Abholdatum" /></v-col>
                            <v-col><v-combobox v-model="data.pickup_time" :items="time_presets" label="Abholzeit" append-icon="" /></v-col>
                        </v-row>
                    </template>

                    <template v-if="formular && formular.data && formular.data.assembly && formular.data.assembly == 'Ja'">
                        <v-select v-model="data.installer" :items="installers" item-text="data.long" item-value="id" label="Montage durch" />
                        <v-checkbox v-model="data.different_install_time" label="abweichendes Montagedatum" />
                        <v-row v-if="data.different_install_time">
                            <v-col><DatePicker v-model="data.install_date" multiple label="Montagedatum" /></v-col>
                            <v-col><TimePicker v-model="data.install_time" label="Montagebegin" /></v-col>
                        </v-row>
                        <v-text-field v-model="data.installer_amount" type="number" label="Anzahl Monteure (inkl. Leitung)" />
                    </template>
                    <template v-else-if="data.service || data.contract_type == 'service'">
                        <v-row>
                            <v-col cols="8">
                                <v-select v-model="data.installer" :items="installers" item-text="data.long" item-value="id" label="Montage durch" />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="data.installer_amount" type="number" label="Anzahl Monteure (inkl. Leitung)" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col><v-text-field v-model="data.electrician_on_site" label="Kontakt Vorort" /></v-col>
                            <v-col>
                                <v-text-field v-model="data.electrician_on_site_phone" label="Kontakt Telefon" type="tel" :rules="phoneRule" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col><DatePicker v-model="data.install_date" multiple label="Montagedatum" /></v-col>
                            <v-col><TimePicker v-model="data.install_time" label="Montagebegin" /></v-col>
                        </v-row>
                        <v-text-field v-model="data.site_street" label="Strasse" />
                        <v-row dense>
                            <v-col dense cols="4">
                                <v-text-field v-model="data.site_plz" label="PLZ" type="number" @input="queryPLZ" />
                            </v-col>
                            <v-col dense cols="8">
                                <v-text-field v-model="data.site_city" label="Ort" />
                            </v-col>
                        </v-row>
                    </template>

                    <v-textarea v-model="data.transport_notes" label="Notizen" />
                </template>
            </v-form>
        </template>
        <template #action="{ action }">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="action">Abbrechen</v-btn>
            <v-btn color="primary" text @click="save(action)">Speichern</v-btn>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.datatitle {
    padding: 0.5rem 5px;
    font-size: 16px;
}
</style>
