<script>
export default {
    name: "CalendarLayout",
};
</script>

<template>
    <div class="calendar-layout">
        <div class="toolbar"><slot name="toolbar" /></div>
        <div class="content"><slot /></div>
    </div>
</template>

<style lang="scss" scoped>
.calendar-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    padding-top: 1rem;
    overflow: hidden;

    .toolbar {
        width: 100%;
        max-width: 1000px;
        padding: 1rem;
        padding-bottom: 0;
        margin: 0 auto;

        display: flex;
        gap: 1rem;
    }

    .content {
        width: 100%;
        max-width: 1000px;
        height: calc(100% - 2rem);
        gap: 1rem;
        padding: 0 1px 0 1rem;
        margin: 1rem auto;
        overflow-y: scroll;
    }
}
</style>
