var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"absolute":"","position-x":_vm.dialogX,"position-y":_vm.dialogY},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.item)?_c('v-list',[(_vm.item.canPrint())?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('print', _vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-list-item-title',[_vm._v("Datei drucken")])],1):_vm._e(),(_vm.item.canSend())?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('send', _vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-list-item-title',[_vm._v("Datei senden")])],1):_vm._e(),(_vm.item.canImport())?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('import', _vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-import")])],1),_c('v-list-item-title',[_vm._v("Daten Importieren")])],1):_vm._e(),(_vm.item.canEdit())?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('edit', _vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("Datei bearbeiten")])],1):_vm._e(),(_vm.item.canOpenExternal())?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('external', _vm.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-open-in-new")])],1),_c('v-list-item-title',[_vm._v("Datei öffnen")])],1):_vm._e(),(_vm.item.canDelete())?_c('ConfirmDialog',{attrs:{"title":"Datei löschen?","text":_vm.item.name},on:{"resolve":function($event){_vm.item.deleteFile();
                _vm.close();
                _vm.$emit('update:files');
                _vm.$emit('update:formular');
                _vm.$emit('update:legend');
                _vm.$emit('update:rapport');}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Datei löschen")])],1)]}}],null,false,935172695)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }