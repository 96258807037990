export default {
    methods: {
        generateFormularReport(formular) {
            return {
                formular_exists: formular != undefined,
                formular_edited: formular?.data != undefined,
                access: formular?.data?.access != undefined,
                system_design: formular?.data?.system_design != undefined,
                rated_voltage: formular?.data?.rated_voltage != undefined,
                rated_current: formular?.data?.rated_current != undefined,
                rated_current_valide:
                    formular?.data?.access == "Laien"
                        ? Array.isArray(formular?.data?.rated_current)
                            ? formular?.data?.rated_current.find((current) => current > 250) == undefined
                            : formular?.data?.rated_current <= 250
                        : true,
                main_fuse_location: formular?.data?.main_fuse_location != undefined,
                short_circuit_resistance:
                    formular?.data?.ipk != undefined ||
                    formular?.data?.icp != undefined ||
                    formular?.data?.icc != undefined ||
                    (formular?.data?.icw != undefined && formular?.data?.icw_time != undefined && formular?.data?.ipk != undefined),
                main_fuse: formular?.data?.main_fuse_type != undefined && formular?.data?.main_fuse_size != undefined,
                fuse_amount: formular?.data?.fuse_amount != undefined,
                mounting_location: formular?.data?.mounting_location != undefined,
                low_voltage_grid: formular?.data?.low_voltage_grid != undefined || formular?.data?.system_design == "Kleinverteiler" || formular?.data?.system_design == "geschlossene Bauform (Kleinverteiler)",
                copper_system: formular?.data?.copper_system != undefined || formular?.data?.access == "Laien",
                protection: formular?.data?.protection != undefined || formular?.data?.access == "Laien",
                ip_protection: formular?.data?.ip_protection != undefined || formular?.data?.system_design == "Geschlossene Bauform",
                fuse_change: formular?.data?.fuse_change != undefined,
                dimension: formular?.data?.width != undefined && formular?.data?.height != undefined && formular?.data?.depth != undefined,
                transportable: formular?.data?.transportable != undefined,
                assembly: formular?.data?.assembly != undefined,
                power_station: formular?.data?.power_station != undefined,
            };
        },
        isFormularComplete(formular) {
            return Object.entries(this.generateFormularReport(formular)).reduce((value, item) => {
                if (item[1] == false) return false;
                return value;
            }, true);
        },
    },
};
