<script>
import { mapState } from "vuex";

import Loading from "@components/_loading.vue";
import AccountMenu from "@components/menus/AccountMenu.vue";
import NotificationMenu from "@components/menus/NotificationMenu.vue";
import NavButton from "@components/layouts/main/NavButton.vue";
import NavItem from "@components/layouts/main/NavItem.vue";
import NavMenu from "@components/menus/NavMenu.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "MainLayout",
    components: {
        Loading,
        AccountMenu,
        NotificationMenu,
        NavButton,
        NavItem,
        NavMenu,
    },
    mixins: [permissionMixin],
    props: {
        loading: { type: Boolean, default: undefined },
    },
    data() {
        return {
            isLoading: true,
        };
    },
    computed: {
        ...mapState({
            account: (state) => state.auth.account,
        }),
        avatarText() {
            if (!this.account) return "A";
            if (!this.account.data) return this.account.username.substring(0, 2).toUpperCase();
            if (!this.account.data.short) return this.account.username.substring(0, 2).toUpperCase();
            return this.account.data.short.toUpperCase();
        },
    },
    watch: {
        loading(value) {
            this.isLoading = value;
        },
    },
    mounted() {
        if (this.loading == undefined) this.isLoading = false;
    },
};
</script>

<template>
    <div class="main-layout">
        <div class="navbar">
            <router-link v-slot="{ navigate }" to="/" custom>
                <img src="@assets/Fallegger_white.png" @click="navigate" />
            </router-link>
            <div class="nav">
                <NavButton icon="mdi-briefcase" label="Aufträge" to="/" />
                <NavButton v-if="isInGroup('avor')" icon="mdi-calendar" label="Kalender" to="/calendar" />
                <NavButton icon="mdi-gavel" label="Normen" to="/norms" />
                <NavButton v-if="isInGroup('avor') || isInGroup('inspector')" icon="mdi-magnify" label="Filter" to="/filter" />
                <slot name="navbar" />
            </div>
            <div class="nav-mini">
                <NavMenu>
                    <template #activator="{ on, attrs, isActive }">
                        <NavItem :active="isActive" v-bind="attrs" v-on="on">
                            <v-icon dark>mdi-menu</v-icon>
                        </NavItem>
                    </template>
                </NavMenu>
                <slot name="navbar.mini" />
            </div>
            <v-spacer />
            <div class="append">
                <slot name="navbar.append" />
                <NotificationMenu>
                    <template #activator="{ on, attrs, items, isActive }">
                        <NavItem :active="isActive" v-bind="attrs" v-on="on">
                            <v-badge overlap color="error" :value="items.length > 0" :content="items.length">
                                <v-icon dark>mdi-bell</v-icon>
                            </v-badge>
                        </NavItem>
                    </template>
                </NotificationMenu>
                <AccountMenu>
                    <template #activator="{ on, attrs, isActive }">
                        <NavItem :active="isActive" v-bind="attrs" v-on="on">
                            <v-avatar color="white" size="40">{{ avatarText }}</v-avatar>
                        </NavItem>
                    </template>
                </AccountMenu>
            </div>
        </div>
        <div v-if="!isLoading" class="content">
            <slot />
        </div>
        <Loading v-else />
    </div>
</template>

<style lang="scss" scoped>
.main-layout {
    width: 100vw;
    height: calc(100vh - env(titlebar-area-height, $titlebar-area-height-alt));
    display: grid;
    grid-template-rows: $navbar-height calc(100% - $navbar-height);
    grid-template-columns: 100%;
    overflow: hidden;

    .navbar {
        display: flex;
        background: $primary;
        align-items: center;
        padding: 0 1rem;

        .nav {
            display: flex;
        }

        .nav-mini {
            display: none;
        }

        img {
            height: 60%;
            cursor: pointer;
            margin: 0 2rem 0 1rem;
        }

        img.medium {
            display: none;
        }

        .v-avatar {
            cursor: pointer;
            user-select: none;
        }

        .append {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .content {
        display: contents;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 960px) {
    .main-layout {
        .navbar {
            img {
                content: url("@assets/Fallegger_white_small.png");
                height: 40%;
            }
        }
    }
}

@media only screen and (max-width: 775px) {
    .main-layout {
        .navbar {
            img {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .main-layout {
        .navbar {
            padding: 0;

            .nav {
                display: none;
            }

            .nav-mini {
                display: unset;
            }
        }
    }
}
</style>

<style lang="scss">
.navbar:hover {
    #navbutton-label {
        font-size: 12px !important;
    }

    #navbutton-notch {
        transform: translateY(calc($navbar-notch-transform + 0.5rem)) rotate(45deg) !important;
    }
}
</style>
