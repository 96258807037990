<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "EVURenameDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        evu: { type: String, required: true },
    },
    data() {
        return {
            name: null,
            requiredRule: [(v) => !!v || "Eingabe ist erforderlich"],
        };
    },
    watch: {
        evu: {
            immediate: true,
            handler(value) {
                this.name = value;
            },
        },
    },
    methods: {
        ...mapMutations({
            logMessage: "ui/log",
            logError: "ui/error",
        }),
        close(action) {
            action();
            this.$emit("close");
        },
        async submit(action) {
            try {
                await this.axios.post("/evu/" + this.evu, { name: this.name });
                this.logMessage("EVU wurde umbenannt");
                this.$emit("update");
                this.close(action);
            } catch (error) {
                console.log(error);
                this.logError("Es ist ein Fehler aufgetreten");
            }
        },
    },
};
</script>

<template>
    <DialogLayout title="EVU Umbenennen" width="500">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-text-field v-model="name" :rules="requiredRule" label="EVU Name" />
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="!name" @click="submit(action)">Speichern</v-btn>
        </template>
    </DialogLayout>
</template>
