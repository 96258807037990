<script>
import { mapMutations } from "vuex";

import ConfirmDialog from "@components/dialogs/ConfirmDialog.vue";
import EVURenameDialog from "@components/dialogs/EVURenameDialog.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "NormItemComponent",
    components: {
        ConfirmDialog,
        EVURenameDialog,
    },
    mixins: [permissionMixin],
    props: {
        value: { type: Object, default: undefined },
    },
    methods: {
        ...mapMutations({
            logMessage: "ui/log",
            logError: "ui/error",
        }),
        removeItem(item) {
            const filteredNorms = this.value.norms.filter((norm) => norm.text != item.text);
            this.axios
                .post(this.value.type + "/" + this.value.id + "/norms", filteredNorms)
                .then(() => {
                    this.logMessage("Norm wurde erfolgreich geändert");
                    this.$emit("update");
                    this.$emit("input", {
                        ...this.value,
                        norms: filteredNorms,
                    });
                })
                .catch(() => this.logError("Ein fehler ist aufgetreten"));
        },
        removeEVU() {
            this.axios
                .delete("/evu/" + this.value.id)
                .then(() => this.logMessage("Norm wurde erfolgreich gelöscht"))
                .then(() => this.$emit("update"))
                .catch(() => this.logError("Norm konnte nicht gelöscht werden"));
        },
    },
};
</script>

<template>
    <v-scroll-y-transition mode="out-in">
        <v-card v-if="value" :key="value.name" flat>
            <v-card-text>
                <v-row class="px-3">
                    <v-spacer></v-spacer>
                    <h3 class="text-h5">{{ value.name }}</h3>
                    <v-spacer></v-spacer>
                    <EVURenameDialog v-if="value.type == 'evu' && hasPermission('norm.edit.evu')" :evu="value.name" @update="$emit('update')">
                        <template #activator="{ attrs, on }">
                            <v-btn icon small v-bind="attrs" v-on="on"><v-icon small>mdi-pencil</v-icon></v-btn>
                        </template>
                    </EVURenameDialog>
                    <ConfirmDialog
                        v-if="value.type == 'evu' && hasPermission('norm.remove.evu')"
                        title="EVU-Norm löschen?"
                        text="Soll dieser Eintrag gelöscht werden?"
                        @resolve="removeEVU"
                    >
                        <template #activator="{ attrs, on }">
                            <v-btn icon small color="error" v-bind="attrs" v-on="on"><v-icon small>mdi-close</v-icon></v-btn>
                        </template>
                    </ConfirmDialog>
                </v-row>
                <v-divider class="my-4" />
                <ul>
                    <li v-for="(norm, index) in value.norms" :key="index">
                        <div class="norm-item">
                            {{ norm.text }}
                            <v-spacer></v-spacer>
                            <div class="item-action">
                                <ConfirmDialog
                                    v-if="hasPermission('norm.edit.' + value.type)"
                                    title="Norm-Eintrag löschen?"
                                    :text="norm.text"
                                    @resolve="removeItem(norm)"
                                >
                                    <template #activator="{ attrs, on }">
                                        <v-btn icon small color="error" v-bind="attrs" v-on="on">
                                            <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                </ConfirmDialog>
                            </div>
                        </div>
                    </li>
                </ul>
            </v-card-text>
        </v-card>
        <div v-else>Kein Eintrag ausgewählt</div>
    </v-scroll-y-transition>
</template>

<style lang="scss" scoped>
li {
    text-align: start;

    .norm-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        border-bottom: 1px solid #ccc;
        padding: 5px 0;

        .item-action {
            display: flex;
        }
    }
}
</style>
