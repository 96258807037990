<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "ContractStatusDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        contract: { type: Object, required: true },
    },
    data() {
        return {
            status: 0,
            status_items: [
                { text: "Arbeitsvorbereitung", value: 0 },
                { text: "In Arbeit", value: 1 },
                { text: "Geprüft", value: 2 },
                { text: "Transportbereit", value: 3 },
                { text: "Abgeschlossen", value: 4 },
            ],
        };
    },
    watch: {
        contract: {
            immediate: true,
            handler(contract) {
                if (contract) this.status = this.contract.status;
            },
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        close(action) {
            action();
            this.$emit("close");
        },
        save(action) {
            this.axios
                .post("/contract/" + this.contract.contract_id + "/status", { status: this.status })
                .then(() => {
                    this.logMessage("Der Status wurde geändert");
                    this.$emit("update:contract");
                    this.close(action);
                })
                .catch(() => this.logError("Es ist ein fehler aufgetreten"));
        },
    },
};
</script>

<template>
    <DialogLayout title="Verantwortlicher bearbeiten" width="400" v-bind="$attrs">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-select v-model="status" :items="status_items" item-text="text" item-value="value" />
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="save(action)">Speichern</v-btn>
        </template>
    </DialogLayout>
</template>
