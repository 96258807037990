import { render, staticRenderFns } from "./FileDrag.vue?vue&type=template&id=5b65b50b&scoped=true&"
import script from "./FileDrag.vue?vue&type=script&lang=js&"
export * from "./FileDrag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b65b50b",
  null
  
)

export default component.exports