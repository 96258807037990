<script>
export default {
    name: "DialogLayoutComponent",
    props: {
        value: { type: Boolean, default: false },
        title: { type: String, default: undefined },
        subtitle: { type: String, default: undefined },
        fullscreen: { type: Boolean, default: undefined },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        hasAction() {
            return !!this.$slots["action"] || !!this.$scopedSlots["action"];
        },
        hasContent() {
            return !!this.$slots["content"] || !!this.$scopedSlots["content"];
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (this.dialog != value) this.dialog = value;
                if (value) this.$emit("reset");
            },
        },
        dialog: {
            immediate: true,
            handler(value) {
                if (this.value != value) this.$emit("input", value);
                if (!value) this.$emit("close");
            },
        },
    },
    methods: {
        close() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
    },
};
</script>

<template>
    <v-dialog v-model="dialog" :fullscreen="fullscreen" v-bind="$attrs">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <v-card rounded="0" height="100%">
            <template v-if="title || subtitle">
                <v-card-title v-if="title">{{ title }}</v-card-title>
                <v-card-subtitle v-if="subtitle">{{ subtitle }}</v-card-subtitle>
                <v-divider></v-divider>
            </template>
            <slot name="content" :action="close" />
            <v-card-text v-if="!hasContent" style="padding: 0">
                <div class="dialog-main" :style="{ 'max-height': fullscreen ? 'calc(100vh - 7rem)' : '70vh' }">
                    <slot :action="close" />
                </div>
            </v-card-text>
            <template v-if="hasAction">
                <v-divider></v-divider>
                <v-card-actions>
                    <slot name="action" :action="close" />
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.dialog-main {
    width: 100%;
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
