<script>
import { mapGetters, mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "InspectionDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        contract: { type: Object, required: true },
        formular: { type: Object, default: undefined },
        checklist: { type: Object, default: () => {} },
    },
    data() {
        return {
            inspector: null,
            schema_id: null,
            icc: undefined,
            ipk: undefined,
            icp: undefined,
            weight: undefined,
            report: {},
        };
    },
    computed: {
        ...mapGetters({
            inspectors: "user/inspectors",
        }),
        title() {
            if (this.contract.status == 1) return "Anlage geprüft";
            if (this.contract.status == 2) return "Schlusskontrolle bestanden";
            return "Fehler";
        },
        subtitle() {
            if (this.contract.status == 1) return "Wurde die Anlage erfolgreich geprüft?";
            if (this.contract.status == 2) return "Hat die Anlage die Schlusskontrolle bestanden?";
            return "Fehler";
        },
        formularReport() {
            return {
                formular_exists: this.formular != undefined,
                formular_edited: this.formular?.data != undefined,
                access: this.formular?.data?.access != undefined,
                system_design: this.formular?.data?.system_design != undefined,
                rated_voltage: this.formular?.data?.rated_voltage != undefined,
                rated_current: this.formular?.data?.rated_current != undefined,
                main_fuse_location: this.formular?.data?.main_fuse_location != undefined,
                short_circuit_resistance:
                    this.formular?.data?.ipk != undefined ||
                    this.formular?.data?.icp != undefined ||
                    this.formular?.data?.icc != undefined ||
                    (this.formular?.data?.icw != undefined && this.formular?.data?.icw_time != undefined && this.formular?.data?.ipk != undefined),
                main_fuse: this.formular?.data?.main_fuse_type != undefined && this.formular?.data?.main_fuse_size != undefined,
                fuse_amount: this.formular?.data?.fuse_amount != undefined,
                mounting_location: this.formular?.data?.mounting_location != undefined,
                low_voltage_grid: this.formular?.data?.low_voltage_grid != undefined || this.formular?.data?.system_design == "Kleinverteiler" || this.formular?.data?.system_design == "geschlossene Bauform (Kleinverteiler)",
                copper_system: this.formular?.data?.copper_system != undefined || this.formular?.data?.access == "Laien",
                protection: this.formular?.data?.protection != undefined || this.formular?.data?.access == "Laien",
                ip_protection: this.formular?.data?.ip_protection != undefined || this.formular?.data?.system_design == "Geschlossene Bauform",
                fuse_change: this.formular?.data?.fuse_change != undefined,
                dimension:
                    this.formular?.data?.width != undefined && this.formular?.data?.height != undefined && this.formular?.data?.depth != undefined,
                transportable: this.formular?.data?.transportable != undefined,
                assembly: this.formular?.data?.assembly != undefined,
                power_station: this.formular?.data?.power_station != undefined,
            };
        },
        hasIcc() {
            return this.formular?.data?.main_fuse_location == "Nein";
        },
        hasIpk() {
            return this.formular?.data?.main_fuse_location == "Ja" && this.formular?.data?.short_circuit_resistance_simple == "Ipk";
        },
        hasIcp() {
            return this.formular?.data?.main_fuse_location == "Ja" && this.formular?.data?.short_circuit_resistance_simple == "Icp";
        },
        isNotEmpty() {
            return this.contract.data?.contract_type != "empty_system";
        },
    },
    watch: {
        checklist: {
            immediate: true,
            deep: true,
            handler(value) {
                this.report = value;
            },
        },
        contract: {
            immediate: true,
            deep: true,
            handler(value) {
                this.schema_id = value?.data?.schema_id;
            },
        },
        formular: {
            immediate: true,
            deep: true,
            handler(value) {
                this.icc = value?.data?.icc;
                this.icp = value?.data?.icp || this.calcIcp(value?.data?.ipk);
                this.weight = value?.data?.weight;
            },
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        close(action) {
            action();
            this.$emit("close");
        },
        calcIpk(icp) {
            if (icp <= 5) return Math.round(icp * 1.5);
            if (icp > 5 && icp <= 10) return Math.round(icp * 1.7);
            if (icp > 10 && icp <= 20) return Math.round(icp * 2);
            if (icp > 20 && icp <= 50) return Math.round(icp * 2.1);
            return Math.round(icp * 2.2);
        },
        calcIcp(ipk) {
            if (ipk <= 8) return Math.round(ipk / 1.5);
            if (ipk > 8 && ipk <= 17) return Math.round(ipk / 1.7);
            if (ipk > 17 && ipk <= 40) return Math.round(ipk / 2);
            if (ipk > 40 && ipk <= 105) return Math.round(ipk / 2.1);
            return Math.round(ipk / 2.2);
        },
        async save(action) {
            try {
                if (this.contract.status == 1 && this.inspector)
                    await this.axios.post("/contract/" + this.contract.contract_id + "/data", {
                        inspector: this.inspector,
                        inspect_date: Date.now(),
                        schema_id: this.schema_id,
                    });

                if (this.contract.status == 1 && this.hasIcc)
                    await this.axios.post("/external/formular/" + this.formular.reference_id, { ...this.formular?.data, icc: this.icc });
                if (this.contract.status == 1 && this.hasIpk)
                    await this.axios.post("/external/formular/" + this.formular.reference_id, {
                        ...this.formular?.data,
                        ipk: this.calcIpk(this.icp),
                    });
                if (this.contract.status == 1 && this.hasIcp)
                    await this.axios.post("/external/formular/" + this.formular.reference_id, { ...this.formular?.data, icp: this.icp });
                if (this.contract.status == 2 && this.weight)
                    await this.axios.post("/external/formular/" + this.formular.reference_id, { ...this.formular?.data, weight: this.weight });
                
                await this.axios.post("/contract/" + this.contract.contract_id + "/status");
                this.logMessage("Der Auftragsstatus wurde geändert");
                this.$emit("update:contract");
                this.$emit("update:formular");
                this.close(action);
            } catch (error) {
                this.logError("Es ist ein fehler aufgetreten");
            }
        },
    },
};
</script>

<template>
    <DialogLayout :title="title" width="600">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <div>{{ subtitle }}</div>
            <template v-if="contract.status == 1">
                <v-select v-model="inspector" :items="inspectors" item-text="data.long" item-value="id" label="Geprüft von" />
                <v-text-field v-if="isNotEmpty" v-model="schema_id" label="Schemanummer" />
                <v-text-field v-if="hasIcc" v-model="icc" label="Bedingter Bemessungskurzschlussstrom Icc (kA)" type="number" />
                <v-text-field v-if="hasIcp || hasIpk" v-model="icp" label="unbeeinflusster Kurzschlussstrom Icp (kA)" type="number" />
                <template v-if="isNotEmpty">
                    <v-checkbox v-model="report.formular" class="mt-6" dense readonly label="Schnittstellenprotokoll komplett" />
                    <v-checkbox v-model="report.legend" dense readonly label="Legende vorhanden" />
                </template>
            </template>
            <template v-if="contract.status == 2">
                <v-checkbox v-model="report.image" dense readonly label="Bilder hochgeladen" />
                <v-checkbox v-model="report.test_report" dense readonly label="Messprotokoll vorhanden" />
                <v-checkbox v-model="report.contract_data" dense readonly label="Auftragsinformationen" />
                <v-checkbox v-model="report.socket" style="margin-left: 2rem" dense readonly label="Sockelinformation" />
                <v-checkbox v-model="report.bag" style="margin-left: 2rem" dense readonly label="Tragtascheinformation" />
                <v-text-field v-model="weight" label="Gewicht (kg)" hint="optional" persistent-hint />
            </template>

            <!-- <v-row>
                <v-col>
                    <v-checkbox v-model="formularReport.formular_exists" dense readonly label="Legende erstellt" />
                    <v-checkbox v-model="formularReport.formular_exists" dense readonly label="Schnittstellenprotokoll erstellt" />
                    <v-checkbox v-model="formularReport.access" dense readonly label="Zugänglichkeit definiert" />
                    <v-checkbox v-model="formularReport.rated_voltage" dense readonly label="Bemessungsspannung definiert" />
                    <v-checkbox v-model="formularReport.main_fuse_location" dense readonly label="Vorsicherungsposition definiert" />
                    <v-checkbox v-model="formularReport.main_fuse" dense readonly label="Schaltschrankvorsicherung definiert" />
                    <v-checkbox v-model="formularReport.mounting_location" dense readonly label="Montageort definiert" />
                    <v-checkbox v-model="formularReport.copper_system" dense readonly label="Kupfersammelschienensystem definiert" />
                    <v-checkbox v-model="formularReport.ip_protection" dense readonly label="Gehäuse Schutzart definiert" />
                    <v-checkbox v-model="formularReport.dimension" dense readonly label="Anlagedimensionen definiert" />
                    <v-checkbox v-model="formularReport.assembly" dense readonly label="Montage definiert" />
                </v-col>
                <v-col>
                    <v-checkbox v-model="formularReport.formular_edited" dense readonly label="Schnittstellenprotokoll bearbeitet" />
                    <v-checkbox v-model="formularReport.system_design" dense readonly label="Anlagebauform definiert" />
                    <v-checkbox v-model="formularReport.rated_current" dense readonly label="Bemessungsstrom definiert" />
                    <v-checkbox v-model="formularReport.short_circuit_resistance" dense readonly label="Kurzschlussfestigkeit definiert" />
                    <v-checkbox v-model="formularReport.fuse_amount" dense readonly label="Anzahl Sicherungen definiert" />
                    <v-checkbox v-model="formularReport.low_voltage_grid" dense readonly label="Niederspannungsnetz definiert" />
                    <v-checkbox v-model="formularReport.protection" dense readonly label="Schutzart definiert" />
                    <v-checkbox v-model="formularReport.fuse_change" dense readonly label="Sicherungsaustausch definiert" />
                    <v-checkbox v-model="formularReport.transportable" dense readonly label="Transportierbarkeit definiert" />
                    <v-checkbox v-model="formularReport.power_station" dense readonly label="Verteilnetzbetreiber definiert" />
                </v-col>
            </v-row> -->
        </template>
        <template #action="{ action }">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-btn
                color="primary"
                :disabled="
                    (contract.status == 1 && !inspector) ||
                    (contract.status == 2 && (!checklist.contract_data || !checklist.socket || !checklist.bag)) ||
                    (isNotEmpty && !checklist.formular)
                "
                text
                @click="save(action)"
            >
                Bestätigen
            </v-btn>
        </template>
    </DialogLayout>
</template>
