import { render, staticRenderFns } from "./JournalDialog.vue?vue&type=template&id=6960a21a&scoped=true&"
import script from "./JournalDialog.vue?vue&type=script&lang=js&"
export * from "./JournalDialog.vue?vue&type=script&lang=js&"
import style0 from "./JournalDialog.vue?vue&type=style&index=0&id=6960a21a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6960a21a",
  null
  
)

export default component.exports