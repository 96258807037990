<script>
export default {
    name: "NormTreeviewComponent",
    props: {
        value: { type: Object, required: true },
        selected: { type: Object, default: undefined },
    },
    data() {
        return {
            tab: 0,
            active: [],
            selectedItem: undefined,
        };
    },
    computed: {
        items() {
            return [
                {
                    name: "Kunden",
                    children: this.value.customer.map((item) => ({ type: "customer", ...item })).sort((a, b) => (a.name > b.name ? 1 : -1)),
                },
                {
                    name: "EVU",
                    children: this.value.evu.map((item) => ({ type: "evu", ...item })).sort((a, b) => (a.name > b.name ? 1 : -1)),
                },
            ];
        },
    },
    watch: {
        selected: {
            immediate: true,
            handler(value) {
                this.selectedItem = value;
            },
        },
    },
    methods: {
        selectItem(items) {
            const item = items[0];
            if (item) {
                this.selectedItem = item;
                this.$emit("update:selected", item);
            }
        },
    },
};
</script>

<template>
    <v-treeview
        :active.sync="active"
        :open="items"
        :items="items"
        activatable
        open-on-click
        transition
        item-key="name"
        return-object
        @update:active="selectItem"
    >
        <template #prepend="{ item }">
            <v-icon v-if="!item.children">mdi-math-norm</v-icon>
        </template>
    </v-treeview>
</template>

<style lang="scss" scoped></style>
