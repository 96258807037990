<script>
export default {
    name: "TextfieldComponent",
    props: {
        icon: { type: String, default: undefined },
        placeholder: { type: String, default: undefined },
        width: { type: String, default: "100%" },
        value: { type: String, default: "" },
        dense: { type: String, default: undefined },
        type: { type: String, default: "text" },
        rounded: { type: String, default: undefined },
        filled: { type: Boolean, default: undefined },
        tall: { type: Boolean, default: undefined },
        center: { type: Boolean, default: undefined },
    },
    data() {
        return {
            data: "",
        };
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => {
                    this.$emit("input", event.target.value);
                },
                keyup: (event) => {
                    if (event.keyCode == 13) {
                        event.preventDefault();
                        this.$emit("submit", event);
                    } else this.$emit("keyup", event);
                },
            };
        },
        valide() {
            if (this.filled != undefined || this.filled) return true;
            if (!this.data) return false;
            return this.data.length > 0;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.data = value;
            },
        },
    },
};
</script>

<template>
    <div
        class="toolbar-textfield"
        :class="{ dense: dense != undefined, rounded: rounded != undefined, valide, tall: tall != undefined || tall }"
        :style="{ '--textfield-width': width }"
    >
        <v-icon v-if="icon" class="icon">{{ icon }}</v-icon>
        <input
            v-model="data"
            :class="{ center: center != undefined || center }"
            :placeholder="placeholder"
            :value="value"
            :type="type"
            v-on="listeners"
        />
    </div>
</template>

<style lang="scss" scoped>
.toolbar-textfield {
    height: $item-height;
    width: var(--textfield-width);
    background-color: $background;
    opacity: 0.5;
    padding: 0 10px;
    display: flex;
    align-items: center;
    transition: opacity 0.1s ease-in-out;

    &:hover {
        opacity: 1;
    }

    .icon {
        min-width: $item-height;
        min-height: $item-height;
        margin-left: -10px;
    }

    input {
        width: 100%;
        height: $item-height;
        outline: none;
    }
}

.toolbar-textfield.dense {
    height: $item-height-dense;
}

.toolbar-textfield.rounded {
    border-radius: $item-height !important;
    padding: 0 calc($item-height / 2);
}

.toolbar-textfield.valide {
    opacity: 1;
}

.toolbar-textfield.tall {
    height: calc($item-height * 1.5);
}

input.center {
    text-align: center;
}
</style>
