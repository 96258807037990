import axios, { AxiosInstance } from "axios";
import router from "@router/";
import store from "@store/";
import { Route } from "vue-router";

export interface CustomAxiosInstance extends AxiosInstance {
    getAccessToken: () => Promise<void | Route>;
}

export async function createInstance(baseURL): Promise<CustomAxiosInstance> {
    const instance = axios.create({
        baseURL: baseURL,
        withCredentials: true,
    }) as CustomAxiosInstance;

    function getAccessToken() {
        return instance
            .put("/auth")
            .then((response) => store.commit("auth/setAccessToken", response.data.access_token))
            .catch((error) => {
                if (router.currentRoute.path != "/login") router.push("/login");
                return error;
            });
    }

    function requestInterceptor(config) {
        if (store.state.auth.access_token) config.headers["Authorization"] = `Bearer ${store.state.auth.access_token}`;
        return config;
    }

    async function responseInterceptor(error) {
        const request = error.config;
        const response = error.response;

        //if no auth error
        if (response.status !== 401 && response.status !== 403) return Promise.reject(error);
        if (request._retry) return Promise.reject();

        //parse body
        const data = typeof response.data == "string" ? response.data : await response.data.text();

        //if no or invalide refresh_token
        if ((response.status == 403 && data == "invalide refresh_token") || (response.status == 401 && data == "no refresh_token")) {
            if (router.currentRoute.path != "/login") router.push("/login");
            return Promise.reject(error);
        }

        //if no or invalide access_token
        if ((response.status == 403 && data == "invalide access_token") || (response.status == 401 && data == "no access_token")) {
            request._retry = true;

            return getAccessToken().then(() => instance(request));
        }
    }

    instance.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
    instance.interceptors.response.use((res) => res, responseInterceptor);
    instance.getAccessToken = getAccessToken;

    try {
        await getAccessToken();
    } catch (error) {
        console.warn("credentials failed");
    }

    return instance;
}
