<script>
export default {
    name: "DatePickerComponent",
    props: {
        value: { type: [Number, Array], default: undefined },
        label: { type: String, default: undefined },
        multiple: { type: Boolean, default: undefined },
        disabled: { type: Boolean, default: undefined },
    },
    data() {
        return {
            state: false,
            date: null,
        };
    },
    computed: {
        displayDate() {
            if (this.multiple) {
                if (Array.isArray(this.date)) {
                    return this.date.map((item) => {
                        const date = this.$moment(item).format("DD.MM.YYYY");
                        return date == "Invalid date" ? undefined : date;
                    });
                } else {
                    const date = this.$moment(this.date).format("DD.MM.YYYY");
                    return date == "Invalid date" ? [] : [date];
                }
            }

            const date = this.$moment(this.date).format("DD.MM.YYYY");
            return date == "Invalid date" ? null : date;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (this.multiple) {
                    if (Array.isArray(value)) {
                        this.date = value.map((item) => {
                            const date = this.$moment(item).format("YYYY-MM-DD");
                            return date == "Invalid date" ? undefined : date;
                        });
                    } else {
                        const date = this.$moment(value).format("YYYY-MM-DD");
                        this.date = date == "Invalid date" ? [] : [date];
                    }
                } else {
                    const date = this.$moment(value).format("YYYY-MM-DD");
                    this.date = date == "Invalid date" ? null : date;
                }
            },
        },
    },
    methods: {
        save() {
            this.$refs.dialog.save(this.date);
            this.dialog = false;
            if (this.multiple) {
                if (Array.isArray(this.date)) {
                    this.$emit(
                        "input",
                        this.date.map((item) => Date.parse(item))
                    );
                } else this.$emit("input", [Date.parse(this.date)]);
            } else this.$emit("input", Date.parse(this.date));
        },
        reset() {
            this.date = null;
            this.save();
        },
        tab(event) {
            if (!event.shiftKey) this.$emit("tab", event);
        },
    },
};
</script>

<template>
    <v-dialog ref="dialog" v-model="state" :return-value.sync="date" width="290px">
        <template #activator="{ on, attrs }">
            <v-combobox
                v-if="multiple"
                :value="displayDate"
                multiple
                readonly
                :disabled="disabled"
                :label="label"
                append-icon=""
                v-bind="attrs"
                v-on="on"
                @focus="state = true"
                @keydown.tab="tab"
            />
            <v-text-field
                v-else
                :value="displayDate"
                :label="label"
                readonly
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
                @focus="state = true"
                @keydown.tab="tab"
            ></v-text-field>
        </template>
        <v-date-picker v-model="date" scrollable first-day-of-week="1" :multiple="multiple" @click:date="save">
            <v-btn small width="100%" style="margin-top: -0.5rem" @click="reset">reset</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<style lang="scss" scoped></style>
