var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission('file.upload') || _vm.matchPermission('file.upload.*') || _vm.matchPermission('file.create.*'))?_c('v-menu',{attrs:{"tile":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs,"isActive":_vm.state})]}}],null,true),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-list',[(_vm.hasPermission('file.upload'))?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-upload")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Datei hochladen")])],1)],1)]}}],null,false,346531301)}):_vm._e(),(_vm.hasPermission('file.upload.Administrativ'))?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"Administrativ","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shield-crown-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Administrativ hochladen")])],1)],1)]}}],null,false,4061713434)}):_vm._e(),(_vm.hasPermission('file.upload.AVOR Bilder') && !_vm.hasPermission('file.upload.Bilder') && !_vm.isMaterial)?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"AVOR Bilder","accept":"image/png,image/jpeg","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-camera-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("AVOR-Bild hochladen")])],1)],1)]}}],null,false,3803260139)}):(_vm.hasPermission('file.upload.Bilder') && !_vm.hasPermission('file.upload.AVOR Bilder') && !_vm.isMaterial)?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"Bilder","accept":"image/png,image/jpeg","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-camera-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Bild hochladen")])],1)],1)]}}],null,false,2179613356)}):(_vm.hasPermission('file.upload.AVOR Bilder') && _vm.hasPermission('file.upload.Bilder') && !_vm.isMaterial)?_c('v-list-group',{attrs:{"prepend-icon":"mdi-camera-outline","no-action":""},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Bilder hochladen")])]},proxy:true}],null,false,1542519069)},[_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"AVOR Bilder","accept":"image/png,image/jpeg","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("AVOR-Bild hochladen")])],1)],1)]}}],null,false,32534826)}),_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"Bilder","accept":"image/png,image/jpeg","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Bild hochladen")])],1)],1)]}}],null,false,675289069)})],1):_vm._e(),(_vm.hasPermission('file.upload.Dispo') && !_vm.isMaterial)?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"Dispo","accept":"application/pdf","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-floor-plan")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dispo hochladen")])],1)],1)]}}],null,false,478044412)}):_vm._e(),((_vm.hasPermission('file.upload.Schema') || _vm.hasPermission('file.upload.Schema Revision')) && !_vm.isMaterial && !_vm.isEmptySystem)?_c('v-list-group',{attrs:{"prepend-icon":"mdi-transit-connection-variant","no-action":""},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Schema hochladen")])]},proxy:true}],null,false,2207589112)},[(_vm.hasPermission('file.upload.Schema') && !_vm.isMaterial && !_vm.isEmptySystem)?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"Schema","accept":"application/pdf","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Schema hochladen")])],1)],1)]}}],null,false,3115701247)}):_vm._e(),(_vm.hasPermission('file.upload.Schema Revision') && !_vm.isMaterial && !_vm.isEmptySystem)?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"Schema Revision","accept":"application/pdf","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Revision hochladen")])],1)],1)]}}],null,false,387721469)}):_vm._e()],1):_vm._e(),(_vm.hasPermission('file.upload.Prüfbericht') && !_vm.isService && !_vm.isMaterial && !_vm.isEmptySystem)?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"Prüfbericht","accept":"application/pdf","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-certificate-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Messprotokoll hochladen")])],1)],1)]}}],null,false,2032902354)}):_vm._e(),(_vm.hasPermission('file.upload.Lieferschein') && !_vm.isService)?_c('FileUpload',{attrs:{"path":("/contract/" + (_vm.contract.contract_id) + "/file"),"type":"Lieferschein","accept":"application/pdf","multiple":""},on:{"upload":function($event){return _vm.$emit('update:files')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Lieferschein hochladen")])],1)],1)]}}],null,false,72538618)}):_vm._e(),(!_vm.rapport && _vm.hasPermission('file.create.Rapport') && _vm.isService)?_c('v-list-item',{on:{"click":_vm.createRapport}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clipboard-text-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Neuer Rapport")])],1)],1):_vm._e(),(!_vm.legend && _vm.hasPermission('file.create.Legende') && !_vm.isService && !_vm.isMaterial && !_vm.isEmptySystem)?_c('v-list-item',{on:{"click":_vm.createLegend}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-table")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Neue Legende")])],1)],1):_vm._e(),(!_vm.formular && _vm.hasPermission('formular.create') && !_vm.isService && !_vm.isMaterial && !_vm.isEmptySystem)?_c('v-list-item',{on:{"click":_vm.createFormular}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-question")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Neues Schnittstellenprotokoll")])],1)],1):_vm._e(),(_vm.hasPermission('file.upload') && _vm.hasPermission('file.import') && _vm.templates.length > 0)?_c('v-list-group',{attrs:{"prepend-icon":"mdi-paperclip","no-action":""},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Vorlage importieren")])]},proxy:true}],null,false,2826238539)},_vm._l((_vm.templates),function(template){return _c('v-list-item',{key:template.name,on:{"click":function($event){return _vm.importTemplate(template)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(template.name))])],1)],1)}),1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }