<script>
import { mapState, mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";
import FileSystem from "@components/FileSystem.vue";

export default {
    name: "DocumentSendDialogComponent",
    components: {
        DialogLayout,
        FileSystem,
    },
    props: {
        contract: { type: Object, required: true },
        files: { type: Array, default: () => [] },
        formular: { type: Object, default: undefined },
        rapport: { type: Object, default: undefined },
        legend: { type: Object, default: undefined },
    },
    data() {
        return {
            template: undefined,
            text: "",
            sendFiles: [],
        };
    },
    computed: {
        ...mapState({
            account: (state) => state.auth.account,
        }),
    },
    watch: {
        template: {
            deep: true,
            handler(value, previous) {
                if (!value) return;
                if (previous && value.name != previous.name) this.$refs.filesystem.resetSelection();

                this.text = value.text || "";
                this.text = this.processText(this.text);

                if (Array.isArray(value.files)) value.files.forEach((file) => this.$refs.filesystem.selectFile(file));
            },
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        close(action) {
            action();
            this.$emit("close");
        },
        reset() {
            this.template = undefined;
            this.text = "";
            this.sendFiles = [];
        },
        mailFilter(item) {
            if (Array.isArray(item.children)) return item.children.length > 0;
            else return item.canSend();
        },
        mailMap(item) {
            if (Array.isArray(item.children)) return { ...item, children: item.children.filter((file) => file.canSend()) };
            else return item;
        },
        processText(text) {
            const dataBundle = {
                ...this.contract,
                ...this.contract?.data,
                ...this.formular?.data,
            };
            delete dataBundle.data;

            const condition_reg = /{{([\w\-_]+)(==|!=|>|<)([\wäöüÄÖÜ\-_ ]+)\?([\wäöüÄÖÜ\-_ ]*):([\wäöüÄÖÜ\-_ ]*)}}/g;
            const variable_reg = /{{([a-zA-Z-_]+)}}/g;

            var variable_match = text.matchAll(variable_reg);
            for (const match of variable_match) text = text.replaceAll(match[0], dataBundle[match[1]] || "");

            var condition_match = text.matchAll(condition_reg);
            for (const match of condition_match) {
                let condition = undefined;
                if (match[2] == "==") condition = dataBundle[match[1]] == match[3];
                if (match[2] == "!=") condition = dataBundle[match[1]] != match[3];
                if (match[2] == ">") condition = parseFloat(dataBundle[match[1]]) > parseFloat(match[3]);
                if (match[2] == "<") condition = parseFloat(dataBundle[match[1]]) < parseFloat(match[3]);

                if (condition != undefined) {
                    if (condition) text = text.replaceAll(match[0], match[4]);
                    else text = text.replaceAll(match[0], match[5]);
                }
            }
            return text;
        },
        async save() {
            const id_list = [];
            for (const file of this.sendFiles) {
                const id = file.send();
                if (typeof id === "object" && typeof id.then === "function") id_list.push(await id);
                else id_list.push(id);
            }

            const subject = encodeURIComponent(
                this.processText(this.template?.subject || "{{contract_name}}")
                    .replaceAll(",", "")
                    .replaceAll("(", "")
                    .replaceAll(")", "")
            );
            const body = encodeURIComponent(this.text);

            window.open(
                `dfutura://mail/${id_list.join("/")}?to=${this.template?.to || this.contract.email}&subject=${subject}&body=${body}`,
                "_self"
            );
        },
    },
};
</script>

<template>
    <DialogLayout title="Dokumente versenden" width="800" @close="reset">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-select v-model="template" :items="account.templates" item-text="name" return-object label="Email Vorlage" />
            <v-textarea v-model="text" rows="8" label="Email Text" />
            <FileSystem
                ref="filesystem"
                v-model="sendFiles"
                :contract="contract"
                :files="files"
                :formular="formular"
                :rapport="rapport"
                :legend="legend"
                selectable
                csv
                :filter="mailFilter"
                :map="mailMap"
            />
        </template>
        <template #action="{ action }">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-btn color="primary" text @click="save()"><v-icon left>mdi-microsoft-outlook</v-icon>Outlook öffnen</v-btn>
        </template>
    </DialogLayout>
</template>
