<script>
import { mapState, mapMutations, mapActions } from "vuex";

import Layout from "@layouts/main.layout.vue";
import HomeLayout from "@layouts/home.layout.vue";
import ContractTile from "@components/home/ContractTile.vue";
import TextField from "@components/TextField.vue";
import ContractEmptyState from "@components/empty/ContractEmptyState.vue";

export default {
    name: "HomeView",
    components: {
        Layout,
        HomeLayout,
        ContractTile,
        TextField,
        ContractEmptyState,
    },
    data() {
        return {
            search: undefined,
        };
    },
    computed: {
        ...mapState({
            query: (state) => state.contract.query,
            contracts: (state) => state.contract.contracts,
            history: (state) => state.contract.history,
            loadMore: (state) => state.contract.loadMore,
            loading: (state) => state.contract.loading,
        }),
        showHistory() {
            return this.query == undefined || this.query.length == 0;
        },
    },
    mounted() {
        if (this.history.length == 0) this.queryHistory();
    },
    methods: {
        ...mapMutations({
            logWarning: "ui/warning",
            logError: "ui/error",
        }),
        ...mapActions({
            queryContracts: "contract/queryContracts",
            queryHistory: "contract/queryHistory",
            touchContract: "contract/touchContract",
        }),
    },
};
</script>

<template>
    <Layout>
        <template #navbar.append>
            <TextField v-model="search" icon="mdi-magnify" width="min(100%, 300px)" rounded placeholder="Suche" @submit="queryContracts(search)" />
        </template>
        <template #default>
            <HomeLayout :title="showHistory ? 'Zuletzt gesucht:' : `Ergebnis: '${query}'`">
                <template #default>
                    <!-- History -->
                    <template v-if="showHistory">
                        <ContractTile
                            v-for="(contract, index) in history"
                            :key="index"
                            :value="contract"
                            @click="touchContract(contract.contract_id)"
                        />
                        <ContractEmptyState v-if="history.length == 0" />
                        <v-progress-circular v-if="loading" indeterminate class="mx-auto" />
                    </template>
                    <!-- Search Results -->
                    <template v-else>
                        <ContractTile
                            v-for="(contract, index) in contracts"
                            :key="index"
                            :value="contract"
                            @click="touchContract(contract.contract_id)"
                        />
                        <ContractEmptyState v-if="contracts.length == 0" />
                        <v-progress-circular v-if="loading" indeterminate class="mx-auto" />
                        <div v-if="loadMore" class="load-more" @click="queryContracts(search)">Mehr Laden</div>
                    </template>
                </template>
            </HomeLayout>
        </template>
    </Layout>
</template>

<style lang="scss" scoped>
.load-more {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: $box-shadow-1;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.08);
    }
}
</style>
