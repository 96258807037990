<script>
import { mapMutations } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "CustomerNormDialogComponent",
    components: {
        DialogLayout,
    },
    data() {
        return {
            form: false,
            customers: undefined,
            customer: undefined,
            search: null,
            text: undefined,
            requiredRule: [(v) => !!v || "Eingabe ist erforderlich"],
            valideRule: [(v) => (!!v && !!v.customer_id) || "Ungültiger Kunde"],
        };
    },
    watch: {
        search(value) {
            if (!value) return;
            this.axios.get("/customer?query=" + value).then((res) => (this.customers = res.data));
        },
    },
    methods: {
        ...mapMutations({
            logMessage: "ui/log",
            logError: "ui/error",
        }),
        close(action) {
            action();
            this.customers = undefined;
            this.customer = undefined;
            this.text = undefined;
            this.$emit("close");
        },
        submit(action) {
            this.axios
                .get("/customer/" + this.customer.customer_id + "/norms")
                .then(async (res) => {
                    this.axios
                        .post("/customer/" + this.customer.customer_id + "/norms", [...res.data, { text: this.text }])
                        .then(() => {
                            this.logMessage("Norm wurde hinzugefügt");
                            this.$emit("update");
                            this.close(action);
                        })
                        .catch(() => this.logError("Es ist ein fehler aufgetreten"));
                })
                .catch(() => this.logError("Es ist ein fehler aufgetreten"));
        },
    },
};
</script>

<template>
    <DialogLayout title="Kunden-Norm hinzufügen" width="500">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <v-form v-model="form" class="norm-form">
                <v-autocomplete
                    v-model="customer"
                    :rules="[...requiredRule, ...valideRule]"
                    label="Kunde"
                    :items="customers"
                    item-text="company"
                    return-object
                    :search-input.sync="search"
                />
                <v-textarea v-model="text" :rules="requiredRule" label="Norm-Text" />
            </v-form>
        </template>
        <template #action="{ action }">
            <v-btn color="error" text @click="close(action)">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="!form" @click="submit(action)">Speichern</v-btn>
        </template>
    </DialogLayout>
</template>

<style lang="scss">
.v-autocomplete__content.v-menu__content::-webkit-scrollbar-track {
    background: white !important;
}
</style>
