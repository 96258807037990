<script>
export default {
    name: "ImageZoomComponent",
    props: {
        src: { type: String, default: undefined },
        context: { type: Array, default: () => [] },
    },
    data() {
        return {
            index: 0,
            image: undefined,
            width: undefined,
            height: undefined,
            contentWidth: undefined,
            contentHeight: undefined,
            scale: 1.01,
            positionX: 50,
            positionY: 50,
            tempX: undefined,
            tempY: undefined,
        };
    },
    computed: {
        verticalOverflow() {
            const local = this.width / this.height;
            const remote = this.contentWidth / this.contentHeight;
            return local > remote;
        },
        backgroundSize() {
            return this.verticalOverflow ? `auto ${this.scale * 100}%` : `${this.scale * 100}% auto`;
        },
    },
    watch: {
        src: {
            immediate: true,
            handler(src) {
                if (!src) return;
                this.index = this.context.indexOf(src);
                this.fetchImage(src);
            },
        },
        index(value) {
            this.fetchImage(this.context[value]);
        },
    },
    mounted() {
        new ResizeObserver(() => {
            if (!this.$refs?.container) return;
            this.width = this.$refs.container.clientWidth;
            this.height = this.$refs.container.clientHeight;
        }).observe(this.$refs.container);

        document.addEventListener("mouseup", () => this.dragEnd());
    },
    methods: {
        async fetchImage(id) {
            const blob = await this.axios.get("/media/" + id, { responseType: "blob" });
            this.image = window.URL.createObjectURL(blob.data);

            const img = document.createElement("img");
            img.onload = () => {
                this.contentWidth = img.width;
                this.contentHeight = img.height;
            };
            img.src = this.image;
        },
        zoom(event) {
            if (event.deltaY > 0) this.scale -= 1;
            if (event.deltaY < 0) this.scale += 1;
            if (this.scale < 1.1) this.scale = 1.01;
            if (this.scale > 10) this.scale = 10;
        },
        dragStart(event) {
            if (event.touches?.length > 1) return;
            this.tempX = event.clientX || event.touches[0]?.clientX;
            this.tempY = event.clientY || event.touches[0]?.clientY;
        },
        drag(event) {
            if (!this.tempX || !this.tempY) return;
            if (event.touches?.length > 1) return;
            const currentX = event.clientX || event.touches[0]?.clientX;
            const currentY = event.clientY || event.touches[0]?.clientY;
            const offsetX = ((100 / this.width) * (currentX - this.tempX)) / this.scale;
            const offsetY = ((100 / this.height) * (currentY - this.tempY)) / this.scale;

            const sizeFactor = this.verticalOverflow ? this.contentHeight / this.height : this.contentWidth / this.width;
            const virtualMaxSize = this.verticalOverflow ? this.width * sizeFactor : this.height * sizeFactor;
            const virtualSize = this.verticalOverflow ? this.contentWidth * this.scale : this.contentHeight * this.scale;
            const inverted = virtualSize < virtualMaxSize;

            if (this.verticalOverflow && inverted) {
                this.positionX += offsetX;
                this.positionY -= offsetY;
            } else if (!this.verticalOverflow && inverted) {
                this.positionX -= offsetX;
                this.positionY += offsetY;
            } else {
                this.positionX -= offsetX;
                this.positionY -= offsetY;
            }

            if (this.positionX > 100) this.positionX = 100;
            if (this.positionX < 0) this.positionX = 0;
            if (this.positionY > 100) this.positionY = 100;
            if (this.positionY < 0) this.positionY = 0;

            this.tempX = currentX;
            this.tempY = currentY;
        },
        dragEnd() {
            this.tempX = undefined;
            this.tempY = undefined;
        },
        previous() {
            if (this.index <= 0) this.index = this.context.length - 1;
            else this.index--;
        },
        next() {
            if (this.index >= this.context.length - 1) this.index = 0;
            else this.index++;
        },
    },
};
</script>

<template>
    <div
        ref="container"
        class="img-container"
        :style="{
            'background-image': image ? `url(${image})` : 'unset',
            'background-size': backgroundSize,
            'background-position': `${positionX}% ${positionY}%`,
        }"
        @wheel="zoom"
        @mousedown="dragStart"
        @mousemove="drag"
        @touchstart="dragStart"
        @touchmove="drag"
        @touchend="dragEnd"
    >
        <div v-ripple class="prev-button" @click="previous" @mousedown.stop @touchstart.stop><v-icon color="white">mdi-chevron-left</v-icon></div>
        <div v-ripple class="next-button" @click="next" @mousedown.stop @touchstart.stop><v-icon color="white">mdi-chevron-right</v-icon></div>
    </div>
</template>

<style lang="scss" scoped>
.img-container {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    user-select: none;
    position: relative;

    .prev-button,
    .next-button {
        position: absolute;
        background: rgba(20, 20, 20, 0.8);
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        height: 8rem;
        cursor: pointer;
    }

    .prev-button {
        left: 0;
    }

    .next-button {
        right: 0;
    }
}
</style>
