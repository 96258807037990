import { render, staticRenderFns } from "./NavGroup.vue?vue&type=template&id=43173706&scoped=true&"
import script from "./NavGroup.vue?vue&type=script&lang=js&"
export * from "./NavGroup.vue?vue&type=script&lang=js&"
import style0 from "./NavGroup.vue?vue&type=style&index=0&id=43173706&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43173706",
  null
  
)

export default component.exports