<script>
import { mapState } from "vuex";

import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "JournalDialogComponent",
    components: {
        DialogLayout,
    },
    props: {
        journal: { type: Object, required: true },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        ...mapState({
            users: (state) => state.user.users,
        }),
        origin() {
            return this.users.find((user) => user.id == this.journal.origin)?.data?.long || this.journal.origin;
        },
    },
    methods: {
        getKeys(change) {
            const unique = new Set();
            if (change.previous && typeof change.previous == "object") Object.keys(change.previous).forEach((key) => unique.add(key));
            if (change.current && typeof change.current == "object") Object.keys(change.current).forEach((key) => unique.add(key));
            return unique;
        },
        compare(prev, curr) {
            if (typeof prev != typeof curr) return false;
            if (typeof prev == "object") return JSON.stringify(prev) == JSON.stringify(curr);
            return prev == curr;
        },
        processValue(value) {
            if (value == undefined) return "";
            if (typeof value == "boolean") return value ? "Ja" : "Nein";
            if (Array.isArray(value)) return value.join(", ");
            return value;
        },
        translateKey(key) {
            if (key == "access") return "Zugänglichkeit";
            if (key == "system_design") return "Äussere Bauform";
            if (key == "rated_voltage") return "Bemessungsspannung";
            if (key == "rated_current") return "Bemessungsstrom";
            if (key == "main_fuse_location") return "Vorsicherung innerhalb der Verteilung";
            if (key == "main_fuse_type") return "Vorsicherungstyp";
            if (key == "main_fuse_size") return "Vorsicherungsgröse";
            if (key == "short_circuit_resistance_simple") return "Ipk oder Icp";
            if (key == "ipk") return "Ipk";
            if (key == "icp") return "Icp";
            if (key == "short_circuit_resistance_complex") return "Icc oder Icw";
            if (key == "icc") return "Icc";
            if (key == "icw") return "Icw";
            if (key == "icw_time") return "Icw Zeit";
            if (key == "fuse_amount") return "Bemessungsbelastungsfaktor";
            if (key == "mounting_location") return "Montageort";
            if (key == "low_voltage_grid") return "EMV Verträglichkeit";
            if (key == "halogenfree") return "Halogenfrei";
            if (key == "temperature") return "Hohe Temperatur";
            if (key == "humidity") return "Hohe Luftfeuchtigkeit";
            if (key == "altitude") return "Hohe Höhenlage";
            if (key == "temp_hum_change") return "Temperatur / Luftfeuchtigkeitsänderung";
            if (key == "dust") return "staubig";
            if (key == "magnetic_field") return "magnetische Felder";
            if (key == "explosive") return "explosionsgefahr";
            if (key == "copper_system") return "innere Unterteilung";
            if (key == "protection") return "Schutzart";
            if (key == "ip_protection") return "IP-Schutzart";
            if (key == "fuse_change") return "Sicherungen austauschbar";
            if (key == "width") return "Breite";
            if (key == "height") return "Höhe";
            if (key == "depth") return "Tiefe";
            if (key == "transportable") return "transportierbar";
            if (key == "transport_conditions") return "Transportinfos";
            if (key == "assembly") return "Montage";
            if (key == "power_station") return "EVU";
            if (key == "customer_request") return "Kundenwünsche";

            if (key == "contract_type") return "Auftrafstyp";
            if (key == "customer_contacted") return "Kunde Kontaktiert";
            if (key == "transport") return "Lieferung";
            if (key == "carrier") return "Lieferant";
            if (key == "call_early") return "Vorgängig Anrufen";
            if (key == "call_early_notes") return "Anruf Anmerkungen";
            if (key == "transport_date") return "Lieferdatum";
            if (key == "transport_time") return "Lieferzeit";
            if (key == "transport_location_alt") return "Lieferadresse abweichend";
            if (key == "electrician_on_site") return "Ansprechpartner Vorort";
            if (key == "electrician_on_site_phone") return "Ansprechpartner Telefon";
            if (key == "site_street") return "Lieferadresse Strasse";
            if (key == "site_plz") return "Lieferadresse Plz";
            if (key == "site_city") return "Lieferadresse Ort";
            if (key == "installer") return "Installateur";
            if (key == "different_install_time") return "Abweichende Montagezeit";
            if (key == "install_date") return "Montagedatum";
            if (key == "install_time") return "Montagezeit";
            if (key == "installer_amount") return "Anzahl Monteure";
            if (key == "transport_notes") return "Transport Notizen";
            if (key == "monteur") return "Erbauer";
            if (key == "system_amount") return "Anlageteile";
            if (key == "schema_id") return "Schemanummer";
            if (key == "socket") return "Sockel";
            if (key == "devices_missing") return "Ausstehende Geräte";
            if (key == "devices_returned") return "Überschüssige Geräte";
            if (key == "assembly_notes") return "Montage Notizen";

            if (key == "manufacturer_sign") return "Verantwortlich";
            return key;
        },
        isObject(value) {
            return typeof value == "object";
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" width="auto" :title="journal.title">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #content>
            <div class="main">
                <div class="entry">
                    <div class="key">Beschreibung</div>
                    <div class="data">{{ journal.description }}</div>
                </div>
                <div class="entry">
                    <div class="key">Zeitstempel</div>
                    <div class="data">{{ journal.timestamp | moment("calendar") }}</div>
                </div>
                <div v-if="origin" class="entry">
                    <div class="key">Urheber</div>
                    <div class="data">{{ origin }}</div>
                </div>
                <template v-if="journal.change && (journal.change.previous || journal.change.current)">
                    <template v-if="!isObject(journal.change.previous)">
                        <div class="entry">
                            <div class="key">Von</div>
                            <div class="data code">{{ journal.change.previous }}</div>
                        </div>
                        <div class="entry">
                            <div class="key">Zu</div>
                            <div class="data code">{{ journal.change.current }}</div>
                        </div>
                    </template>
                    <table v-else-if="getKeys(journal.change).size > 0">
                        <tr>
                            <th>Wert</th>
                            <th>Von</th>
                            <th>Zu</th>
                        </tr>
                        <template v-for="(key, index) in Object.keys(journal.change.current)">
                            <template v-if="journal.change.previous">
                                <template v-if="!compare(journal.change.previous[key], journal.change.current[key])">
                                    <tr :key="index">
                                        <td style="font-style: italic">{{ translateKey(key) }}</td>
                                        <td>{{ journal.change.previous ? processValue(journal.change.previous[key]) : "" }}</td>
                                        <td>{{ journal.change.current ? processValue(journal.change.current[key]) : "" }}</td>
                                    </tr>
                                </template>
                            </template>
                            <template v-else-if="journal.change.current">
                                <template v-if="journal.change.current[key]">
                                    <tr :key="index">
                                        <td style="font-style: italic">{{ translateKey(key) }}</td>
                                        <td></td>
                                        <td>{{ journal.change.current ? processValue(journal.change.current[key]) : "" }}</td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </table>
                </template>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.main {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 80vh;
    min-width: 25rem;
    overflow-y: auto;

    .entry {
        .key {
            font-size: 12px;
            font-weight: bold;
        }

        .data {
            font-size: 14px;
        }

        .data.code {
            padding: 5px 10px;
            border: 1px dotted #ccc;
            min-height: 2rem;
            white-space: pre-wrap;
        }
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        font-size: 14px;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 5px;
    }

    tr:nth-child(even) {
        background-color: rgb(240, 240, 240);
    }
}
</style>
