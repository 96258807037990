<script>
import { mapMutations } from "vuex";

import FileUpload from "@components/FileUpload.vue";

import permissionMixin from "@mixin/permission.mixin";

export default {
    name: "FileMenuComponent",
    components: {
        FileUpload,
    },
    mixins: [permissionMixin],
    props: {
        contract: { type: Object, required: true },
        rapport: { type: Object, default: undefined },
        legend: { type: Object, default: undefined },
        formular: { type: Object, default: undefined },
        templates: { type: Array, default: () => [] },
    },
    data() {
        return {
            state: false,
        };
    },
    computed: {
        isService() {
            if (this.contract?.data?.service) return true;
            return this.contract?.data?.contract_type == "service";
        },
        isMaterial() {
            return this.contract?.data?.contract_type == "material";
        },
        isEmptySystem() {
            return this.contract?.data?.contract_type == "empty_system";
        },
    },
    methods: {
        ...mapMutations({
            logError: "ui/error",
            logMessage: "ui/log",
        }),
        createLegend() {
            this.axios
                .put("/contract/" + this.contract.contract_id + "/legend")
                .then(() => {
                    this.logMessage("Legende wurde erstellt");
                    this.$emit("create:legend");
                })
                .catch(() => this.logError("Legende konnte nicht erstellt werden"));
        },
        createFormular() {
            this.axios
                .put("/contract/" + this.contract.contract_id + "/formular")
                .then(() => {
                    this.logMessage("Schnittstellenprotokoll wurde erstellt");
                    this.$emit("create:formular");
                })
                .catch(() => this.logError("Beim erstellen des Formulars ist ein fehler aufgetreten"));
        },
        createRapport() {
            this.axios
                .put("/contract/" + this.contract.contract_id + "/rapport")
                .then(() => {
                    this.logMessage("Rapport wurde erstellt");
                    this.$emit("create:rapport");
                })
                .catch(() => this.logError("Rapport konnte nicht erstellt werden"));
        },
        importTemplate(template) {
            this.axios
                .put("/template/" + this.contract.contract_id, { file: template.name })
                .then(() => this.$emit("update:files"))
                .catch(() => this.logError("Vorlage konnte nicht importiert werden"));
        },
    },
};
</script>

<template>
    <v-menu v-if="hasPermission('file.upload') || matchPermission('file.upload.*') || matchPermission('file.create.*')" v-model="state" tile offset-y>
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" :isActive="state" />
        </template>
        <v-list>
            <FileUpload v-if="hasPermission('file.upload')" :path="`/contract/${contract.contract_id}/file`" multiple @upload="$emit('update:files')">
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-upload</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Datei hochladen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </FileUpload>
            <FileUpload
                v-if="hasPermission('file.upload.Administrativ')"
                :path="`/contract/${contract.contract_id}/file`"
                type="Administrativ"
                multiple
                @upload="$emit('update:files')"
            >
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-shield-crown-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Administrativ hochladen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </FileUpload>
            <!-- Image Upload -->
            <FileUpload
                v-if="hasPermission('file.upload.AVOR Bilder') && !hasPermission('file.upload.Bilder') && !isMaterial"
                :path="`/contract/${contract.contract_id}/file`"
                type="AVOR Bilder"
                accept="image/png,image/jpeg"
                multiple
                @upload="$emit('update:files')"
            >
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-camera-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>AVOR-Bild hochladen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </FileUpload>
            <FileUpload
                v-else-if="hasPermission('file.upload.Bilder') && !hasPermission('file.upload.AVOR Bilder') && !isMaterial"
                :path="`/contract/${contract.contract_id}/file`"
                type="Bilder"
                accept="image/png,image/jpeg"
                multiple
                @upload="$emit('update:files')"
            >
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-camera-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Bild hochladen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </FileUpload>
            <v-list-group
                v-else-if="hasPermission('file.upload.AVOR Bilder') && hasPermission('file.upload.Bilder') && !isMaterial"
                prepend-icon="mdi-camera-outline"
                no-action
                @click.stop
            >
                <template #activator>
                    <v-list-item-title>Bilder hochladen</v-list-item-title>
                </template>

                <FileUpload
                    :path="`/contract/${contract.contract_id}/file`"
                    type="AVOR Bilder"
                    accept="image/png,image/jpeg"
                    multiple
                    @upload="$emit('update:files')"
                >
                    <template #activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-icon />
                            <v-list-item-content>
                                <v-list-item-title>AVOR-Bild hochladen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </FileUpload>
                <FileUpload
                    :path="`/contract/${contract.contract_id}/file`"
                    type="Bilder"
                    accept="image/png,image/jpeg"
                    multiple
                    @upload="$emit('update:files')"
                >
                    <template #activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-icon />
                            <v-list-item-content>
                                <v-list-item-title>Bild hochladen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </FileUpload>
            </v-list-group>
            <FileUpload
                v-if="hasPermission('file.upload.Dispo') && !isMaterial"
                :path="`/contract/${contract.contract_id}/file`"
                type="Dispo"
                accept="application/pdf"
                multiple
                @upload="$emit('update:files')"
            >
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-floor-plan</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Dispo hochladen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </FileUpload>
            <v-list-group
                v-if="(hasPermission('file.upload.Schema') || hasPermission('file.upload.Schema Revision')) && !isMaterial && !isEmptySystem"
                prepend-icon="mdi-transit-connection-variant"
                no-action
                @click.stop
            >
                <template #activator>
                    <v-list-item-title>Schema hochladen</v-list-item-title>
                </template>

                <FileUpload
                    v-if="hasPermission('file.upload.Schema') && !isMaterial && !isEmptySystem"
                    :path="`/contract/${contract.contract_id}/file`"
                    type="Schema"
                    accept="application/pdf"
                    multiple
                    @upload="$emit('update:files')"
                >
                    <template #activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-icon />
                            <v-list-item-content>
                                <v-list-item-title>Schema hochladen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </FileUpload>
                <FileUpload
                    v-if="hasPermission('file.upload.Schema Revision') && !isMaterial && !isEmptySystem"
                    :path="`/contract/${contract.contract_id}/file`"
                    type="Schema Revision"
                    accept="application/pdf"
                    multiple
                    @upload="$emit('update:files')"
                >
                    <template #activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-icon />
                            <v-list-item-content>
                                <v-list-item-title>Revision hochladen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </FileUpload>
            </v-list-group>
            <FileUpload
                v-if="hasPermission('file.upload.Prüfbericht') && !isService && !isMaterial && !isEmptySystem"
                :path="`/contract/${contract.contract_id}/file`"
                type="Prüfbericht"
                accept="application/pdf"
                multiple
                @upload="$emit('update:files')"
            >
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-file-certificate-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Messprotokoll hochladen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </FileUpload>
            <FileUpload
                v-if="hasPermission('file.upload.Lieferschein') && !isService"
                :path="`/contract/${contract.contract_id}/file`"
                type="Lieferschein"
                accept="application/pdf"
                multiple
                @upload="$emit('update:files')"
            >
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                            <v-icon>mdi-format-list-bulleted-square</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Lieferschein hochladen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </FileUpload>
            <v-list-item v-if="!rapport && hasPermission('file.create.Rapport') && isService" @click="createRapport">
                <v-list-item-icon>
                    <v-icon>mdi-clipboard-text-clock</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Neuer Rapport</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!legend && hasPermission('file.create.Legende') && !isService && !isMaterial && !isEmptySystem" @click="createLegend">
                <v-list-item-icon>
                    <v-icon>mdi-file-table</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Neue Legende</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!formular && hasPermission('formular.create') && !isService && !isMaterial && !isEmptySystem" @click="createFormular">
                <v-list-item-icon>
                    <v-icon>mdi-file-question</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Neues Schnittstellenprotokoll</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-group
                v-if="hasPermission('file.upload') && hasPermission('file.import') && templates.length > 0"
                prepend-icon="mdi-paperclip"
                no-action
                @click.stop
            >
                <template #activator>
                    <v-list-item-title>Vorlage importieren</v-list-item-title>
                </template>
                <v-list-item v-for="template in templates" :key="template.name" @click="importTemplate(template)">
                    <v-list-item-content>
                        <v-list-item-title>{{ template.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped></style>
