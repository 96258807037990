var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-layout"},[_c('div',{staticClass:"navbar"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('img',{attrs:{"src":require("@assets/Fallegger_white.png")},on:{"click":navigate}})]}}])}),_c('div',{staticClass:"nav"},[_c('NavButton',{attrs:{"icon":"mdi-briefcase","label":"Aufträge","to":"/"}}),(_vm.isInGroup('avor'))?_c('NavButton',{attrs:{"icon":"mdi-calendar","label":"Kalender","to":"/calendar"}}):_vm._e(),_c('NavButton',{attrs:{"icon":"mdi-gavel","label":"Normen","to":"/norms"}}),(_vm.isInGroup('avor') || _vm.isInGroup('inspector'))?_c('NavButton',{attrs:{"icon":"mdi-magnify","label":"Filter","to":"/filter"}}):_vm._e(),_vm._t("navbar")],2),_c('div',{staticClass:"nav-mini"},[_c('NavMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var isActive = ref.isActive;
return [_c('NavItem',_vm._g(_vm._b({attrs:{"active":isActive}},'NavItem',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-menu")])],1)]}}])}),_vm._t("navbar.mini")],2),_c('v-spacer'),_c('div',{staticClass:"append"},[_vm._t("navbar.append"),_c('NotificationMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var items = ref.items;
var isActive = ref.isActive;
return [_c('NavItem',_vm._g(_vm._b({attrs:{"active":isActive}},'NavItem',attrs,false),on),[_c('v-badge',{attrs:{"overlap":"","color":"error","value":items.length > 0,"content":items.length}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-bell")])],1)],1)]}}])}),_c('AccountMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var isActive = ref.isActive;
return [_c('NavItem',_vm._g(_vm._b({attrs:{"active":isActive}},'NavItem',attrs,false),on),[_c('v-avatar',{attrs:{"color":"white","size":"40"}},[_vm._v(_vm._s(_vm.avatarText))])],1)]}}])})],2)],1),(!_vm.isLoading)?_c('div',{staticClass:"content"},[_vm._t("default")],2):_c('Loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }