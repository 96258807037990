<script>
export default {
    name: "TimePickerComponent",
    props: {
        value: { type: String, default: undefined },
        label: { type: String, default: undefined },
        disabled: { type: Boolean, default: undefined },
    },
    data() {
        return {
            state: false,
            time: null,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.time = value;
            },
        },
        time: {
            handler(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        reset() {
            this.time = null;
            this.$refs.dialog.save(null);
        },
    },
};
</script>

<template>
    <v-dialog ref="dialog" v-model="state" :return-value.sync="time" width="290px">
        <template #activator="{ on, attrs }">
            <v-text-field v-model="time" :disabled="disabled" :label="label" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-time-picker v-model="time" scrollable format="24hr" @click:minute="$refs.dialog.save(time)">
            <v-btn small width="100%" @click="reset">reset</v-btn>
        </v-time-picker>
    </v-dialog>
</template>

<style lang="scss" scoped></style>
