<script>
import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "DocumentDialogComponent",
    components: {
        DialogLayout,
    },
    data() {
        return {
            dialog: false,
            document: undefined,
            width: 0,
        };
    },
    mounted() {
        this.width = window.innerWidth;
        window.onresize = () => (this.width = window.innerWidth);
    },
    methods: {
        open(template, data, doctype) {
            this.dialog = true;
            this.axios
                .post("/document/" + template + "/pdf" + (doctype ? `?doctype=${doctype}` : ""), data, { responseType: "blob" })
                .then((result) => (this.document = URL.createObjectURL(result.data)));
        },
        close() {
            this.dialog = false;
            this.document = undefined;
            this.$emit("close");
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" :fullscreen="width < 900" width="auto">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #content>
            <div class="document-viewer">
                <div class="viewer-content">
                    <!-- <object v-if="document" :data="document" type="application/pdf"></object> -->
                    <object v-if="document" :data="document" type="image/png"></object>
                    <div v-else class="loader"><v-progress-circular indeterminate /></div>
                </div>
                <div v-ripple class="viewer-close" @click="close">Vorschau schliessen</div>
            </div>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.document-viewer {
    overflow: hidden !important;
    width: 90vw;
    height: 90vh;
    display: grid;
    grid-template-areas:
        "content"
        "close";
    grid-template-columns: 100%;
    grid-template-rows: calc(100% - 3.5rem) 3.5rem;
    background: white;

    .viewer-content {
        grid-area: content;
        background: #525659;

        object {
            height: 100%;
            width: 100%;
        }

        .loader {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .viewer-close {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #323639;
        color: white;
        cursor: pointer;
    }
}

@media only screen and (max-width: 900px) {
    .document-viewer {
        width: 100vw;
        height: 100vh;
    }
}
</style>
