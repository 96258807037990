<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "ContractSearchMenuComponent",
    data() {
        return {
            state: false,
            width: 0,
        };
    },
    computed: {
        ...mapState({
            contracts: (state) => state.contract.contracts,
            loading: (state) => state.contract.loading,
        }),
        shortContracts() {
            if (!this.contracts || this.contracts.length == 0) return [];
            return this.contracts.slice(0, 5);
        },
    },
    mounted() {
        this.width = window.innerWidth;
        window.onresize = () => (this.width = window.innerWidth);
    },
    methods: {
        ...mapActions({
            touchContract: "contract/touchContract",
        }),
        open() {
            this.state = true;
        },
    },
};
</script>

<template>
    <v-menu v-model="state" tile offset-y :min-width="width > 450 ? '400' : '100%'" :max-width="width > 450 ? '400' : '100%'">
        <template #activator="{ attrs }">
            <slot name="activator" :on="open" :attrs="attrs" :isActive="state" />
        </template>
        <v-list>
            <router-link
                v-for="(contract, index) in shortContracts"
                :key="contract.contract_id || index"
                v-slot="{ navigate }"
                custom
                :to="`/contract/${contract.contract_id || index}`"
            >
                <v-list-item
                    @click="
                        navigate($event);
                        touchContract(contract.contract_id || index);
                    "
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ contract.company }}</v-list-item-title>
                        <v-list-item-subtitle>
                            <b>{{ contract.contract_id || index }}</b> -
                            {{ contract.contract_name }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped></style>
