import Home from "@views/Home.vue";
import Contract from "@views/Contract.vue";
import Norm from "@views/Norm.vue";
import Filter from "@views/Filter.vue";
import Calendar from "@views/Calendar.vue";

export default [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login",
        component: () => lazyLoadView(import("@views/Login.vue")),
    },
    {
        path: "/contract/:id",
        name: "Auftrag",
        component: Contract,
    },
    {
        path: "/norms",
        name: "Normen",
        component: Norm,
    },
    {
        path: "/filter",
        name: "Filter",
        component: Filter,
    },
    {
        path: "/calendar",
        name: "Kalender",
        component: Calendar,
    },
];

function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        delay: 200,
        timeout: 10000,
        loading: require("@components/_loading.vue").default,
        error: require("@components/_timeout.vue").default,
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        },
    });
}
