<script>
export default {
    name: "ContractLayout",
};
</script>

<template>
    <div class="contract-layout">
        <div class="action"><slot name="toolbar" /></div>
        <div class="item files">
            <div class="item-title">Dateien</div>
            <div class="item-content"><slot /></div>
        </div>
        <div class="item infos">
            <div class="item-title">Informationen</div>
            <div class="item-content"><slot name="info" /></div>
        </div>
        <div class="item norms">
            <div class="item-title">Normen</div>
            <div class="item-content"><slot name="norms" /></div>
        </div>
        <div class="item checklist">
            <div class="item-title">Verlauf</div>
            <div class="item-content"><slot name="checklist" /></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.contract-layout {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-areas:
        "action action action checklist"
        "files  infos  norms  checklist";
    grid-template-columns: minmax(400px, 1fr) minmax(500px, 1fr) 1fr 400px;
    grid-template-rows: $navbar-height calc(100% - $navbar-height - 1rem);
    gap: 1rem;
    overflow: hidden;

    .item {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: $navbar-height auto;

        .item-title {
            background: $primary;
            color: white;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            font-size: 20px;
        }

        .item-content {
            padding: 1rem;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .action,
    .files,
    .infos,
    .norms,
    .checklist {
        background: white;
        box-shadow: $box-shadow-1;
    }

    .action {
        grid-area: action;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        gap: 1rem;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .files {
        grid-area: files;
    }

    .infos {
        grid-area: infos;
    }

    .norms {
        grid-area: norms;

        .prefix {
            font-weight: bold;
        }
    }

    .checklist {
        grid-area: checklist;

        .item-content {
            padding: 0;
        }
    }
}

@media screen and (max-width: 1800px) {
    .contract-layout {
        grid-template-areas:
            "action action action"
            "infos  norms  checklist"
            "files  files  checklist";
        grid-template-columns: minmax(450px, 1fr) 1fr 400px;
        grid-template-rows: $navbar-height calc(100% - $navbar-height - 1rem) calc(100% + 5px);
        overflow-y: auto;
        padding-right: 2px;
    }
}

@media screen and (max-width: 1300px) {
    .contract-layout {
        grid-template-areas:
            "action action"
            "infos  checklist"
            "norms  checklist"
            "files  checklist";
        grid-template-columns: auto 400px;
        grid-template-rows: $navbar-height 1fr auto auto;
        overflow-y: auto;
    }
}

@media screen and (max-width: 850px) {
    .contract-layout {
        grid-template-areas:
            "action"
            "infos"
            "norms"
            "files"
            "checklist";
        grid-template-columns: calc(100vw - 2rem);
        grid-template-rows: $navbar-height auto auto auto auto;
    }
}
</style>
