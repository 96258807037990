<script>
import Layout from "@layouts/main.layout.vue";
import CalendarLayout from "@layouts/calendar.layout.vue";

export default {
    name: "CalendarView",
    components: {
        Layout,
        CalendarLayout,
    },
    data() {
        return {
            type: "month",
            types: [
                { key: "Monat", value: "month" },
                { key: "Woche", value: "week" },
                { key: "Tag", value: "day" },
            ],
            value: "",
            events: [],
            title: undefined,
        };
    },
    mounted() {
        this.axios
            .get("/calendar")
            .then((res) => (this.events = res.data.map((event) => ({ ...event, start: new Date(event.start), end: new Date(event.end) }))));
    },
    methods: {
        typeToLabel(type) {
            return this.types.find((item) => item.value == type)?.key;
        },
        setToday() {
            this.value = "";
        },
    },
};
</script>

<template>
    <Layout>
        <CalendarLayout>
            <template #toolbar>
                <v-toolbar>
                    <v-btn outlined class="mr-4 hide-mobile" color="grey darken-2" @click="setToday">Heute</v-btn>
                    <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()">
                        <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()">
                        <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-toolbar-title class="ml-4 hide-mobile">{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                        <template #activator="{ on, attrs }">
                            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                <span>{{ typeToLabel(type) }}</span>
                                <v-icon right> mdi-menu-down </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="item in types" :key="item.key" @click="type = item.value">
                                <v-list-item-title>{{ item.key }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
            </template>
            <template #default>
                <div class="calendar-main elevation-1">
                    <v-calendar
                        ref="calendar"
                        v-model="value"
                        class="calendar"
                        :events="events"
                        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                        :type="type"
                        locale="de-CH"
                        event-overlap-mode="column"
                        show-week
                        first-time="05:00"
                        @change="title = $refs.calendar.title"
                    >
                        <template #event="{ event }">
                            <v-tooltip bottom>
                                <template #activator="{ attrs, on }">
                                    <div
                                        class="event-item"
                                        :class="type"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="$router.push('/contract/' + event.contract)"
                                    >
                                        <b v-if="event.time">{{ event.time }}</b>
                                        <div>{{ event.name }}</div>
                                        <v-icon v-if="event.icon" small color="white">{{ event.icon }}</v-icon>
                                    </div>
                                </template>
                                <span>{{ event.name }}</span>
                            </v-tooltip>
                        </template>
                    </v-calendar>
                </div>
            </template>
        </CalendarLayout>
    </Layout>
</template>

<style lang="scss" scoped>
.calendar-main {
    height: calc(100% - 12rem);
    .calendar {
        height: auto;
        overflow: hidden;
        min-height: calc(100% - 12rem);

        .event-item {
            display: flex;
            align-items: center;
            padding: 0 5px;
            gap: 5px;

            div {
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .event-item.week,
        .event-item.day {
            flex-direction: column;
            align-items: flex-start;

            div {
                white-space: pre-wrap;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .hide-mobile {
        display: none;
    }
}
</style>
