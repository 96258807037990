import { render, staticRenderFns } from "./NormTreeview.vue?vue&type=template&id=22b2f36a&scoped=true&"
import script from "./NormTreeview.vue?vue&type=script&lang=js&"
export * from "./NormTreeview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b2f36a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VIcon,VTreeview})
