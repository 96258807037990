import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueAxios from "vue-axios";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import axios from "axios";
import VueSecureHTML from "vue-html-secure";
import Ripple from "vuetify/lib/directives/ripple";

import { createInstance } from "./api/base.api";

Vue.config.productionTip = false;

require("moment/locale/de");
moment.locale("de");

Vue.use(VueMoment, { moment });
Vue.use(VueSecureHTML);
Vue.directive("ripple", Ripple);

export const app = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
});

setup().finally(() => app.$mount("#app"));

async function setup() {
    const config = await axios.get(window.location.origin + "/config.php");
    const backend = config?.data?.api_base || process.env.VUE_APP_API_HOST;
    const external = config?.data?.external_url || process.env.VUE_APP_EXTERNAL_URL;
    const vapid_public = config?.data?.vapid_public || process.env.VUE_APP_VAPID_PUBLIC;
    const api = await createInstance(backend);

    Vue.use(VueAxios, api);
    Vue.prototype.$backend = backend;
    Vue.prototype.$external = external;

    if (!navigator.serviceWorker.controller) return;
    try {
        const serviceWorker = await navigator.serviceWorker.ready;
        const pushManager = serviceWorker.pushManager;

        let subscription = await pushManager.getSubscription();
        if (subscription == undefined) {
            subscription = await pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapid_public,
            });
        }

        Vue.axios
            .post("/notification/subscribe", subscription)
            .then(() => console.log("webpush subscribed"))
            .catch((err) => console.log(err));
    } catch (error) {
        console.log(error);
    }
}
