<script>
import DialogLayout from "@layouts/dialog.layout.vue";

export default {
    name: "EmailDialogComponent",
    components: {
        DialogLayout,
    },
    data() {
        return {
            dialog: false,
            mail: undefined,
        };
    },
    computed: {
        title() {
            if (!this.mail) return "Email";
            return this.mail.subject;
        },
    },
    methods: {
        close() {
            this.dialog = false;
            this.$emit("close");
        },
        open(id) {
            this.dialog = true;
            this.axios
                .get("/media/" + id + "/email")
                .then((res) => (this.mail = res.data))
                .catch((err) => console.log(err));
        },
    },
};
</script>

<template>
    <DialogLayout v-model="dialog" :title="title" width="800">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>
        <template #default>
            <template v-if="mail">
                <template v-if="mail.senderEmail">
                    <div class="category-title">Absender</div>
                    <div>{{ mail.senderName }} ({{ mail.senderSmtpAddress || mail.senderEmail }})</div>
                </template>
                <template v-if="mail.recipients">
                    <div class="category-title">Empfänger</div>
                    <div v-for="recipient in mail.recipients" :key="recipient.email">{{ recipient.name }}</div>
                </template>
                <template v-if="mail.clientSubmitTime">
                    <div class="category-title">Datum</div>
                    <div>{{ mail.clientSubmitTime | moment("calendar") }}</div>
                </template>
                <template v-if="mail.subject">
                    <div class="category-title">Betreff</div>
                    <div>{{ mail.subject }}</div>
                </template>
                <template v-if="mail.body">
                    <div class="category-title">Inhalt</div>
                    <div v-html-safe="mail.body" style="white-space: pre-wrap"></div>
                </template>
            </template>
        </template>
        <template #action>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close">Ok</v-btn>
        </template>
    </DialogLayout>
</template>

<style lang="scss" scoped>
.category-title {
    font-weight: bold;
    font-size: 12px;
    color: black;
    margin-top: 0.5rem;
}
</style>
