var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"timeline"},[_c('v-timeline',{attrs:{"dense":"","clipped":""}},[_c('v-timeline-item',{attrs:{"fill-dot":"","small":"","color":"accent","icon":"mdi-briefcase-plus"}},[_c('JournalDialog',{attrs:{"journal":{
                        origin: _vm.contract.manufacturer_sign,
                        title: 'Auftrag erstellt',
                        description: 'Der Auftrag wurde eröffnet',
                        timestamp: new Date(_vm.contract.created),
                    }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var attrs = ref.attrs;
                    var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticStyle:{"user-select":"none"}},'div',attrs,false),on),[_vm._v("Auftrag erstellt")])]}}])})],1),_vm._l((_vm.computedJournal),function(entry,index){return [(entry.disabled)?[(!_vm.checklist[entry.checklist])?_c('v-timeline-item',{key:index,attrs:{"fill-dot":"","small":"","color":"#ccc","icon":"mdi-clock-outline"}},[_c('div',{staticClass:"entry"},[_vm._v(_vm._s(entry.title))])]):_vm._e()]:(Array.isArray(entry.children))?[_c('ExpansionPanel',{key:index,scopedSlots:_vm._u([{key:"header",fn:function(ref){
                    var expand = ref.expand;
return [_c('v-timeline-item',{attrs:{"fill-dot":"","small":"","color":_vm.colors[entry.category]},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-badge',{attrs:{"value":!expand,"content":_vm.badgeContent(entry),"color":_vm.colors[entry.category],"bordered":"","small":""}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(_vm._s(_vm.icons[entry.category]))])],1)]},proxy:true}],null,true)},[_c('JournalDialog',{attrs:{"journal":entry},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var attrs = ref.attrs;
                    var on = ref.on;
return [(expand)?_c('div',_vm._g(_vm._b({staticClass:"entry"},'div',attrs,false),on),[_vm._v(_vm._s(entry.title))]):_c('div',{staticClass:"entry"},[_vm._v(_vm._s(entry.title))])]}}],null,true)})],1)]}}],null,true)},_vm._l((entry.children),function(children,chindex){return _c('v-timeline-item',{key:'ch' + chindex,attrs:{"hide-dot":""}},[_c('JournalDialog',{attrs:{"journal":children},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var attrs = ref.attrs;
                    var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"entry"},'div',attrs,false),on),[_vm._v(_vm._s(children.title))])]}}],null,true)})],1)}),1)]:[_c('v-timeline-item',{key:index,attrs:{"fill-dot":"","small":"","color":_vm.colors[entry.category],"icon":_vm.icons[entry.category]}},[_c('JournalDialog',{attrs:{"journal":entry},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var attrs = ref.attrs;
                    var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"entry"},'div',attrs,false),on),[_vm._v(_vm._s(entry.title))])]}}],null,true)})],1)]]})],2)],1),_c('div',{staticClass:"action"},[(_vm.contract.status == 0 || _vm.contract.status == undefined)?_c('div',{staticClass:"action-button grey",staticStyle:{"pointer-events":"none"}},[_vm._v(" Auftrag in Vorbereitung ")]):(_vm.contract.status == 1)?_c('div',{staticClass:"action-button info",staticStyle:{"pointer-events":"none"}},[_vm._v("Auftrag in Arbeit")]):(_vm.contract.status == 2)?_c('div',{staticClass:"action-button accent",staticStyle:{"pointer-events":"none"}},[_vm._v("Auftrag geprüft")]):(_vm.contract.status == 3)?_c('div',{staticClass:"action-button success",staticStyle:{"pointer-events":"none"}},[_vm._v("Auftrag transportbereit")]):(_vm.contract.status == 4)?_c('div',{staticClass:"action-button error",staticStyle:{"pointer-events":"none"}},[_vm._v("Auftrag abgeschlossen")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }